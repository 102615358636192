import { AfterViewInit, Component, inject, Input, OnInit } from '@angular/core';
import { AdBannerRotationService } from '../../../../shared/service/ad-banner-rotation.service';
import { AdBanner } from '../../../../core/model/banner/ad-banner';
import { PropertyReportSectionEnum } from '../../../../core/enum/property-report-section-enum';
import { AdBannerService } from "../../../../shared/service/ad-banner.service";
import { AdBannerAction } from "../../../../core/model/banner/ad-banner-action";
import { takeUntil } from "rxjs";
import { BaseUnsubscribe } from "../../../../core/component/base-unsubscribe/base-unsubscribe";
import { DataService } from "../../../../shared/service/data.service";
import { GoogleAnalyticsService } from "../../../../shared/service/google-analytics.service";
import { GA_Button } from '../../../../shared/constant/google-analytics-constants';
import {AdBannerGaAction} from "../../../../core/model/banner/ad-banner-ga-action";

@Component({
  selector: 'gema3g-left-sidebar',
  standalone: true,
  templateUrl: './left-sidebar.component.html',
  styleUrls: ['./left-sidebar.component.scss']
})
export class LeftSidebarComponent extends BaseUnsubscribe implements OnInit, AfterViewInit {

  constructor() {
    super();
  }

  private adBannerRotationService: AdBannerRotationService = inject(AdBannerRotationService);
  private adBannerService: AdBannerService = inject(AdBannerService);
  private gaService = inject(GoogleAnalyticsService);

  @Input() propertyReportSection: string;
  adBannerKeySuffix: string = '-LEFT';
  newAdBanners: AdBanner[] = [];

  ngOnInit(): void {
    this.adBannerRotationService.adBanners
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(adBanners => {

      let adBannerKeys = Array.from(adBanners.keys());
      this.newAdBanners = [];

      switch (this.propertyReportSection) {
        case PropertyReportSectionEnum.REGISTRY:
          adBannerKeys.forEach(key => {
            if (key.startsWith(PropertyReportSectionEnum.REGISTRY + this.adBannerKeySuffix)) {
              this.newAdBanners.push(adBanners.get(key)!);
            }
          })
          break;

        case PropertyReportSectionEnum.VALUATION_AND_SALES:
          adBannerKeys.forEach(key => {
            if (key.startsWith(PropertyReportSectionEnum.VALUATION_AND_SALES + this.adBannerKeySuffix)) {
              this.newAdBanners.push(adBanners.get(key)!);
            }
          })
          break;

        case PropertyReportSectionEnum.PLANS_AND_SURVEYS:
          adBannerKeys.forEach(key => {
            if (key.startsWith(PropertyReportSectionEnum.PLANS_AND_SURVEYS + this.adBannerKeySuffix)) {
              this.newAdBanners.push(adBanners.get(key)!);
            }
          })
          break;

        case PropertyReportSectionEnum.CONDO_DETAILS:
          adBannerKeys.forEach(key => {
            if (key.startsWith(PropertyReportSectionEnum.CONDO_DETAILS + this.adBannerKeySuffix)) {
              this.newAdBanners.push(adBanners.get(key)!);
            }
          })
          break;

      }

    })
  }

  ngAfterViewInit(): void {
  }

  invokeAction(action: AdBannerAction, gaAction: AdBannerGaAction | undefined, index: number) {
    if(gaAction){
      this.gaService.adClicked(gaAction);
    } else if(index == 0){
      this.gaService.adClicked( new AdBannerGaAction(GA_Button.FIRST_LEFT_SIDE_AD, GA_Button.FIRST_LEFT_SIDE_AD_LABEL));
    }
    this.adBannerService.invokeAction(action);
  }
}

import { inject, Injectable } from '@angular/core';
import { FeatureFlag, FeatureFlagService } from '@ngx-flagr/core';
import { FeatureFlagEvaluationResult } from '@ngx-flagr/core/src/feature-flag';
import { LoggerService } from '../log/logger.service';
import { FeatureFlagEnum } from 'src/app/core/enum/feature-flag.enum';

@Injectable({ providedIn: 'root' })
export class ApplicationFeatureFlagService implements FeatureFlagService {
    constructor() {
        this.loadFeatures();
    }

    private loggerService = inject(LoggerService);
    private featureFlags: FeatureFlag[] = [];
    
    isFeatureEnabled(featureFlag: FeatureFlagEnum): FeatureFlagEvaluationResult {
        return this.isEnabled(featureFlag.toString());
    }

    isEnabled(featureFlag: FeatureFlag): FeatureFlagEvaluationResult {
        let enabled: boolean = false;

        switch (featureFlag) {
            case FeatureFlagEnum.MARKETING_AD_CLICK.toString(): 
                //todo: implement logic to check if feature is enabled
                enabled = false; //temporarily set to true for testing, might change this to observable instead
                break;
            
            default:

        }
        
        this.loggerService.logDebug(`feature flag ${featureFlag} enabled? ${enabled}`);
        return enabled;
    }

    private loadFeatures = () => {
        this.featureFlags.push(FeatureFlagEnum.MARKETING_AD_CLICK.toString());
    }
}

<ng-container *ngIf="ownerToPropertyMap && ownerToPropertyMap[0] && ownerToPropertyMap[0].ownerToPropertyMapItems && ownerToPropertyMap[0].ownerToPropertyMapItems.length">
  <div class="owner-name-section">
    <div class="owner-image">
      <img id="osr-img-singleowner-header" alt="single owner header" src="assets/img/svg/icons/property/icon_propertytype_singleowner_header.svg">
    </div>
    <h3 id="osr-h3-owner-name" class="owner-name"><span>{{ownerToPropertyMap[0].name}}</span></h3>
  </div>

  <div id="src-displaying-results" class="sr-results-found">
    <strong> Displaying {{ownerToPropertyMap[0].ownerToPropertyMapItems.length}} {{ownerToPropertyMap[0].ownerToPropertyMapItems.length == 1 ? "Result" : "Results"}} </strong>
  </div>

  <gema3g-sort-search-results
    (onSortingSelected)="applySorting($event)"
    [sortingOptions]="sortingOptions"
    class="sr-sorting-container"
  >
  </gema3g-sort-search-results>

  <div (scroll)="onScroll($event)" [ngClass]="!isMobileFullScreen ? 'max-h-[25vh]' : 'max-h-[calc(100dvh-250px)]'" class="overflow-y-auto">
    <div *ngFor="let result of ownerToPropertyMap[0].ownerToPropertyMapItems; index as i">
      <div (mouseout)="onMouseOut(result)" (mouseover)="onMouseOver(result)" *ngIf="result.fullAddress" class="sr-property-container !border-b-2 !border-solid">
        <div class="sr-item-image-container">
          <ng-container *ngIf="result?.unitType!=null">
            <ng-container [ngSwitch]="result?.unitType">
              <gema3g-svg-icon id="osr-icon-suite-{{i}}" *ngSwitchCase="'SUITE'" [fill]="'#fcbc77'" [height]="24" [icon]="'location'" [width]="24"></gema3g-svg-icon>
              <gema3g-svg-icon id="osr-icon-locker-or-parking-{{i}}" *ngSwitchCase="'LOCKER_OR_PARKING'" [fill]="'#3a8fef'" [height]="24" [icon]="'parking'" [width]="24"></gema3g-svg-icon>
              <gema3g-svg-icon id="osr-icon-parking-{{i}}" *ngSwitchCase="'PARKING'" [fill]="'#3a8fef'" [height]="24" [icon]="'parking'" [width]="24"></gema3g-svg-icon>
              <gema3g-svg-icon id="osr-icon-locker-{{i}}" *ngSwitchCase="'LOCKER'" [fill]="'#3a8fef'" [height]="24" [icon]="'locker'" [width]="24"></gema3g-svg-icon>
              <gema3g-svg-icon id="osr-icon-location-{{i}}" [fill]="'#fcbc77'" [height]="24" [icon]="'location'" [width]="24" ng-switch-default></gema3g-svg-icon>
            </ng-container>
          </ng-container>
          <div *ngIf="result?.unitType==null">
            <div id="osr-unit-type-null-{{i}}" class="icon-location">
              <gema3g-svg-icon id="osr-icon-building-{{i}}" *ngIf="result.pin.length<6" [fill]="'#3a8fef'" [height]="24" [icon]="'building'" [width]="24"></gema3g-svg-icon>
              <gema3g-svg-icon id="osr-icon-location-pin-grtr-5-{{i}}" *ngIf="result.pin.length>5" [fill]="'#fcbc77'" [height]="24" [icon]="'location'" [width]="24"></gema3g-svg-icon>
              <gema3g-svg-icon id="osr-icon-location-pin-is-null-{{i}}" *ngIf="result.pin==null" [fill]="'#fcbc77'" [height]="24" [icon]="'location'" [width]="24"></gema3g-svg-icon>
          </div>
          </div>
        </div>
        <div class="sr-property-description-container">

          <div class="sr-property-description-first-item">
            <div id="osr-full-address-{{i}}" class="text-os-bold">{{result.fullAddress}}</div>
            <div class="flex-1"></div>
            <div id="osr-pin-or-arn-{{i}}" [ngClass]="result.hasPinOrArn ? 'block' : 'hidden'" class="h-[24px]">
              <div id="osr-details-collapse-{{i}}" (click)="result.detailsExpanded = !result.detailsExpanded" *ngIf="result.detailsExpanded" class="text-left">
                <gema3g-svg-icon id="osr-icon-chevronup-{{i}}"
                  [fill]="'#9e9e9e'"
                  [height]="24"
                  [hidden]="!result.showExpansionImages && !isMobileFullScreen"
                  [icon]="'chevronup'"
                  [width]="34"
                >
                </gema3g-svg-icon>
              </div>
              <div id="osr-details-expand-{{i}}" (click)="result.detailsExpanded = !result.detailsExpanded" *ngIf="!result.detailsExpanded" class="text-left">
                <gema3g-svg-icon id="osr-icon-chevrondown-{{i}}"
                  [fill]="'#9e9e9e'"
                  [height]="24"
                  [hidden]="!result.showExpansionImages && !isMobileFullScreen"
                  [icon]="'chevrondown'"
                  [width]="34"
                >
                </gema3g-svg-icon>
              </div>
            </div>
          </div>
          <div id="osr-legal-description-{{i}}">{{result.legalDescription | cutText:43: false}}</div>
          <ng-container *ngIf="result.detailsExpanded">
            <div *ngIf="result.pii?.pin" class="flex flex-row">
              <div id="osr-details-label-pin-{{i}}" class="sr-details-label text-os-bold">PIN:</div>
              <div id="osr-results-pin-{{i}}">{{result.pii?.pin}}</div>
            </div>
            <ng-container *ngIf="result.pii?.arns && result.pii.arns.length > 0">

              <ng-container *ngIf="result.pii?.arns && result.pii.arns.length == 1">
                <div class="flex flex-row">
                  <div id="osr-details-label-arn-{{i}}" class="sr-details-label text-os-bold">ARN:</div>
                  <div id="osr-result-arns-{{i}}">{{result.pii.arns[0]}}</div>
                </div>
              </ng-container>

              <ng-container *ngIf="result.pii?.arns && result.pii.arns.length > 1">
                <div>
                  <a id="osr-link-multiple-arns-{{i}}" (click)="result.arnExpanded = !result.arnExpanded" class="anchor" target="_blank">
                    Multiple ARNS
                  </a>
                </div>
                <ng-container *ngIf="result.arnExpanded">
                  <div id="osr-arn-row-{{arn}}-{{i}}" *ngFor="let arn of result.pii?.arns" class="flex flex-row">
                    <div id="osr-details-label-arn-{{arn}}-{{i}}" class="sr-details-label text-os-bold">ARN:</div>
                    <div id="osr-row-{{arn}}-{{i}}">{{arn}}</div>
                  </div>
                </ng-container>
              </ng-container>

            </ng-container>

            <ng-container *ngIf="result.unitType" class="flex flex-row">
              <div id="osr-details-label-type-{{i}}" class="sr-details-label text-os-bold">Type:</div>
              <div id="osr-unit-type-other-{{i}}" *ngIf="result.unitType == 'LOCKER_OR_PARKING'">Other</div>
              <div id="osr-unit-type-locker-or-parking-{{i}}" *ngIf="result.unitType != 'LOCKER_OR_PARKING'">{{result.unitType}}</div>
            </ng-container>
          </ng-container>
          <div *ngIf="result.hasPinOrArn">
            <a id="osr-link-property-report-{{i}}" (click)="goToProperty(result)" class="anchor" target="_blank">Property Report</a>
          </div>
        </div>
      </div>
    </div>
  </div>

</ng-container>

import {AfterViewInit, Component, inject, Input} from '@angular/core';
import {AdBannerRotationService} from '../../../../shared/service/ad-banner-rotation.service';
import {AdBanner} from '../../../../core/model/banner/ad-banner';
import {PropertyReportSectionEnum} from '../../../../core/enum/property-report-section-enum';
import {AdBannerAction} from "../../../../core/model/banner/ad-banner-action";
import {AdBannerService} from "../../../../shared/service/ad-banner.service";
import {BaseUnsubscribe} from "../../../../core/component/base-unsubscribe/base-unsubscribe";
import {takeUntil, lastValueFrom} from "rxjs";
import {AdBannerActionEnum} from "../../../../core/enum/ad-banner-action-enum";
import {RouteMappingUtility} from "../../../../shared/utility/route-mapping-utility";
import {EstoreProductCategoryEnum} from "../../../../core/enum/estore-product-category-enum";
import {DataService} from "../../../../shared/service/data.service";
import {GoogleAnalyticsService} from "../../../../shared/service/google-analytics.service";
import {CommonModule} from '@angular/common';
import {GA_Ad, GA_Button} from '../../../../shared/constant/google-analytics-constants';
import {EstoreService} from '../../../../shared/service/estore.service';
import {UserService} from "../../../../shared/service/user.service";
import {UserAccessControl} from "../../../../core/model/user/user-access-control";
import {PropertyDetail} from '../../../../core/model/property/property-detail';
import {ProductCarousel} from "../../../../core/model/estore/product-carousel";
import {PropertyReportService} from '../../../../shared/service/property-report.service';
import {AdBannerWeightEnum} from '../../../../core/enum/ad-banner-weight-enum';
import {ProductEnum} from '../../../../core/enum/product-enum';
import {AdBannerGaAction} from "../../../../core/model/banner/ad-banner-ga-action";

@Component({
  selector: 'gema3g-right-sidebar',
  standalone: true,
  templateUrl: './right-sidebar.component.html',
  styleUrls: ['./right-sidebar.component.scss'],
  imports: [CommonModule]
})
export class RightSidebarComponent extends BaseUnsubscribe {
  constructor() {
    super();
  }

  private gaService = inject(GoogleAnalyticsService);
  private adBannerRotationService = inject(AdBannerRotationService);
  private adBannerService = inject(AdBannerService);
  private eStoreService = inject(EstoreService);
  private userService = inject(UserService);
  private propertyReportService = inject(PropertyReportService);
  userAccessControls: UserAccessControl;
  subjectProperty: PropertyDetail;

  @Input() propertyReportSection: string;
  adBannerKeySuffix: string = '-RIGHT';
  newAdBanners: AdBanner[] = [];
  newRightBottomAdBanners: AdBanner[] = [];
  condoBanner: AdBanner;
  viewStatReportsAction = new AdBannerAction(AdBannerActionEnum.NAVIGATE_TO_ROUTE, ['../catalogue',
    RouteMappingUtility.eStoreCategoryToRoute(EstoreProductCategoryEnum.STAT) + '-' + RouteMappingUtility.eStoreCategoryToRoute(EstoreProductCategoryEnum.PROPERTY_SURVEY_IMAGE)]);

  isPropertySummary: boolean = false;
  isTitlePresent: boolean = false;
  isParcelRegisterActive: boolean = false;

  defaultParcelRegisterAdBanner: AdBanner = new AdBanner(AdBanner.IMAGE_AD, PropertyReportSectionEnum.REGISTRY, AdBannerWeightEnum.MEDIUM, 'assets/img/store-front/parcelregister/T_PR_H1.gif', new AdBannerAction(AdBannerActionEnum.OPEN_PARCEL_REGISTER_REPORT), new AdBannerGaAction(GA_Ad.T_PR_H1));
  defaultParcelRegisterSquareAdBanner: AdBanner = new AdBanner(AdBanner.IMAGE_AD, PropertyReportSectionEnum.REGISTRY, AdBannerWeightEnum.MEDIUM, 'assets/img/store-front/parcelregister/B_PR_S1.gif', new AdBannerAction(AdBannerActionEnum.OPEN_PARCEL_REGISTER_REPORT), new AdBannerGaAction(GA_Ad.B_PR_S1));

  async ngOnInit(): Promise<void> {

    this.propertyReportService.subjectProperty$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((newPropertyDetail) => {
        this.subjectProperty = newPropertyDetail;
      });
    this.userAccessControls = this.userService.getUserAccessControl();
    let productCarousel = await lastValueFrom(this.eStoreService.getCarouselProductsByPin(this.subjectProperty.pii.pin));
    this.isTitlePresent = productCarousel.prop_details.some(product => product.prodId === ProductEnum.TITLE_CHECK_PARCEL_REGISTER.toString());

    this.adBannerRotationService.adBanners
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(adBanners => {

        let adBannerKeys = Array.from(adBanners.keys());

        this.newAdBanners = [];
        this.newRightBottomAdBanners = [];

        switch (this.propertyReportSection) {
          case PropertyReportSectionEnum.REGISTRY:
            this.isPropertySummary = true;
            adBannerKeys.forEach(key => {
              const banner = adBanners.get(key) as AdBanner;
              if (key.startsWith(PropertyReportSectionEnum.REGISTRY + '-RIGHTBOTTOM')) {
                let banner = adBanners.get(key)!;
                if (banner.imageSource.includes('/B_TC')) {
                  if (this.isTitlePresent) {
                    this.newRightBottomAdBanners.push(banner);
                  } else {
                    this.newRightBottomAdBanners.push(this.defaultParcelRegisterSquareAdBanner);
                  }
                } else if (banner.imageSource.includes('/B_PR')) {
                  if (this.userAccessControls.parcelRegisterActive) {
                    this.newRightBottomAdBanners.push(banner);
                  }
                } else {
                  this.newRightBottomAdBanners.push(banner);
                }
              } else if (key.startsWith(PropertyReportSectionEnum.REGISTRY + this.adBannerKeySuffix)) {
                let banner = adBanners.get(key)!;
                if (banner.imageSource.includes('conduit')) {
                  this.condoBanner = banner;
                } else if (banner.imageSource.includes('/T_TC')) {
                  if (this.isTitlePresent) {
                    this.newAdBanners.push(banner);
                  } else {
                    this.newAdBanners.push(this.defaultParcelRegisterAdBanner);
                  }
                } else if (banner.imageSource.includes('/T_PR')) {
                  if (this.userAccessControls.parcelRegisterActive) {
                    this.newAdBanners.push(banner);
                  }
                } else {
                  this.newAdBanners.push(banner);
                }
              }
            });
            break;

          case PropertyReportSectionEnum.VALUATION_AND_SALES:
            adBannerKeys.forEach(key => {
              if (key.startsWith(PropertyReportSectionEnum.VALUATION_AND_SALES + this.adBannerKeySuffix)) {
                this.newAdBanners.push(adBanners.get(key)!);
              }
            });
            break;

          case PropertyReportSectionEnum.PLANS_AND_SURVEYS:
            adBannerKeys.forEach(key => {
              if (key.startsWith(PropertyReportSectionEnum.PLANS_AND_SURVEYS + this.adBannerKeySuffix)) {
                this.newAdBanners.push(adBanners.get(key)!);
              }
            });
            break;

          case PropertyReportSectionEnum.CONDO_DETAILS:
            adBannerKeys.forEach(key => {
              if (key.startsWith(PropertyReportSectionEnum.CONDO_DETAILS + this.adBannerKeySuffix)) {
                this.newAdBanners.push(adBanners.get(key)!);
              }
            });
            break;

        }

      })
  }

  ngAfterViewInit(): void {
  }

  invokeAction(action: AdBannerAction, gaAction: AdBannerGaAction | undefined, index: number) {
    if(gaAction) {
      this.gaService.adClicked(gaAction);
    } else if (index == 0) {
      this.gaService.adClicked( new AdBannerGaAction(GA_Button.FIRST_RIGHT_SIDE_AD, GA_Button.FIRST_RIGHT_SIDE_AD_LABEL));
    }
    this.adBannerService.invokeAction(action);
  }
}

import { Injectable, Inject, inject } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { LoggerService } from "../../../shared/service/log/logger.service";
import { DOCUMENT } from '@angular/common';
import { UserService } from "../../../shared/service/user.service";
import { AuthenticationService } from "../../../shared/service/authentication.service";
import { lastValueFrom } from "rxjs";
import { UrlService } from "src/app/shared/service/url.service";


/**
 * This Auth guard restricts the /initialize-profile route to users who are:
 * 1. logged in and,
 * 2. have already accepted the terms and conditions and,
 * 3. have not yet completed the user profile.
 */
@Injectable({ providedIn: 'root' })
export class UserProfileInitializedAuthGuard {
  constructor(@Inject(DOCUMENT) private document: Document) {
  }

  private loggerService = inject(LoggerService);
  private authService = inject(AuthenticationService);
  private userService = inject(UserService);
  private urlService = inject(UrlService);
  
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree> {
    let user = this.userService.user;

    let isUserLoggedIn: boolean = this.authService.isLoggedIn;
    let isLegalAgreementAccepted: boolean = user.isLegalAgreementAccepted;
    let isUserProfileInitialized: boolean = user.isProfileAccepted;
    let requireLegalAgreement: boolean = user.requireLegalAgreement;

    this.loggerService.logDebug(`user ${this.userService.user?.businessEntityId} logged in? ${isUserLoggedIn}, legal agreement accepted? ${isLegalAgreementAccepted}, user profile initialized? ${isUserProfileInitialized}`);

    if (!isUserLoggedIn) {
      //send the user back to the landing page
      //TODO sso and non-sso have different landing pages in this scenario
      this.urlService.goToLoginPage();
      return false;
    } else if (!isLegalAgreementAccepted && requireLegalAgreement) {
      this.urlService.goToAcceptTermsAndConditionsPage();
      return false;
    } else if (isUserProfileInitialized) {
      this.urlService.goToLandingPage();
      return false;
    }

    return true;
  }
}

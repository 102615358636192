import { LOCALE_CONFIG } from "ngx-daterangepicker-material"

export const GA_Event = {
    EVENT: 'event',
    BUSINESS_ENTITY_ID: 'BusinessEntityId',
    NAVIGATE: 'Navigate',
    OPEN_MODAL: 'OpenModal',
    OPEN_PAGE: 'OpenPage',
    OPEN_SECTION: 'OpenSection',
    BUTTON_CLICKED: 'ButtonClicked',
    FEATURE_CLICKED: 'FeatureClicked',
    AD_CLICKED: 'AdClicked',
}

export const GA_Label = {
    PIN: 'PIN',
    ARN: 'ARN',
    ENABLED: 'Enabled',
    LOGIN: 'Login',
    LOGOUT: 'Logout',
}

export const GA_Button = {
    GEO_ACADEMY_BUTTON: 'GeoWarehouse academy button',
    GEO_ACADEMY_BUTTON_LABEL: 'Geo_Academy_button',
    SUPPORT_BUTTON: 'Support button',
    SUPPORT_BUTTON_LABEL: 'Registration tracker button',
    RATA_BUTTON: 'RATA_button',
    RATA_BUTTON_LABEL: 'RATA_button',
    HOME_BUTTON: 'Home button',
    HOME_BUTTON_LABEL: 'Home_button',
    SEARCH_PROPERTY: 'Search button',
    SEARCH_PROPERTY_LABEL: 'Search_property',
    SEARCH_BY: 'Search by',
    SEARCH_BY_LABEL: 'Search_by',
    HOODQ_SCHOOLS: 'Hoodq Section',
    HOODQ_SCHOOLS_LABEL: 'Hoodq_section',
    MAP_LAYERS: 'Map Layers Button',
    MAP_LAYERS_LABEL: 'Map_Layers',
    MAP_LAYER_OWNERSHIP: 'Ownership Map Layer',
    MAP_LAYER_ASSESSMENT: 'Assessment Map Layer',
    MAP_LAYER_STREET: 'Street Map Layer',
    MAP_LAYER_THEMATIC: 'Thematic Map Layer',
    MAP_LAYER_FIRST_BASE_SOLUTION: 'First Base Solution Map Layer',
    MAP_LAYER_LOT_CONCESSION: 'Lot Concession Map Layer',
    MAP_LAYER_LRO_MUNICIPALITY: 'LRO Municipality Map Layer',
    HEATMAP_AVG_SALES_ON: 'Block heatmap average sales',
    HEATMAP_AVG_SALES_ON_LABEL: 'Heatmap_Avg_Sales_ON',
    HEATMAP_GROWTH_RATE_ON: 'Block heatmap growth rate',
    HEATMAP_GROWTH_RATE_ON_LABEL: 'Heatmap_Growth_Rate_ON',
    HEATMAP_MARKET_TURNOVER_ON: 'Block heatmap market turnover',
    HEATMAP_MARKET_TURNOVER_ON_LABEL: 'Heatmap_Market_Turnover_ON',
    MAP_VIEW_STD_ROAD_MAP: 'Map Standard Road Map button',
    MAP_VIEW_STD_ROAD_MAP_LABEL: 'Map_View_Std_Road_Map',
    AERIAL_VIEW: 'Aerial View button',
    AERIAL_VIEW_LABEL: 'Aerial_View',
    ENHANCED_AERIAL: 'Enhanced Aerial button',
    ENHANCED_AERIAL_LABEL: 'Enhanced_Aerial',
    VALUATION_SALES_BUTTON: 'Valuation & Sales button',
    VALUATION_SALES_BUTTON_LABEL: 'Valuation_Sales_button',
    PLANS_SURVEYS_EASEMENTS_BUTTON: 'Plans, Surveys & Easements button',
    PLANS_SURVEYS_EASEMENTS_BUTTON_LABEL: 'Plans_Surveys_Easements_button',
    RESIDENTIAL_RELOCATION_BUTTON: 'Residential Relocation button',
    RESIDENTIAL_RELOCATION_BUTTON_LABEL: 'Residential_Relocation_button',
    HOODQ_BUTTON: 'HoodQ button',
    HOODQ_BUTTON_LABEL: 'HoodQ_button',
    DEMOGRAPHICS_BUTTON: 'Demographics button',
    DEMOGRAPHICS_BUTTON_LABEL: 'Demographics_button',
    SITE_AND_STRUCTURE_BUTTON: 'Site and Structure button',
    SITE_AND_STRUCTURE_BUTTON_LABEL: 'Site_and_Structure_button',
    PRINT_PDF_BUTTON: 'Print PDF button',
    PRINT_PDF_BUTTON_LABEL: 'Print_PDF_button',
    TOOLS_BUTTON: 'Tools button',
    TOOLS_BUTTON_LABEL: 'Tools_button',
    FIRST_LEFT_SIDE_AD: 'First Left Side Ad',
    FIRST_LEFT_SIDE_AD_LABEL: 'First_Left_Side_Ad',
    FIRST_RIGHT_SIDE_AD: 'First Right Side Ad',
    FIRST_RIGHT_SIDE_AD_LABEL: 'First_Right_Side_Ad',
    SEARCH_COMPARABLES_VIEW_LAST_RESULTS: 'View Last Search Comparables Results button',
}

export const GA_Page = {
    SEARCH_COMPS: 'Search Comparables',
    SEARCH_COMPS_LABEL: 'Search_Comps',
    PROPERTY_DETAILS_PAGE: 'PropertyDetailsPage',
    PROPERTY_DETAILS_PAGE_LABEL: 'Identifier',
    PROPERTY_DETAILS_PIN_LABEL: GA_Label.PIN,
    PROPERTY_DETAILS_ARN_LABEL: GA_Label.ARN,
}

export const GA_Section = {
    //TODO: add sections requiring tracking here
}

export const GA_Navigate = {
    TO_TRAINING: 'ToTraining',
    TO_OVERVIEW: 'ToOverview',
    TO_HOW_TO_LIBRARY: 'ToHowToLibrary',
    TO_CONTACT_US: 'ToContactUs',
    TO_INSIGHTER: 'ToInsighter',
    TO_HELP_AND_SUPPORT: 'ToHelpAndSupport',
    TO_MY_ACCOUNT: 'ToMyAccount',
    TO_LOGIN: 'Login',
    TO_LEGAL_AGREEMENT: 'LegalAgreement',
    TO_INITIALIZE_PROFILE: 'InitializeProfile',
    TO_HOME: 'Home',
    TO_ESTORE: 'EStore',
    TO_ESTORE_CATALOGUE: 'EStoreCatalogue',
    TO_MARKETING_SITE: 'MarketingSite',
}

export const GA_Modal = {
    ABOUT: 'AboutDialog',
    ACCOUNT_SUSPENDED: 'AccountSuspendedDialog',
    ADDRESS_CORRECTION: 'AddressCorrectionDialog',
    ADD_NEW_COMPARABLES_REPORT: 'AddingNewCompararablesReport',
    CANNOT_OPEN_REPORT: 'CannotOpenReportDialog',
    CONFIRM_CARD_REMOVAL: 'ConfirmCardRemovalDialog',
    SELECTED_PROPERTY: 'SelectedPropertyDialog',
    NEW_VERSION: 'NewVersionDialog',
    HOODQ_ASSESSMENT_SELECTION: 'HoodqAssessmentSelectionDialog',
    INSIGHTS_REPORT: 'InsightsReportDialog',
    PYB_REPORT_STATUS: 'PybReportStatusDialog',
    PRINT_CSV_COMPARABLES_REPORT: 'PrintingCsvCompararablesReportDialog',
    PRINT_PDF_COMPARABLES_REPORT: 'PrintingPdfCompararablesReportDialog',
    PROPERTY_REPORT_MAX_COUNTER_REACHED: 'PropertyReportMaxCounterReachedDialog',
    PROPERTY_STREETVIEW: 'PropertyStreetViewDialog',
    PROPERTY_SURVEY_IMAGE_LOWRES_PREVIEW: 'PropertySurveyImageLowResolutionPreviewDialog',
    RESIDENTIAL_RELOCATION_ADDITIONAL_INFO: 'ResidentialRelocationAdditionalInfoDialog',
    RESIDENTIAL_RELOCATION_ASSESSMENT_SELECTION: 'ResidentialRelocationAssessmentSelectionDialog',
    SEARCH_COMPARABLES_PROMPT_MORE_POLYGONS: 'SCPromptForMorePolygonsDialog',
    SELECTED_PROPERTY_REPORT: 'SelectedPropertyReportDialog',
    GENERATE_PDF_REPORT: 'GeneratePdfReportDialog',
    PROPERTY_REPORT_LOW_BALANCE: 'PropertyReportLowBalanceDialog',
    COMPARABLE_SEARCH_LOW_BALANCE: 'ComparableSearchLowBalanceDialog',
    SESSION_TIMEOUT: 'SessionTimeoutDialog',
    UNSAVED_CHANGES: 'UnsavedChangesDialog',
}

export const GA_Feature = {
    CENTER_PROPERTY_ON_MAP: 'CenterPropertyOnMap',
    COPY_PIN: 'CopyPin',
    COPY_ARN: 'CopyArn',
    IN_APP_TICKER_TAPE_CLICKED: 'InAppTickerTapeClicked',
    MAP_MARKER_OPEN_REPORT: 'MapMarkerOpenReport',
    MAP_MARKER_STREET_VIEW: 'MapMarkerStreetView',
    MAP_MARKER_CLOSED: 'MapMarkerClosed',
    PROPERTY_REPORT_SCROLL_TO_TOP: 'PropertyReportScrollToTop',
    PROPERTY_REPORT_COLLAPSE_ALL_SECTIONS: 'CollapseAllPropertyReportSections',
    PROPERTY_REPORT_EXPAND_ALL_SECTIONS: 'ExpandAllPropertyReportSections',
    OMNIBAR_SEARCH_SHORTCUT_KEY: 'OmnibarSearchShortcutKey',
    SEARCH_COMPARABLES_FORM_RESET: 'SCFormReset',
    SEARCH_COMPARABLES_MODIFY_SEARCH_CRITERIA: 'SCModifySearchCriteria',
    SEARCH_COMPARABLES_SORT_RESULTS: 'SCSortResults',
    SEARCH_COMPARABLES_OPEN_PROPERTY_REPORT: 'SCOpenPropertyReport',
    SEARCH_COMPARABLES_RESULTS_ORIENTATION_VERTICAL: 'SCResultsOrientationVertical',
    SEARCH_COMPARABLES_RESULTS_ORIENTATION_HORIZONTAL: 'SCResultsOrientationHorizontal',
    SEARCH_COMPARABLES_RESULTS_SCREEN_SIZE_NORMAL: 'SCResultsScreenSizeNormal',
    SEARCH_COMPARABLES_RESULTS_SCREEN_SIZE_MAXIMIZED: 'SCResultsScreenSizeMaximized',
    SEARCH_COMPARABLES_RESULTS_LIST_VIEW: 'SCResultsListView',
    SEARCH_COMPARABLES_RESULTS_CHART_VIEW: 'SCResultsChartView',
    SEARCH_COMPARABLES_RESULTS_AUTOPAN_MAP: 'SCResultsAutoPanMap',
    VOICE_SEARCH: 'VoiceSearch',
    VOICE_SEARCH_LABEL: 'Search string',
    OPEN_STREET_VIEW_BY_PIN: 'Open Street View By Pin'
}

export const GA_Ad = {
  T_PR_H1: 'Top_ParcelReg_Horizontal_1',
  T_PR_H2: 'Top_ParcelReg_Horizontal_2',
  T_PR_H3: 'Top_ParcelReg_Horizontal_3',
  T_PR_H4: 'Top_ParcelReg_Horizontal_4',

  B_PR_S1: 'Bottom_ParcelReg_Square_1',
  B_PR_S2: 'Bottom_ParcelReg_Square_2',
  B_PR_S3: 'Bottom_ParcelReg_Square_3',
  B_PR_S4: 'Bottom_ParcelReg_Square_4',

  T_TC_H1: 'Top_TitleChecks_Horizontal_1',
  T_TC_H2: 'Top_TitleChecks_Horizontal_2',
  T_TC_H3: 'Top_TitleChecks_Horizontal_3',
  T_TC_H4: 'Top_TitleChecks_Horizontal_4',

  B_TC_S1: 'Bottom_TitleChecks_Square_1',
  B_TC_S2: 'Bottom_TitleChecks_Square_2',
  B_TC_S3: 'Bottom_TitleChecks_Square_3',
  B_TC_S4: 'Bottom_TitleChecks_Square_4',
}

export class AdBannerGaAction {

  constructor(category: string, label?: string, value?: string) {
    this.category = category;
    this.label = label;
    this.value = value;
  }

  category: string;
  label?: string;
  value?: string;
}

import { AfterViewInit, Component, computed, effect, ElementRef, EventEmitter, HostListener, inject, Inject, input, Input, OnChanges, OnInit, output, Output, signal, SimpleChanges, ViewChild, WritableSignal } from '@angular/core';
import { MainMapService } from '../../../../home/home/main-map/main-map.service';
import { MainSearchCategoryType } from '../../../../core/model/interface/main-search-category-type';
import { OmnibarSearchTypeEnum } from '../../../../core/enum/omnibar-search-type-enum';
import { AutoSuggestRecentResult } from '../../../../core/model/interface/autosuggest-recent-result';
import { AutoSuggestSearchService } from '../../../../shared/service/search/autosuggest-search.service';
import { lastValueFrom, skip, takeUntil } from 'rxjs';
import * as _ from 'lodash';
import { DateUtilityService } from '../../../../shared/utility/date.utility';
import { AutoSuggestSearchMode } from '../../../../core/enum/autosuggest-search-mode';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { LotConcessionService } from '../../../../shared/service/lot-concession.service';
import { LotConcessionTownship } from '../../../model/lotconcession/township';
import { LotConcession } from '../../../model/lotconcession/concession';
import { ILot, Lot } from '../../../model/lotconcession/lot';
import { OmnibarSearchService } from '../../../../shared/service/search/omnibar-search.service';
import { AutoSuggestCurrentResultTypeEnum } from '../../../../core/enum/autosuggest-current-result-type-enum';
import { OmnibarErrorStateMatcher } from '../omnibar-error-state-matcher';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatDialog } from "@angular/material/dialog";
import { User } from '../../../../core/model/user/user';
import { OmnibarStateService } from '../../../../shared/service/search/omnibar-state.service';
import { OmnibarStateEnum } from '../../../../core/enum/omnibar-state-enum';
import { PropertyReportService } from '../../../../shared/service/property-report.service';
import { MatSelectItem } from '../../../../core/model/mat-select-item/mat-select-item';
import { UserService } from '../../../../shared/service/user.service';
import { UserAccessControl } from '../../../../core/model/user/user-access-control';
import { LroPolygonsService } from '../../../../shared/service/lro-polygons.service';
import { SearchActivity } from '../../../../core/model/omnibar/search-activity';
import { ActivityTypeEnum } from '../../../../core/enum/activity-type-enum';
import { ProductEnum } from '../../../../core/enum/product-enum';
import { OmnibarNoSearchResultsService } from '../../../../shared/service/search/omnibar-no-search-results-service';
import { DataService } from '../../../../shared/service/data.service';
import { BaseUnsubscribe } from "../../base-unsubscribe/base-unsubscribe";
import { MatSnackBar } from '@angular/material/snack-bar';
import { defaultErrorMatSnackBarConfig, LocalStorageKey } from '../../../../shared/constant/constants';
import { LroState } from '../../../../core/model/map/lro-state';
import { SearchBusyIndicatorService } from '../../../../shared/service/search/ui/search-busy-indicator.service';
import { LoggerService } from '../../../../shared/service/log/logger.service';
import { ScreenManager } from '../../../../shared/service/screen-manager.service';
import { ScreenNameEnum } from '../../../enum/screen-name.enum';
import { ThemeService } from '../../../../shared/service/theme.service';
import { Router } from "@angular/router";
import { SearchResult } from '../../../../core/model/search-result/search-result';
import { CustomValidatorService } from '../../../../shared/service/custom-validator.service';
import { TimerService } from '../../../../shared/service/timer/timer.service';
import { faCircle, faCircleInfo, faLocationDot, faMagnifyingGlassLocation, faS } from '@fortawesome/free-solid-svg-icons';
import { BrowserDetectorService } from '../../../../shared/service/browser-detector.service';
import { environment } from "../../../../../environments/environment";
import { GoogleAnalyticsService } from "../../../../shared/service/google-analytics.service";
import { AuthenticationService } from "../../../../shared/service/authentication.service";
import { StringUtility } from "../../../../shared/utility/string-utility";
import { MunicipalityService } from '../../../../shared/service/municipality.service';
import { Municipality } from '../../../../core/model/property/municipality';
import { ComparablesSearchService } from "../../../../shared/service/search/comparables-search.service";
import { EstoreProductCategoryEnum } from "../../../enum/estore-product-category-enum";
import { UrlService } from "../../../../shared/service/url.service";
import { WarningDialogData } from "../../modal/warning-dialog/warning-dialog-data";
import { WarningService } from "../../../../shared/service/warning.service";
import { faCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { GA_Button, GA_Feature, GA_Page } from '../../../../shared/constant/google-analytics-constants';
import { SearchComparablesResultService } from '../../../../shared/service/search/search-comparables-result.service';
import { SearchComparablesEnum } from '../../../../core/enum/search-comparables.enum';
import { MatButtonToggleGroup } from '@angular/material/button-toggle';
import { StreetViewService } from 'src/app/shared/service/google-maps/street-view.service';
import { LocationService } from 'src/app/shared/service/location.service';
import { CameraAccessDialogComponent } from '../../camera-access-dialog/camera-access-dialog.component';
import { PointOfView } from 'src/app/core/model/spatial/point-of-view';
import {CondoSummary} from "../../../model/property/condo-summary";

declare var $: any;

@Component({
  selector: 'gema3g-omnibar-search',
  templateUrl: './omnibar-search.component.html',
  styleUrls: ['./omnibar-search.component.scss']
})
export class OmnibarSearchComponent extends BaseUnsubscribe implements OnInit, OnChanges, AfterViewInit {

  constructor() {
    super();

    this.initAccessCamera();
    this.isFirefoxBrowser = this.browserDetectorService.getBrowserName().toLowerCase().includes('firefox');
  }

  protected authService = inject(AuthenticationService);
  private mainMapService = inject(MainMapService);
  private customValidatorService = inject(CustomValidatorService);
  private autoSuggestSearchService = inject(AutoSuggestSearchService);
  private omnibarSearchService = inject(OmnibarSearchService);
  private omnibarStateService = inject(OmnibarStateService);
  private lotConcessionService = inject(LotConcessionService);
  private dateUtilityService = inject(DateUtilityService);
  private propertyReportService = inject(PropertyReportService);
  public userService = inject(UserService);
  private lroPolygonsService = inject(LroPolygonsService);
  private omnibarNoSearchResultsService = inject(OmnibarNoSearchResultsService);
  private formBuilder = inject(FormBuilder);
  private dialog = inject(MatDialog);
  private dataService = inject(DataService);
  private _snackBar = inject(MatSnackBar);
  private searchBusyIndicatorService = inject(SearchBusyIndicatorService);
  private loggerService = inject(LoggerService);
  private municipalityService = inject(MunicipalityService);
  private screenManager = inject(ScreenManager);
  private timerService = inject(TimerService);
  private browserDetectorService = inject(BrowserDetectorService);
  protected themeService = inject(ThemeService);
  private router = inject(Router);
  private gaService = inject(GoogleAnalyticsService);
  private comparablesSearchService = inject(ComparablesSearchService);
  private searchComparablesResultService = inject(SearchComparablesResultService);
  private urlService = inject(UrlService);
  private warningService = inject(WarningService);
  protected readonly faCircleInfo = faCircleInfo;
  private streetViewService = inject(StreetViewService);
  private locationService = inject(LocationService);

  OSTE = OmnibarSearchTypeEnum;
  AGSM = AutoSuggestSearchMode;
  PE = ProductEnum;
  userAccessControls: UserAccessControl;
  user: User;
  omnibarErrorStateMatcher = new OmnibarErrorStateMatcher();
  searchTypes: MainSearchCategoryType[];
  recentSearches: AutoSuggestRecentResult[];
  recentlyViewedProperties: AutoSuggestRecentResult[];
  autoSuggestSearchResults: any;
  searchFormGroup: FormGroup;
  wildCardSearchFormGroup: FormGroup;
  ownerSearchFormGroup: FormGroup;
  instrumentPlanSearchFormGroup: FormGroup;
  lotConcessionSearchFormGroup: FormGroup;
  selectedSearchType: string;
  selectedLro: string;
  selectedTownshipLot: ILot;
  lros: any[];
  omnibarSearchByWildcardResults: any = {};
  omnibarSearchByOwnerResults: any = {};
  omnibarSearchByInstrumentResults: any = {};
  omnibarSearchByLotConsessionResults: any = {};
  municipalities: Municipality[];
  lots: Lot = new Lot();
  concessions: LotConcession = new LotConcession();
  townships: LotConcessionTownship = new LotConcessionTownship();
  isSearching: boolean;
  noLotConcessionDialogRef: any;
  lastAutoSuggestSearchString: string = '';
  lastAutoSuggestResultTypeEnum: AutoSuggestCurrentResultTypeEnum;
  selectedLroDisplay: string;
  logo: string = 'assets/img/geowarehouse-logo1.png';
  logoSm: string = 'assets/img/geowarehouse-mini-logo.png';
  lightLogo: string = 'assets/img/footer-left-logo.png';
  private _timeout: number = 100;
  showInput = false;
  recentSearchesLoading: WritableSignal<boolean> = signal(false);
  recentViewsLoading: WritableSignal<boolean> = signal(false);
  sessionTimedOut: WritableSignal<boolean> = signal(false);
  currentAutoSuggestMode: WritableSignal<AutoSuggestSearchMode> = signal(AutoSuggestSearchMode.SHOW_RECENT_RESULTS_ONLY);
  isFirefoxBrowser: boolean;
  faCircle = faCircle;
  faMagnifyingGlassLocation = faMagnifyingGlassLocation;
  faLocationDot = faLocationDot;
  faS = faS;
  searchTypeToolTip: string;
  lroToolTip: string = DataService.LRO_REQUIRED;
  searchShortcutTooltip: string = DataService.OMNIBAR_SEARCH_SHORTCUT_KEY_TOOLTIP;
  comparableSearchCounter: WritableSignal<number> = signal(0);
  faCircleLeft = faCircleLeft;
  searchComparablesToggleGroup: MatButtonToggleGroup
  selectedToggle: string = '';
  latitude: number;
  longitude: number;
  heading: number| null;
  panorama: google.maps.StreetViewPanorama;
  addressQueryResult: any = {};
  videoElement: HTMLVideoElement | undefined;
  private mediaStream: MediaStream | undefined;
  pin: string;
  address: string;
  condoStreetAddress: string
  isLoading: boolean = false;
  isProcessing: boolean = false;
  condoSearchResults: any = {};
  freeholdSearchResults: any = {};
  geoWatchId: number | undefined;

  @ViewChild(MatAutocompleteTrigger) autoComplete: MatAutocompleteTrigger;
  @ViewChild("searchTypePopover") searchTypePopover: any;
  @ViewChild("autoSuggestAll") autoSuggestAllRef: ElementRef;
  @ViewChild("firstName") firstNameRef: ElementRef;
  @ViewChild("lastName") lastNameRef: ElementRef;
  @ViewChild("instrumentPlanNumber") instrumentPlanNumberRef: ElementRef;
  @ViewChild("recentSearchesDiv") recentSearchesDivRef: ElementRef;
  @ViewChild("recentlyViewedDiv") recentlyViewedDivRef: ElementRef;
  @Output() searchResultsOutcome = new EventEmitter<boolean>();
  @Output() moveMapControls = new EventEmitter();
  @Output() searchComparablesFormOpened = new EventEmitter();
  @Input() searchEnabled: boolean;
  @Input() searchComparablesEnabled: boolean | undefined;
  @Input() renewNowButtonEnabled: boolean | undefined;
  @Input() searchComparablesFormVisible?: boolean;
  @Input() addressUpdating: boolean;
  hoodQMapClosedSignal = output<boolean>({alias: 'hoodQMapClosed'});
  comparableSearchCounterTooltipText: string  = DataService.COMPARABLE_SEARCH_COUNTER_TOOLTIP;
  comparableSearchToggleTooltipText: string  = DataService.COMPARABLE_SEARCH_TOGGLE_TOOLTIP;

  getLroName = (lroId: string): string | null => {
    return this.mainMapService.getLroPolygonService().getNameById(lroId);
  }

  openSnackBarError(err: string) {
    this._snackBar.open(err, 'Close', defaultErrorMatSnackBarConfig);
  }
  onAutoSuggestInputFocus = () => {
    //force the omnibar to show the recent entries when the user first enters the search by all field
    this.currentAutoSuggestMode.set(AutoSuggestSearchMode.SHOW_RECENT_RESULTS_ONLY);

    this.recentSearchesLoading.set(true);
    this.recentViewsLoading.set(true);

    setTimeout(() => {
      this.getRecentSearchResults();
    }, 100);
  }

  getRecentSearchResults = () => {

    setTimeout(async () => {
      try {
        this.recentSearches = await lastValueFrom(this.autoSuggestSearchService.getRecentlySearchedProperties(), { defaultValue: [] });
        this.loggerService.logDebug('omnibar recently searched properties', this.recentSearches);

        //augment results
        this.recentSearches.forEach(result => {
          result.lroName = this.getLroName(result.lro?.trim());
        })

      } catch (e) {
        this.loggerService.logError(e);
        //this._snackBar.open(ErrorUtil.OMNIBAR_RECENTLY_SEARCHED_ERROR, 'Close', defaultErrorMatSnackBarConfig);

      } finally {
        this.recentSearchesLoading.set(false);
      }
    }, 100);

    setTimeout(async () => {
      try {
        //this.loggerService.logDebugWithTime('getting omnibar recently viewed properties');
        this.recentlyViewedProperties = await lastValueFrom(this.autoSuggestSearchService.getRecentlyViewedProperties(), { defaultValue: [] });
        //this.loggerService.logDebugWithTime('omnibar recently viewed properties', this.recentlyViewedProperties);

        //this.loggerService.logDebugWithTime('augmenting omnibar recently viewed properties');
        //augment results
        this.recentlyViewedProperties.forEach(result => {
          result.daysElapsed = this.dateUtilityService.getDaysBetween(result.timestamp, new Date().getTime());
        });
        //this.loggerService.logDebugWithTime('augmenting completed');

      } catch (e) {
        this.loggerService.logError(e);
      } finally {
        this.recentViewsLoading.set(false);
        //this.loggerService.logDebugWithTime('this.recentViewsLoading');
      }
    }, 100);
  }

  //triggered from the displayWith template attribute
  displayWithSelectedEntry = (selection: any): string => {

    switch (this.lastAutoSuggestResultTypeEnum) {
      case AutoSuggestCurrentResultTypeEnum.RECENTLY_SEARCHED:
        if (selection.categoryType == OmnibarSearchTypeEnum.ALL_DISPLAY) {
          if (typeof (selection) === 'object') {
            this.lastAutoSuggestSearchString = selection.text;
            return this.lastAutoSuggestSearchString;
          }
        }
        break;

      case AutoSuggestCurrentResultTypeEnum.RECENTLY_VIEWED:
        if (typeof (selection) === 'object') {
          this.lastAutoSuggestSearchString = selection.text;
          return this.lastAutoSuggestSearchString;
        }
    }

    return selection;
  }

  //for some odd reason, the displayWith template attribute wouldn't trigger for autosuggest list selections
  displayWithSelectedEntryForAutoSuggestResults = (selection: any) => {

    switch (this.lastAutoSuggestResultTypeEnum) {
      case AutoSuggestCurrentResultTypeEnum.MUNICIPALITY:
        this.lastAutoSuggestSearchString = selection.text.join(', ');
        this.updateSearchAllFieldWithSelectedAutosuggestEntry();
        break;

      case AutoSuggestCurrentResultTypeEnum.OWNER:
        this.lastAutoSuggestSearchString = selection.text?.[0];
        this.updateSearchAllFieldWithSelectedAutosuggestEntry();
        break;

      case AutoSuggestCurrentResultTypeEnum.BLOCK:
        this.lastAutoSuggestSearchString = selection.text?.[0];
        this.updateSearchAllFieldWithSelectedAutosuggestEntry();
        break;

      case AutoSuggestCurrentResultTypeEnum.FREEHOLD:
        if (this.dataService.isPin(this.lastAutoSuggestSearchString) && this.dataService.isPin(selection.id) && this.lastAutoSuggestSearchString == selection.id) {
          return selection.id;
        } else {
          this.lastAutoSuggestSearchString = selection.text?.[0];
          this.updateSearchAllFieldWithSelectedAutosuggestEntry();
        }
        break;

      case AutoSuggestCurrentResultTypeEnum.CONDO:
        this.lastAutoSuggestSearchString = selection.text?.[0];
        this.updateSearchAllFieldWithSelectedAutosuggestEntry();
        break;

      case AutoSuggestCurrentResultTypeEnum.NOT_APPLICABLE:
        if (_.isEmpty(selection.text?.[0])) {
          return this.lastAutoSuggestSearchString;
        } else {
          this.lastAutoSuggestSearchString = selection.text?.[0];
          this.updateSearchAllFieldWithSelectedAutosuggestEntry();
          break;
        }
    }

  }

  updateSearchAllFieldWithSelectedAutosuggestEntry = () => {
    this.wildCardSearchFormGroup.get('autoSuggestAll')?.setValue(this.lastAutoSuggestSearchString);
  }

  onRecentlySearchedPropertySelected = (recentResult: AutoSuggestRecentResult) => {
    if (recentResult?.categoryType) {
      this.lastAutoSuggestResultTypeEnum = AutoSuggestCurrentResultTypeEnum.RECENTLY_SEARCHED;
      setTimeout(() => {
        this.getRecentlySearchedProperty(recentResult);
      }, this._timeout);  //to give [displayWith] in the component template time to execute
    }

  }

  getRecentlySearchedProperty = async (recentResult: AutoSuggestRecentResult) => {
    try {

      if (recentResult.categoryType == OmnibarSearchTypeEnum.OWNER_DISPLAY) {
        //if the user previously searched from the owner form, then recreate the owner form with the search text
        this.selectedSearchType = OmnibarSearchTypeEnum.OWNER_VALUE;
        this.ownerSearchFormGroup.reset();

        let splittedName: string[] = recentResult.text.split(' ');
        if (splittedName.length > 1) {
          let firstName = splittedName.slice(0, splittedName.length - 1).toString();
          let lastName = splittedName[splittedName.length - 1];
          if (firstName) firstName = _.replace(firstName, ',', ' ');
          this.ownerSearchFormGroup.controls['firstName'].setValue(firstName);
          this.ownerSearchFormGroup.controls['lastName'].setValue(lastName);
        } else {
          this.ownerSearchFormGroup.controls['lastName'].setValue(recentResult.text);
        }

      } else if (recentResult.categoryType == OmnibarSearchTypeEnum.INSTRUMENT_DISPLAY) {
        //if the user previously searched from the instrument/plan form, then recreate the instrument/plan form with the search text
        this.selectedSearchType = OmnibarSearchTypeEnum.INSTRUMENT_VALUE;
        this.instrumentPlanSearchFormGroup.reset();
        //this.initializeSearchForm(OmnibarSearchTypeEnum.INSTRUMENT_DISPLAY);
        this.instrumentPlanSearchFormGroup.controls['instrumentPlanNumber'].setValue(recentResult.text);
      }

      this.autoComplete?.closePanel();
      this.closeOtherScreens();
      this.mainMapService.clearAllRenderedMapObjects();
      this.screenManager.showScreen(ScreenNameEnum.OMNIBAR_SEARCH_RESULTS);
      this.updateSearchStatus(true);

      let resultsFound: boolean = false;

      if (recentResult.categoryType == OmnibarSearchTypeEnum.INSTRUMENT_DISPLAY && recentResult.text && recentResult.lro) {
        this.omnibarSearchByInstrumentResults = await lastValueFrom(this.omnibarSearchService.searchPropertiesByInstrument(recentResult.text, recentResult.lro), { defaultValue: new SearchResult() });
        resultsFound = !_.isEmpty(this.omnibarSearchByInstrumentResults) && this.omnibarSearchByInstrumentResults.document;
      } else {
        this.omnibarSearchByWildcardResults = await lastValueFrom(this.omnibarSearchService.searchPropertiesByAutoSuggest(AutoSuggestSearchMode.GET_RECENTLY_SEARCHED_SUGGESTED_RESULT, recentResult.url, recentResult.lro), {defaultValue: {}});
        resultsFound = this.isOmnibarSearchResultsFound(this.omnibarSearchByWildcardResults) || this.omnibarSearchByInstrumentResults.document;
      }

      if (resultsFound) {
        this.searchResultsFound();

        this.relogSearchActivity(new SearchActivity(recentResult));

        setTimeout(() => {
          this.mainMapService.renderOmnibarSearchResultsMarkers(AutoSuggestSearchMode.GET_RECENTLY_SEARCHED_SUGGESTED_RESULT, this.omnibarSearchByWildcardResults);

          //include owner name results, if any
          if (this.isOmnibarOwnerSearchResultsFound(this.omnibarSearchByWildcardResults)) {
            this.mainMapService.renderOwnerPropertyMarkers(this.omnibarSearchByWildcardResults, false);
          }

          if (_.trim(recentResult.lro)) {
            this.selectedLro = _.trim(recentResult.lro);
            this.lroPolygonsService.setCurrentLro(this.selectedLro);
          } else {
            this.setLroPostSearchResults(recentResult.text);
          }
        }, this._timeout);

      } else {
        this.noSearchResultsFound();
        this.loggerService.logDebug(`no properties found for recently searched text ${recentResult.text}`);
      }

    } catch (e) {
      this.loggerService.logError(`error getting recently searched property ${recentResult.text}`, e);

    } finally {
      this.updateSearchStatus(false);
      this.blurAutoSuggestField();
    }

  }

  onRecentlyViewedPropertySelected = (recentResult: AutoSuggestRecentResult) => {

    this.lastAutoSuggestResultTypeEnum = AutoSuggestCurrentResultTypeEnum.RECENTLY_VIEWED;

    setTimeout(() => {
      this.getRecentlyViewedProperty(recentResult.pin);
    }, this._timeout);  //to give [displayWith] in the component template time to execute
  }

  getRecentlyViewedProperty = async (pin: string) => {
    try {
      this.closeOtherScreens();
      this.mainMapService.clearAllExceptRenderedMapObjectsForScreen(ScreenNameEnum.PROPERTY_REPORT);
      this.updateSearchStatus(true);

      //show the property report screen and defer all succeeding actions to the property report component
      this.propertyReportService.showPropertyReportByPinDeferData(pin);

    } catch (e) {
      this.noSearchResultsFound();
      this.loggerService.logError(`error getting recently viewed property ${pin}`, e);

    } finally {
      this.updateSearchStatus(false);
      this.blurAutoSuggestField();
    }

  }

  isDisplayingRecentResults = () => {
    return (this.currentAutoSuggestMode() == AutoSuggestSearchMode.SHOW_RECENT_RESULTS_ONLY);
  }

  isDisplayingAutoSuggestResults = () => {
    return (this.currentAutoSuggestMode() == AutoSuggestSearchMode.SHOW_AUTOSUGGEST_RESULTS_ONLY);
  }

  onOmnibarInputKeyUp = (event: any) => {
    let autoSuggestString: string = _.trim(event.target.value);
    this.lastAutoSuggestSearchString = autoSuggestString;

    this.currentAutoSuggestMode.set((autoSuggestString && autoSuggestString.length >= OmnibarSearchService.MINIMUM_CHARACTERS_FOR_AUTOSUGGEST_SEARCH) ? AutoSuggestSearchMode.SHOW_AUTOSUGGEST_RESULTS_ONLY : AutoSuggestSearchMode.SHOW_RECENT_RESULTS_ONLY);

    let ignoreKeys: string[] = ['ArrowDown', 'ArrowUp', 'ArrowLeft', 'ArrowRight', 'ControlLeft', 'ControlRight', 'AltLeft', 'AltRight', 'Escape'];

    if (this.isDisplayingAutoSuggestResults() && event.code && !ignoreKeys.includes(event.code)) {
      this.getAutoSuggestSearchResults();

      //todo close the info bubble until we figure out what's causing it to receive focus in autosuggest mode
      this.mainMapService.closeCurrentMarkerInfoBubble();
    }
  }

  getAutoSuggestSearchResults = async () => {

    let autoSuggestSearchString: string = '';
    try {
      if (this.screenManager.isScreenVisible(ScreenNameEnum.SEARCH_COMPARABLES_RESULTS) ||
          this.screenManager.isScreenVisible(ScreenNameEnum.PROPERTY_REPORT)) {
        //keep these screens visible when autosuggest entries are being presented
      } else {
        this.closeOtherScreens();
      }

      autoSuggestSearchString = this.wildCardSearchFormGroup.controls['autoSuggestAll'].value;
      this.autoSuggestSearchResults = await lastValueFrom(this.autoSuggestSearchService.getAutoSuggestSearchResults(this.selectedLro, autoSuggestSearchString), { defaultValue: {} });
      this.loggerService.logDebug(`autosuggest results for search text ${autoSuggestSearchString}`, this.autoSuggestSearchResults);

      this.updateUI();

    } catch (e) {
      this.loggerService.logError(`error getting autosuggest result property ${autoSuggestSearchString}`, e);

    } finally {
    }
  }

  onAutoSuggestSearchResultSelected = (autoSuggestSearchResult: AutoSuggestRecentResult) => {
    this.autoComplete?.closePanel();
    let typedText = this.autoSuggestAllRef.nativeElement.value;

    this.closeOtherScreens();

    setTimeout(() => {
      this.getAutoSuggestedResult(typedText, autoSuggestSearchResult);
    }, this._timeout);
  }

  getAutoSuggestedResult = async (typedText: string, autoSuggestSearchResult: any) => {

    this.lastAutoSuggestResultTypeEnum = autoSuggestSearchResult.type;
    this.displayWithSelectedEntryForAutoSuggestResults(autoSuggestSearchResult);  // for some odd reason, the displayWith template attribute wouldn't trigger for autosuggest list selections so we are invoking it directly instead

    this.mainMapService.clearAllRenderedMapObjects();
    this.screenManager.showScreen(ScreenNameEnum.OMNIBAR_SEARCH_RESULTS);

    switch (autoSuggestSearchResult.type) {
      case AutoSuggestCurrentResultTypeEnum.OWNER:
        let lro = (autoSuggestSearchResult.lro !== undefined) ? autoSuggestSearchResult.lro : this.selectedLro;
        try {
          this.lastAutoSuggestResultTypeEnum = AutoSuggestCurrentResultTypeEnum.OWNER;
          this.updateSearchStatus(true);
          this.omnibarSearchByWildcardResults = await lastValueFrom(this.omnibarSearchService.searchPropertiesByAutoSuggest(AutoSuggestSearchMode.GET_AUTO_SUGGESTED_OWNER_RESULT, autoSuggestSearchResult.url, this.selectedLro), { defaultValue: {} });

          if (this.isOmnibarSearchResultsFound(this.omnibarSearchByWildcardResults)) {
            this.searchResultsFound();
            setTimeout(() => {
              this.mainMapService.renderOmnibarSearchResultsMarkers(AutoSuggestSearchMode.WILDCARD_SEARCH, this.omnibarSearchByWildcardResults);
            }, this._timeout);

            this.lroPolygonsService.setCurrentLro(lro);

          } else {
            this.noSearchResultsFound();
            console.info(`no properties found for search text ${typedText} [auto-suggested url ${autoSuggestSearchResult.url}]`);
          }
        } catch (e) {
          this.loggerService.logError(`error getting autosuggest search results for ${typedText}`, e);

        } finally {
          this.logSearchActivity(OmnibarSearchTypeEnum.ALL_DISPLAY, AutoSuggestCurrentResultTypeEnum.OWNER, this.lastAutoSuggestSearchString, autoSuggestSearchResult.text?.[0], lro, autoSuggestSearchResult.url);
          this.blurAutoSuggestField();
          this.updateSearchStatus(false);
        }

        break;


      case AutoSuggestCurrentResultTypeEnum.MUNICIPALITY:
        try {
          this.lastAutoSuggestResultTypeEnum = AutoSuggestCurrentResultTypeEnum.MUNICIPALITY;
          this.updateSearchStatus(true);

          let lroId: string = autoSuggestSearchResult.id;
          this.lroPolygonsService.setCurrentLroState(new LroState(lroId, true));

          setTimeout(async () => {
            //render the municipality's polygon and center the map on it
            let municipalityDescription: string = '';
            try {
              municipalityDescription = autoSuggestSearchResult.text[0];
              let municipality: Municipality | undefined;
              this.municipalities = await lastValueFrom(this.municipalityService.getMunicipalitiesByLro(lroId));

              municipality = this.municipalities?.find((municipality) => {
                return municipality.lro == lroId && municipality.municipality.includes(municipalityDescription);
              });

              if (municipality) {
                let municipalityIds: string[] = [];
                municipalityIds.push(municipality.munId);
                this.mainMapService.renderMunicipalityPolygon(municipalityIds);
                this.loggerService.logDebug(`focused search result on municipality ${municipalityDescription}`);

              } else {
                this.loggerService.logDebug(`municipality ${municipalityDescription} not found`);
              }

            } catch (e) {
              this.loggerService.logError(`error focusing search result on municipality ${municipalityDescription}`);
            }
          }, 100);

          // Refer to GWM-14302. For municipality search, it's expected no properties found.
          // But, we don't want to show the warning to the user.
          this.searchResultsFound();
        } catch (e) {
          this.loggerService.logError(`error getting autosuggest search results for ${typedText}`, e);

        } finally {
          this.logSearchActivity(OmnibarSearchTypeEnum.ALL_DISPLAY, AutoSuggestCurrentResultTypeEnum.MUNICIPALITY, this.lastAutoSuggestSearchString, this.lastAutoSuggestSearchString, autoSuggestSearchResult.id, "omnibar/properties?searchText=" + encodeURIComponent(this.lastAutoSuggestSearchString) + "&lro=" + autoSuggestSearchResult.id + "&pageSize=20&page=0");
          this.blurAutoSuggestField();
          this.updateSearchStatus(false);
        }

        break;

      case AutoSuggestCurrentResultTypeEnum.FREEHOLD:
        try {
          this.lastAutoSuggestResultTypeEnum = AutoSuggestCurrentResultTypeEnum.FREEHOLD;
          this.updateSearchStatus(true);
          this.omnibarSearchByWildcardResults = await lastValueFrom(this.omnibarSearchService.searchPropertiesByAutoSuggest(AutoSuggestSearchMode.GET_AUTO_SUGGESTED_FREEHOLD_RESULT, autoSuggestSearchResult.url, this.selectedLro), { defaultValue: {} });

          if (this.isOmnibarSearchResultsFound(this.omnibarSearchByWildcardResults)) {
            this.searchResultsFound();
            setTimeout(() => {
              this.mainMapService.renderOmnibarSearchResultsMarkers(AutoSuggestSearchMode.WILDCARD_SEARCH, this.omnibarSearchByWildcardResults);

              let mapState = this.mainMapService.getMapState();
              this.mainMapService.setMapCenter(mapState.center);
              this.mainMapService.setMapZoomLevel(mapState.zoomLevel);

              if (this.omnibarSearchByWildcardResults.searchResult?.[0]?.lro != null) {
                this.selectedLro = this.omnibarSearchByWildcardResults.searchResult?.[0]?.lro;
              } else {
                this.setLroPostSearchResults(typedText);
              }

            }, this._timeout);

          } else {
            this.noSearchResultsFound();
            this.loggerService.logDebug(`no properties found for search text ${typedText} [auto-suggested url ${autoSuggestSearchResult.url}]`);
          }
        } catch (e) {
          this.loggerService.logError(`error getting autosuggest search results for ${typedText}`, e);

        } finally {
          let lro = (autoSuggestSearchResult.lro !== undefined) ? autoSuggestSearchResult.lro : this.selectedLro;
          if (this.dataService.isPin(autoSuggestSearchResult.id)) {
            this.logSearchActivity(OmnibarSearchTypeEnum.ALL_DISPLAY, AutoSuggestCurrentResultTypeEnum.FREEHOLD, this.lastAutoSuggestSearchString, autoSuggestSearchResult.text?.[0], lro, autoSuggestSearchResult.url);
          } else {
            this.logSearchActivity(OmnibarSearchTypeEnum.ALL_DISPLAY, AutoSuggestCurrentResultTypeEnum.FREEHOLD, this.lastAutoSuggestSearchString, autoSuggestSearchResult.text?.[0], lro, "omnibar/properties?searchText=" + encodeURIComponent(this.lastAutoSuggestSearchString) + "&lro=" + this.selectedLro + "&pageSize=20&page=0");
          }

          this.blurAutoSuggestField();
          this.updateSearchStatus(false);
        }

        break;

      case AutoSuggestCurrentResultTypeEnum.CONDO:
        try {
          this.lastAutoSuggestResultTypeEnum = AutoSuggestCurrentResultTypeEnum.CONDO;
          this.updateSearchStatus(true);
          this.omnibarSearchByWildcardResults = await lastValueFrom(this.omnibarSearchService.searchPropertiesByAutoSuggest(AutoSuggestSearchMode.GET_AUTO_SUGGESTED_CONDO_RESULT, autoSuggestSearchResult.url, this.selectedLro), { defaultValue: {} });

          if (this.isOmnibarSearchResultsFound(this.omnibarSearchByWildcardResults)) {
            this.searchResultsFound();

            setTimeout(() => {
              this.mainMapService.renderOmnibarSearchResultsMarkers(AutoSuggestSearchMode.WILDCARD_SEARCH, this.omnibarSearchByWildcardResults);
              this.setLroPostSearchResults(typedText);
            }, this._timeout);

          } else {
            this.noSearchResultsFound();
            this.loggerService.logDebug(`no properties found for search text ${typedText} [auto-suggested url ${autoSuggestSearchResult.url}]`);
          }
        } catch (e) {
          this.loggerService.logError(`error getting autosuggest search results for ${typedText}`, e);

        } finally {
          this.logSearchActivity(OmnibarSearchTypeEnum.ALL_DISPLAY, AutoSuggestCurrentResultTypeEnum.CONDO, this.lastAutoSuggestSearchString, autoSuggestSearchResult.text?.[0], autoSuggestSearchResult.lro, autoSuggestSearchResult.url);
          this.blurAutoSuggestField();
          this.updateSearchStatus(false);
        }

        break;

      case AutoSuggestCurrentResultTypeEnum.BLOCK:
        try {
          this.lastAutoSuggestResultTypeEnum = AutoSuggestCurrentResultTypeEnum.BLOCK;
          this.updateSearchStatus(true);
          this.omnibarSearchByWildcardResults = await lastValueFrom(this.omnibarSearchService.searchPropertiesByAutoSuggest(AutoSuggestSearchMode.GET_AUTO_SUGGESTED_BLOCK_RESULT, autoSuggestSearchResult.url, this.selectedLro), { defaultValue: {} });

          if (this.isOmnibarSearchResultsFound(this.omnibarSearchByWildcardResults)) {
            this.searchResultsFound();
            setTimeout(() => {
              this.mainMapService.renderOmnibarSearchResultsMarkers(AutoSuggestSearchMode.WILDCARD_SEARCH, this.omnibarSearchByWildcardResults);
              this.setLroPostSearchResults(typedText);
            }, this._timeout);

          } else {
            this.noSearchResultsFound();
            this.loggerService.logDebug(`no properties found for search text ${typedText} [auto-suggested url ${autoSuggestSearchResult.url}]`);
          }
        } catch (e) {
          this.loggerService.logError(`error getting autosuggest search results for ${typedText}`, e);

        } finally {
          this.logSearchActivity(OmnibarSearchTypeEnum.ALL_DISPLAY, AutoSuggestCurrentResultTypeEnum.BLOCK, this.lastAutoSuggestSearchString, autoSuggestSearchResult.text?.[0], autoSuggestSearchResult.lro, autoSuggestSearchResult.url);
          this.blurAutoSuggestField();
          this.updateSearchStatus(false);
        }

        break;

      case AutoSuggestCurrentResultTypeEnum.NOT_APPLICABLE:
        try {
          this.lastAutoSuggestResultTypeEnum = AutoSuggestCurrentResultTypeEnum.NOT_APPLICABLE;
          this.updateSearchStatus(true);
          this.omnibarSearchByWildcardResults = await lastValueFrom(this.omnibarSearchService.searchPropertiesByAutoSuggest(AutoSuggestSearchMode.GET_AUTO_SUGGESTED_NOT_APPLICABLE_RESULT, autoSuggestSearchResult.url, this.selectedLro), { defaultValue: {} });

          if (this.isOmnibarSearchResultsFound(this.omnibarSearchByWildcardResults)) {
            this.searchResultsFound();
            setTimeout(() => {
              this.mainMapService.renderOmnibarSearchResultsMarkers(AutoSuggestSearchMode.WILDCARD_SEARCH, this.omnibarSearchByWildcardResults);
            }, this._timeout);

          } else {
            this.noSearchResultsFound();
            this.loggerService.logDebug(`no properties found for search text ${typedText} [auto-suggested url ${autoSuggestSearchResult.url}]`);
          }
        } catch (e) {
          this.loggerService.logError(`error getting autosuggest search results for ${typedText}`, e);

        } finally {
          this.logSearchActivity(OmnibarSearchTypeEnum.ALL_DISPLAY, AutoSuggestCurrentResultTypeEnum.NOT_APPLICABLE, this.lastAutoSuggestSearchString, autoSuggestSearchResult.text?.[0], autoSuggestSearchResult.lro, autoSuggestSearchResult.url);
          this.blurAutoSuggestField();
          this.updateSearchStatus(false);
        }

        break;
    }

  }

  blurAutoSuggestField = () => {
    this.autoSuggestAllRef.nativeElement?.blur();
  }

  relogSearchActivity = async (searchActivity: SearchActivity) => {
    this.loggerService.logDebug(`re-logging search activity`, searchActivity);
    await lastValueFrom(this.autoSuggestSearchService.saveRecentSearchActivity(searchActivity));
  }

  logSearchActivity = async (searchCategory: OmnibarSearchTypeEnum, searchType: string, searchText: string, displayText: string, lro: string, url: string) => {
    let searchActivity: SearchActivity = new SearchActivity();
    searchActivity.activityType = ActivityTypeEnum.RECENT_SEARCH;
    searchActivity.url = url;
    searchActivity.type = searchType;
    searchActivity.text = displayText;
    searchActivity.lro = lro;
    searchActivity.categoryType = searchCategory;

    this.loggerService.logDebug(`logging search activity`, searchActivity);
    await lastValueFrom(this.autoSuggestSearchService.saveRecentSearchActivity(searchActivity));
  }

  //TODO: the case values here need to be modified when we model the search results
  isOmnibarSearchResultsFound = (searchResults?: any) => {
    switch (searchResults.activityType) {
      case "NAME_SEARCH":
        return (!_.isEmpty(searchResults?.ownerToPropertyMap));
        break;
      default:
        return (!_.isEmpty(searchResults?.searchResult)) || (searchResults?.document);
        break;
    }
  }

  isOmnibarOwnerSearchResultsFound = (searchResults: any) => {
    return (!_.isEmpty(searchResults?.ownerToPropertyMap));
  }

  onSearchTypeSelected = (event: any) => {
    this.gaService?.buttonClicked(GA_Button.SEARCH_BY, GA_Button.SEARCH_BY_LABEL);
    this.screenManager.hideScreen(ScreenNameEnum.PROPERTY_REPORT);

    switch (this.selectedSearchType) {
      case OmnibarSearchTypeEnum.ALL_VALUE:
        this.wildCardSearchFormGroup.reset();
        break;

      case OmnibarSearchTypeEnum.OWNER_VALUE:
        this.ownerSearchFormGroup.reset();
        break;

      case OmnibarSearchTypeEnum.INSTRUMENT_VALUE:
        this.instrumentPlanSearchFormGroup.reset();
        break;

      case OmnibarSearchTypeEnum.LOT_CONCESSION_VALUE:
        this.lotConcessionSearchFormGroup.reset();
        this.getTownship(this.selectedLro);
        break;
    }
  }

  onLroSelected = (event: any) => {
    this.lots = new Lot();
    this.concessions = new LotConcession();
    this.townships = new LotConcessionTownship();

    let lroId: string = event.source.value;

    //todo: make the change such that the form also picks up what it needs from the new lro, so that we do not have to hide the form
    this.screenManager.hideScreen(ScreenNameEnum.SEARCH_COMPARABLES_FORM);

    setTimeout(() => {
      this.lroPolygonsService.setCurrentLroState(new LroState(lroId, true));
    }, 100);
  }

  getSelectedLroDisplay = (lroId: string) => {
    return "In ".concat(this.lroPolygonsService.getNameById(lroId)!, " (", lroId, ")");
  }

  onTownshipLotSelected = async (lot: ILot, event: any) => {
    if (event.isUserInput && this.selectedTownshipLot?.lotId != lot.lotId) { //this is necessary since mat-select onSelectionChange event fires twice, we're only interested in the new selected value
      try {
        this.screenManager.hideScreen(ScreenNameEnum.OMNIBAR_SEARCH_RESULTS);
        this.updateSearchStatus(true);
        this.selectedTownshipLot = lot;
        let townshipPolygon = await lastValueFrom(this.omnibarSearchService.getTownshipPolygon(lot.lot, lot.lotId), { defaultValue: {} });
        this.mainMapService.renderTownshipLotPolygon(townshipPolygon?.spatialData[0]?.polygon);
      } catch (e) {
      } finally {
        this.updateSearchStatus(false);
      }
    }
  }

  private isEmptyFieldValue = (form: FormGroup, field: string): boolean => {
    return (_.isEmpty(_.trim(form.get(field)?.value)));
  }

  voiceSearchRecognizedText = (voiceText: string | null) => {
    this.loggerService.logDebug(`omnibar voice search text: ${voiceText}`);

    if (voiceText) {
      //this._snackBar.open(voiceText, 'Close', defaultMatSnackBarConfig);
      let form: FormGroup = this.wildCardSearchFormGroup;
      form.get('autoSuggestAll')?.setValue(voiceText);
      this.lastAutoSuggestSearchString = voiceText;
      this.gaService.featureClicked(GA_Feature.VOICE_SEARCH, GA_Feature.VOICE_SEARCH_LABEL, voiceText);
    }
  }

  onFormSubmitted = async () => {

    this.closeOtherScreens();
    this.screenManager.showScreen(ScreenNameEnum.OMNIBAR_SEARCH_RESULTS);
    this.gaService?.buttonClicked(GA_Button.SEARCH_PROPERTY, GA_Button.SEARCH_PROPERTY_LABEL);
    let form: FormGroup;

    try {
      switch (this.selectedSearchType) {
        case OmnibarSearchTypeEnum.ALL_VALUE:
          form = this.wildCardSearchFormGroup;
          this.autoComplete.closePanel();
          this.loggerService.logDebug('omnibar form data submitted', form.value);

          let searchValue: any = form.controls['autoSuggestAll'].value;
          if (typeof (searchValue) === 'object') {
            form.get('autoSuggestAll')?.setValue(searchValue.text);
          }

          if (!form.valid) {
            form.get('autoSuggestAll')?.markAsDirty();
            this.getValidationErrorMessage(form, 'autoSuggestAll', 'Search text', 1);
          } else {
            form.get('autoSuggestAll')?.setValue(StringUtility.sanitizeSearchInput(form.get('autoSuggestAll')?.value?.trim()));
            this.blurAutoSuggestField();
          }

          break;

        case OmnibarSearchTypeEnum.OWNER_VALUE:
          form = this.ownerSearchFormGroup;
          this.loggerService.logDebug('omnibar form data submitted', form.value);

          if (!form.valid) {
            form.get('lastName')?.markAsDirty();
            this.getValidationErrorMessage(form, 'lastName', 'Last name or Corporation name', 2);
          } else {
            form.get('firstName')?.setValue(StringUtility.sanitizeSearchInput(form.get('firstName')?.value?.trim()));
            form.get('lastName')?.setValue(StringUtility.sanitizeSearchInput(form.get('lastName')?.value?.trim()));
            this.firstNameRef.nativeElement.blur();
            this.lastNameRef.nativeElement.blur();
          }

          break;

        case OmnibarSearchTypeEnum.INSTRUMENT_VALUE:
          form = this.instrumentPlanSearchFormGroup;
          this.loggerService.logDebug('omnibar form data submitted', form.value);

          if (!form.valid) {
            form.get('instrumentPlanNumber')?.markAsDirty();
            this.getValidationErrorMessage(form, 'instrumentPlanNumber', 'Instrument or Plan number', 2)
          } else {
            form.get('instrumentPlanNumber')?.setValue(StringUtility.sanitizeSearchInput(form.get('instrumentPlanNumber')?.value?.trim()));
            this.instrumentPlanNumberRef.nativeElement.blur();
          }

          break;

        case OmnibarSearchTypeEnum.LOT_CONCESSION_VALUE:
          form = this.lotConcessionSearchFormGroup;
          this.loggerService.logDebug('omnibar form data submitted', form.value);

          if (!form.valid) {
            form.get('lotConTownship')?.markAsDirty();
            form.get('lotConConcession')?.markAsDirty();
            form.get('lotConLot')?.markAsDirty();
            this.getValidationErrorMessage(form, 'lot', 'Lot, township and concession', 0);
          }

          break;
      }

      this.updateSearchStatus(true);

      //@ts-ignore
      if (!form.valid) {
        this.loggerService.logError('validation errors found in', 'search form', this.selectedSearchType);
        this.updateSearchStatus(false);
        return;
      }

      this.mainMapService.clearAllRenderedMapObjects();

      switch (this.selectedSearchType) {
        case OmnibarSearchTypeEnum.ALL_VALUE:
          this.loggerService.logDebug(`search form ${OmnibarSearchTypeEnum.ALL_DISPLAY} submitted`);

          var searchText: string = this.lastAutoSuggestSearchString;

          let searchActivity: SearchActivity = new SearchActivity();
          let autoSuggestValue: any = this.wildCardSearchFormGroup.controls['autoSuggestAll'].value;
          if (typeof (autoSuggestValue) === 'string') {

            if (searchText == "") {
              searchText = autoSuggestValue;
            }

            searchActivity.activityType = ActivityTypeEnum.RECENT_SEARCH;
            searchActivity.url = "omnibar/properties?searchText=" + encodeURIComponent(searchText) + "&lro=" + this.selectedLro + "&pageSize=20&page=0";
            searchActivity.type = 'O';
            searchActivity.text = searchText;
            searchActivity.lro = this.selectedLro;
            searchActivity.categoryType = OmnibarSearchTypeEnum.ALL_DISPLAY;

          } else if (typeof (autoSuggestValue) === 'object') {
            //we do not need to log the current selection from the autosuggest list as it has been logged at the time the user made a selection
            if (searchText == "") {
              searchText = autoSuggestValue.text;
            }
          }

          searchText = this.stripIllegalSearchCharacters(searchText);

          this.autoSuggestAllRef.nativeElement.value = searchText;

          this.omnibarSearchByWildcardResults = await lastValueFrom(this.omnibarSearchService.searchPropertiesByWildcard(searchText, this.selectedLro, 0, false), { defaultValue: new SearchResult() });
          this.loggerService.logDebug(`omnibar search results for wildcard search text ${searchText}`, this.omnibarSearchByWildcardResults);

          if (this.isOmnibarSearchResultsFound(this.omnibarSearchByWildcardResults)) {

            this.searchResultsFound();

            setTimeout(() => {
              this.mainMapService.renderOmnibarSearchResultsMarkers(AutoSuggestSearchMode.WILDCARD_SEARCH, this.omnibarSearchByWildcardResults);
              //include  owner name results, if any
              if (this.isOmnibarOwnerSearchResultsFound(this.omnibarSearchByWildcardResults)) {
                this.mainMapService.renderOwnerPropertyMarkers(this.omnibarSearchByWildcardResults, false);
              }
            }, this._timeout);

            let lroId: string | undefined = this.lroPolygonsService.getLroIdBySpatialPoint(this.mainMapService.getMapCenter());
            if (lroId !== undefined) {
              searchActivity.lro = lroId;
            }

            setTimeout(() => {
              this.setLroPostSearchResults(searchText);
            }, this._timeout);

          } else {
            this.noSearchResultsFound();
            this.loggerService.logDebug(`no properties found for wildcard search text ${searchText}`);
          }

          if (typeof (autoSuggestValue) === 'string') {
            await lastValueFrom(this.autoSuggestSearchService.saveRecentSearchActivity(searchActivity));
          }

          break;

        case OmnibarSearchTypeEnum.OWNER_VALUE:
          this.loggerService.logDebug(`search form ${OmnibarSearchTypeEnum.OWNER_DISPLAY} submitted`);

          let firstName = _.defaultTo(this.ownerSearchFormGroup.controls['firstName'].value, '');
          let lastName = _.defaultTo(this.ownerSearchFormGroup.controls['lastName'].value, '');
          let fullName = firstName + ' ' + lastName;

          this.ownerSearchFormGroup.controls['firstName'].setValue(firstName);
          this.ownerSearchFormGroup.controls['lastName'].setValue(lastName);

          this.omnibarSearchByOwnerResults = await lastValueFrom(this.omnibarSearchService.searchPropertiesByOwner(firstName, lastName, this.selectedLro), { defaultValue: new SearchResult() });
          this.loggerService.logDebug(`omnibar search results for owner name search text ${this.ownerSearchFormGroup.controls['firstName'].value} ${this.ownerSearchFormGroup.controls['lastName'].value}`, this.omnibarSearchByOwnerResults);

          if (this.isOmnibarOwnerSearchResultsFound(this.omnibarSearchByOwnerResults)) {
            this.searchResultsFound();
            setTimeout(() => {
              this.mainMapService.renderOwnerPropertyMarkers(this.omnibarSearchByOwnerResults, true);
            }, this._timeout);

            this.setLroPostSearchResults(fullName);

          } else {
            this.noSearchResultsFound();
            this.loggerService.logDebug(`no properties found for owner name search text ${this.ownerSearchFormGroup.controls['firstName'].value} ${this.ownerSearchFormGroup.controls['lastName'].value}`);
          }

          this.logSearchActivity(OmnibarSearchTypeEnum.OWNER_DISPLAY, 'O', fullName, fullName, this.selectedLro, "omnibar/properties?searchText=" + encodeURIComponent(fullName) + "&lro=" + this.selectedLro + "&page=0&pageSize=20");

          break;

        case OmnibarSearchTypeEnum.INSTRUMENT_VALUE:
          this.loggerService.logDebug(`search form ${OmnibarSearchTypeEnum.INSTRUMENT_DISPLAY} submitted`);

          var searchText: string = this.instrumentPlanSearchFormGroup.controls['instrumentPlanNumber'].value;
          searchText = this.stripIllegalSearchCharacters(searchText);
          this.instrumentPlanSearchFormGroup.controls['instrumentPlanNumber'].setValue(searchText);

          this.omnibarSearchByInstrumentResults = await lastValueFrom(this.omnibarSearchService.searchPropertiesByInstrument(searchText, this.selectedLro), { defaultValue: new SearchResult() });
          this.loggerService.logDebug(`omnibar search results for instrument/plan number search text ${searchText}`, this.omnibarSearchByInstrumentResults);

          if (!_.isEmpty(this.omnibarSearchByInstrumentResults) && this.omnibarSearchByInstrumentResults.document) {
            this.searchResultsFound();
            setTimeout(() => {
              this.mainMapService.renderInstrumentPropertyMarkers(this.omnibarSearchByInstrumentResults);
            }, this._timeout);

            this.setLroPostSearchResults(searchText);

          } else {
            this.noSearchResultsFound();
            this.loggerService.logDebug(`no properties found for instrument/plan number search text ${searchText}`);
          }

          this.logSearchActivity(OmnibarSearchTypeEnum.INSTRUMENT_DISPLAY, 'O', searchText, searchText, this.selectedLro, "omnibar/properties/instrument?value=" + encodeURIComponent(searchText) + "&page=0&pageSize=20&lro=" + this.selectedLro);

          break;

        case OmnibarSearchTypeEnum.LOT_CONCESSION_VALUE:
          this.loggerService.logDebug(`search form ${OmnibarSearchTypeEnum.LOT_CONCESSION_DISPLAY} submitted`);
          this.omnibarSearchByLotConsessionResults = await lastValueFrom(this.omnibarSearchService.searchPropertiesByLotConcession(this.selectedTownshipLot.lot, this.selectedTownshipLot.lotId), { defaultValue: new SearchResult() });
          this.loggerService.logDebug(`omnibar search results for lot concession search text ${this.selectedTownshipLot.lot} ${this.selectedTownshipLot.lotId}`, this.omnibarSearchByLotConsessionResults);

          if (this.omnibarSearchByLotConsessionResults && this.omnibarSearchByLotConsessionResults.itemsTotal > 0) {
            this.searchResultsFound();
            let townshipPolygon = await lastValueFrom(this.omnibarSearchService.getTownshipPolygon(this.selectedTownshipLot.lot, this.selectedTownshipLot.lotId), { defaultValue: {} });
            setTimeout(() => {
              this.mainMapService.renderTownshipLotPolygonAndMarkers(townshipPolygon, this.omnibarSearchByLotConsessionResults);
            }, this._timeout);

          } else {
            this.noSearchResultsFound();
            this.loggerService.logDebug(`no properties found for lot concession search text ${this.selectedTownshipLot.lot} ${this.selectedTownshipLot.lotId}`);
          }
          break;
      }

    } catch (e) {
      this.loggerService.logError('omnibar search failed', e);
    } finally {
      //must give the user a chance to search again after a failure
      this.updateSearchStatus(false);
    }
  }

  noSearchResultsFound = () => {
    this.omnibarStateService.update(OmnibarStateEnum.SEARCH_RESULTS_NONE);
  }

  searchResultsFound = () => {
    this.omnibarStateService.update(OmnibarStateEnum.SEARCH_RESULTS_FOUND);
    this.omnibarNoSearchResultsService.hide();
  }

  private updateSearchStatus(flag: boolean) {
    this.isSearching = flag;
    (this.isSearching) ? this.omnibarStateService.update(OmnibarStateEnum.SEARCH_INITIATED) : this.omnibarStateService.update(OmnibarStateEnum.SEARCH_ENDED);
    (this.isSearching) ? this.searchBusyIndicatorService.showMainMapBusyIndicator() : this.searchBusyIndicatorService.hideMainMapBusyIndicator();
  }

  initializeSearchForm = (searchType: OmnibarSearchTypeEnum) => {
    switch (searchType) {
      case OmnibarSearchTypeEnum.ALL_DISPLAY:
        this.wildCardSearchFormGroup = this.formBuilder.group({
          autoSuggestAll: ['', [Validators.required, this.customValidatorService.allSpacesValidator()]]
        })
        break;

      case OmnibarSearchTypeEnum.OWNER_DISPLAY:
        this.ownerSearchFormGroup = this.formBuilder.group({
          firstName: [''],
          lastName: ['', [Validators.required, Validators.minLength(2), this.customValidatorService.allSpacesValidator()]]
        })

        break;

      case OmnibarSearchTypeEnum.INSTRUMENT_DISPLAY:
        this.instrumentPlanSearchFormGroup = this.formBuilder.group({
          instrumentPlanNumber: ['', [Validators.required, this.customValidatorService.allSpacesValidator()]]
        })
        break;

      case OmnibarSearchTypeEnum.LOT_CONCESSION_DISPLAY:
        this.lotConcessionSearchFormGroup = this.formBuilder.group({
          lotConTownship: ['', Validators.required],
          lotConConcession: ['', Validators.required],
          lotConLot: ['', Validators.required]
        })

        break;
    }
  }

  initializeSearchFormGroups = () => {
    //create the reactive forms
    this.initializeSearchForm(OmnibarSearchTypeEnum.ALL_DISPLAY);
    this.initializeSearchForm(OmnibarSearchTypeEnum.OWNER_DISPLAY);
    this.initializeSearchForm(OmnibarSearchTypeEnum.INSTRUMENT_DISPLAY);
    this.initializeSearchForm(OmnibarSearchTypeEnum.LOT_CONCESSION_DISPLAY);

    //subscribe to control changes
    this.lotConcessionSearchFormGroup.controls['lotConTownship']?.valueChanges
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(newTownship => {
          if (newTownship != null) {
            this.loggerService.logDebug(`changed to township ${newTownship}`);

            this.mainMapService.clearRenderedMarkers();
            this.screenManager.hideScreen(ScreenNameEnum.OMNIBAR_SEARCH_RESULTS);
            this.resetField(this.lotConcessionSearchFormGroup, 'lotConConcession');
            this.getConcession(newTownship);
            this.resetField(this.lotConcessionSearchFormGroup, 'lotConLot');
            this.lots = new Lot();
          }
        })

    this.lotConcessionSearchForm.controls['lotConConcession']?.valueChanges
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(newConcession => {
          if (newConcession) {
            this.loggerService.logDebug(`changed to concession ${newConcession}`);

            this.mainMapService.clearRenderedMarkers();
            this.screenManager.hideScreen(ScreenNameEnum.OMNIBAR_SEARCH_RESULTS);
            this.resetField(this.lotConcessionSearchFormGroup, 'lotConLot');
            this.getLot(newConcession);
          }
        })
  }

  getTownship = async (lro: string) => {
    try {
      this.townships = await lastValueFrom(this.lotConcessionService.getTownship(lro), { defaultValue: {} });
      this.loggerService.logDebug(`townships in lro ${lro} refreshed`);

      if (this.isEmptyLotConcession(this.townships?.result)) {
        this.noLotConcessionMessage();
      }

    } catch (e) {
      this.loggerService.logError(e);
    } finally {
    }
  }

  getConcession = async (township: string) => {
    if (township != null) {
      try {
        this.concessions = await lastValueFrom(this.lotConcessionService.getConcession(this.selectedLro, township), { defaultValue: {} });
        this.loggerService.logDebug('township concessions refreshed');

        if (this.isEmptyLotConcession(this.concessions?.result)) {
          this.noLotConcessionMessage();
        }

      } catch (e) {
        this.loggerService.logError(e);
      } finally {
      }
    }
  }

  getLot = async (concession: string) => {
    if (concession != null) {
      try {
        this.lots = await lastValueFrom(this.lotConcessionService.getLot(this.selectedLro, this.lotConcessionSearchFormGroup.controls['lotConTownship'].value, concession), { defaultValue: {} });
        this.loggerService.logDebug('township lots refreshed');

        if (this.isEmptyLotConcession(this.lots?.result)) {
          this.noLotConcessionMessage();
        }

      } catch (e) {
        this.loggerService.logError(e);
      } finally {
      }
    }
  }

  isEmptyLotConcession = (list: any) => {
    return (_.isEmpty(list) && this.selectedSearchType == OmnibarSearchTypeEnum.LOT_CONCESSION_VALUE);
  }

  isEmptyAnyLotConcession = () => {
    return (this.selectedSearchType == OmnibarSearchTypeEnum.LOT_CONCESSION_VALUE && (this.isEmptyLotConcession(this.townships?.result) || this.isEmptyLotConcession(this.concessions?.result) || this.isEmptyLotConcession(this.lots?.result)));
  }

  noLotConcessionMessage = () => {
    /*
    if (this.noLotConcessionDialogRef !== undefined) return;

    const msg = ['There are no Lots or Concessions in your selected location.'];
    const dialogData = new ConfirmDialogData('DATA NOT FOUND', msg, '', 'Close', false);
    this.noLotConcessionDialogRef = this.dialog.open(ConfirmDialogComponent, {data: dialogData})
      .afterClosed().subscribe(() => {
        this.noLotConcessionDialogRef = undefined;
      });

    */
    this._snackBar.open(DataService.LOTS_OR_CONCESSIONS_NOT_FOUND, 'Close', defaultErrorMatSnackBarConfig);
  }

  get wildCardSearchForm(): FormGroup {
    return this.wildCardSearchForm;
  }

  get ownerSearchForm(): FormGroup {
    return this.ownerSearchForm;
  }

  get instrumentSearchForm(): FormGroup {
    return this.instrumentSearchForm;
  }

  get lotConcessionSearchForm(): FormGroup {
    return this.lotConcessionSearchFormGroup;
  }

  stripIllegalSearchCharacters = (searchString: string): string => {
    if (searchString) {
      searchString = searchString.replace(/[`~!@#$%^*()_|+\=?;:<>\{\}\[\]\\]/gi, '');
      searchString = searchString.replace(/,((?=\S)|(\s{2,}))/g, ", ");
    }

    return searchString;
  }

  resetControls = () => {
    let defaultSearchMethod: MatSelectItem = this.user.defaultSearch;
    switch (defaultSearchMethod.valueOf()) {
      case 'SEARCH_ALL':
        this.selectedSearchType = OmnibarSearchTypeEnum.ALL_VALUE;
        break;

      case 'SEARCH_NAME':
        this.selectedSearchType = OmnibarSearchTypeEnum.OWNER_VALUE;
        break;

      case 'SEARCH_INSTRUMENT_PLAN':
        this.selectedSearchType = OmnibarSearchTypeEnum.INSTRUMENT_VALUE;
        break;

      case 'SEARCH_LOT_CON':
        this.selectedSearchType = OmnibarSearchTypeEnum.LOT_CONCESSION_VALUE;
        break;

      default:
        this.selectedSearchType = OmnibarSearchTypeEnum.ALL_VALUE;
    }

    this.clearFormControlFieldValue('autoSuggestAll');
    this.clearFormControlFieldValue('firstName');
    this.clearFormControlFieldValue('lastName');
    this.clearFormControlFieldValue('instrumentPlanNumber');

    let lastLro = localStorage.getItem(LocalStorageKey.LRO);
    if (lastLro) {
      this.selectedLro = lastLro;
    } else {
      this.selectedLro = this.user.defaultLRO;
    }
    this.lroPolygonsService.setCurrentLro(this.selectedLro);
  }

  clearFormControlFieldValue = (field: string) => {
    //this.loggerService.logDebug(field);
    // we assume here that field names are unique between FormGroups
    this.resetField(this.ownerSearchFormGroup, field);
    this.resetField(this.wildCardSearchFormGroup, field);
    this.resetField(this.instrumentPlanSearchFormGroup, field);

    //force the omnibar to show the recent entries when the user clears the search by all field
    this.currentAutoSuggestMode.set(AutoSuggestSearchMode.SHOW_RECENT_RESULTS_ONLY);
  }

  resetField(formGroup: FormGroup, field: string) {
    if (formGroup?.controls[field]) {
      formGroup?.controls[field].reset('');
    }
  }

  isInputFieldHasValue = (field: HTMLInputElement) => {
    return (field.value?.length > 0);
  }

  getValidationErrorMessage = (sourceForm: FormGroup, sourceFieldName: string = '', sourceLabel: string, minimumCharacters: number): any => {
    const formControl: FormControl = (sourceForm.get(sourceFieldName) as FormControl);
    let errorMessage: string;
    if (sourceFieldName == 'lot') {
      errorMessage = `${sourceLabel} values are required.`;
    } else {
      if (formControl.hasError('required')) {
        errorMessage = `${sourceLabel} value is required.`;
      } else if (formControl.hasError('minlength')) {
        errorMessage = `${sourceLabel} requires a minimum of ${minimumCharacters} characters.`;
      } else if (formControl.hasError('allSpaces')) {
        errorMessage = `${sourceLabel} requires a value.`;
      } else {
        errorMessage = '';
      }
    }
    if (!_.isEmpty(errorMessage)) {
      this._snackBar.open(errorMessage, 'Close', defaultErrorMatSnackBarConfig);
    }

    //since we are using the snackbar and not the mat-error to display the error message, we can ignore the return string.
    return undefined;
  }

  onToggleGroupClick = (group: MatButtonToggleGroup) => {
    let value = group.value;

    if (value == SearchComparablesEnum.TOGGLE_RENEW) {
      this.renewNow();
    } else if (value == SearchComparablesEnum.TOGGLE_SEARCH) {
      this.toggleSearchComparablesForm();
    } else {
      group.value = null; //deselect all toggle buttons

      //always have the renew now button selected
      if (this.renewNowButtonEnabled) {
        this.comparablesSearchService.updateSearchComparablesToggle(SearchComparablesEnum.TOGGLE_RENEW);
      }
    }

    if (!this.searchComparablesToggleGroup) {
      this.searchComparablesToggleGroup = group;
    }
  }

  goToLastSearchResults = () => {
    this.gaService.buttonClicked(GA_Button.SEARCH_COMPARABLES_VIEW_LAST_RESULTS, '');

    if (!this.screenManager.isScreenVisible(ScreenNameEnum.SEARCH_COMPARABLES_RESULTS)) {
      this.router.navigateByUrl(UrlService.PATH_TO_SEARCH_COMPARABLES_RESULTS);
    }
  }

  toggleSearchComparablesForm = () => {
    this.gaService.openPage(GA_Page.SEARCH_COMPS, GA_Page.SEARCH_COMPS_LABEL);
    this.screenManager.isScreenVisible(ScreenNameEnum.SEARCH_COMPARABLES_FORM)? this.closeSearchComparablesForm() : this.openSearchComparablesForm();
  }

  openSearchComparablesForm = () => {
    this.screenManager.showScreen(ScreenNameEnum.SEARCH_COMPARABLES_FORM);
    this.searchComparablesFormOpened.emit();
    this.moveMapControls.emit(true);
  }

  closeSearchComparablesForm = () => {
    this.screenManager.hideScreen(ScreenNameEnum.SEARCH_COMPARABLES_FORM);
    this.moveMapControls.emit(false);
  }

  switchLro = (lroState: LroState) => {
    if (lroState.lroId) {
      this.selectedLro = lroState.lroId;
      this.loggerService.logDebug(`switching to lro ${this.selectedLro}`);
    } else {
      this.selectedLro = DataService.DEFAULT_LRO;
      this.loggerService.logDebug(`lro not specified, switching to default lro ${this.selectedLro}`);
    }

    this.mainMapService.renderLroPolygon(this.selectedLro, lroState.focus);
    this.lotConcessionSearchFormGroup.reset();
    this.getTownship(this.selectedLro);
  }

  private setLroPostSearchResults = (logIdentifier: string) => {
    let lroId: string | undefined = this.lroPolygonsService.getLroIdBySpatialPoint(this.mainMapService.getMapCenter());
    if (lroId !== undefined) {
      this.loggerService.logDebug(`${logIdentifier} is within lro ${lroId}`);
      this.selectedLro = lroId;
      this.lroPolygonsService.setCurrentLro(this.selectedLro);
    } else {
      this.loggerService.logWarning(`cannot determine in which lro ${logIdentifier} is located`);
    }
  }

  getSearchTypeTooltip = (text?: string) => {
    if (text) {
      this.searchTypeToolTip = "Search by " + text;
    } else {
      switch (this.selectedSearchType) {
        case OmnibarSearchTypeEnum.ALL_VALUE:
          this.searchTypeToolTip = "Search by " + OmnibarSearchTypeEnum.ALL_DISPLAY;
          break;

        case OmnibarSearchTypeEnum.OWNER_VALUE:
          this.searchTypeToolTip = "Search by " + OmnibarSearchTypeEnum.OWNER_DISPLAY;
          break;

        case OmnibarSearchTypeEnum.INSTRUMENT_VALUE:
          this.searchTypeToolTip = "Search by " + OmnibarSearchTypeEnum.INSTRUMENT_DISPLAY;
          break;

        case OmnibarSearchTypeEnum.LOT_CONCESSION_VALUE:
          this.searchTypeToolTip = "Search by " + OmnibarSearchTypeEnum.LOT_CONCESSION_DISPLAY;
          break;
      }
    }

    this.searchTypePopover.open();
  }

  isSearchResultsVisible = () => {
    return this.screenManager.isScreenVisible(ScreenNameEnum.SEARCH_COMPARABLES_RESULTS);
  }

  isLastSearchResultsExist = () => {
    return this.searchComparablesResultService.isLastSearchResultsExist();
  }

  closeOtherScreens = () => {
    this.mainMapService.closeAllMarkerBubbles();
    this.screenManager.closeScreensWhenThisOpened(ScreenNameEnum.OMNIBAR_SEARCH);
    this.hoodQMapClosedSignal.emit(true);
  }

  ngOnChanges(changes: SimpleChanges): void {
    let formVisible: boolean = changes['searchComparablesFormVisible']?.currentValue;
    this.selectedToggle = formVisible ? 'search' : '';
  }

  ngOnInit(): void {
    this.userAccessControls = this.userService.getUserAccessControl();
    this.user = this.userService.user;

    this.searchTypes = [
      { value: OmnibarSearchTypeEnum.ALL_VALUE, display: OmnibarSearchTypeEnum.ALL_DISPLAY },
      { value: OmnibarSearchTypeEnum.OWNER_VALUE, display: OmnibarSearchTypeEnum.OWNER_DISPLAY },
      { value: OmnibarSearchTypeEnum.INSTRUMENT_VALUE, display: OmnibarSearchTypeEnum.INSTRUMENT_DISPLAY },
      { value: OmnibarSearchTypeEnum.LOT_CONCESSION_VALUE, display: OmnibarSearchTypeEnum.LOT_CONCESSION_DISPLAY },
    ];

    this.currentAutoSuggestMode.set(AutoSuggestSearchMode.SHOW_RECENT_RESULTS_ONLY);
    this.lros = this.mainMapService.getLroPolygonService().getLroKeyValuePairs();

    this.initializeSearchFormGroups();

    this.resetControls();

    this.lroPolygonsService.lroState$
        .pipe(skip(1), takeUntil(this.ngUnsubscribe))
        .subscribe(lroState => {
          this.switchLro(lroState);
        });

    this.screenManager.getObservableScreen(ScreenNameEnum.PROPERTY_REPORT)!
        .pipe(skip(1), takeUntil(this.ngUnsubscribe))
        .subscribe(visible => {
          if (visible && this.autoComplete) {
            this.autoComplete.closePanel();
          }
        })

    this.timerService.sessionTimedOutNotifier$
        .pipe(skip(1),takeUntil(this.ngUnsubscribe))
        .subscribe(flag => {
          this.sessionTimedOut.set(flag);
        });

    this.omnibarSearchService.wildCardSearch$
        .pipe(skip(1), takeUntil(this.ngUnsubscribe))
        .subscribe(searchString => {
          if (!_.isEmpty(searchString)) {
            this.wildCardSearchFormGroup.get('autoSuggestAll')?.setValue(searchString);
            setTimeout(() => {
              this.onFormSubmitted();
            }, 100);
          }
        });

    this.omnibarStateService.omnibarSearchComparablesRequest$
        .pipe(skip(1), takeUntil(this.ngUnsubscribe))
        .subscribe((request) => {
          if (request) {
            this.toggleSearchComparablesForm();
          }
        });

    this.comparablesSearchService.searchComparablesToggle$
        .pipe(skip(1), takeUntil(this.ngUnsubscribe))
        .subscribe((value) => {
          if (value != null) {
            if (value == SearchComparablesEnum.TOGGLE_RENEW && this.renewNowButtonEnabled) {
              this.selectedToggle = value;
            } else if (value == SearchComparablesEnum.TOGGLE_SEARCH) {
              this.selectedToggle = value;
            } else {
              this.selectedToggle = SearchComparablesEnum.TOGGLE_NONE;
              if (this.searchComparablesToggleGroup) {
                this.searchComparablesToggleGroup.value = null;
              }
            }
          }
        });
  }

  async ngAfterViewInit() {
    this.lroPolygonsService.ssoLroState$
        .pipe(skip(1), takeUntil(this.ngUnsubscribe))
        .subscribe(ssoLroState => {
          if (ssoLroState && ssoLroState.lroId) {
            this.loggerService.logDebug(`sso request switching lro to ${ssoLroState.lroId!}`);
            this.selectedLro = ssoLroState.lroId!;
            this.lroPolygonsService.setCurrentLroState(ssoLroState);
            this.lroPolygonsService.setSSOLroState(new LroState(null, false));  //reset
          }
        });

    await this.comparablesSearchService.initCounters(false);
    this.comparableSearchCounter = this.comparablesSearchService.comparableSearchCounter;
  }

  //handle search keyboard shortcut
  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    switch (event.key) {
      case "s":
      case "S":
        event.stopPropagation();

        if (this.selectedSearchType == OmnibarSearchTypeEnum.ALL_VALUE && this.autoSuggestAllRef && this.autoSuggestAllRef.nativeElement != document.activeElement && !this.dialog.openDialogs.length) {
          setTimeout(() => {
            this.autoSuggestAllRef.nativeElement.value = '';
            this.autoSuggestAllRef.nativeElement.focus();
            this.gaService.featureClicked(GA_Feature.OMNIBAR_SEARCH_SHORTCUT_KEY, '', '');
          }, 100);
        };
        break;
    }
  }

  async reloadGema3G() {

    this.gaService?.buttonClicked(GA_Button.HOME_BUTTON, GA_Button.HOME_BUTTON_LABEL);

    this.screenManager.closeAllScreens();

    await this.router.navigate(['/home'])
      .then(() => {
        //focus the user's default lro on the map by re-drawing it
        this.selectedLro = this.user.defaultLRO;
        this.lroPolygonsService.setCurrentLro(this.selectedLro);
        this.mainMapService.renderLroPolygon(this.selectedLro, true);

        this.updateUI();
      });
  }

  goBack = () => {
    if (document.referrer && document.referrer.toLowerCase().includes(environment.url.E_STORE_DOCUMENT_REFERRER.toLowerCase())) {
      //go back to 3g and not to estore since estore has its own 2g page flow within the iframe
      this.router.navigate(['/home']);
    } else {
      history.back();
    }
  }

  async renewNow() {
    if(this.user.isMultiLicense){
      const content = [DataService.LICENSE_SUBSCRIPTION_RENEWAL_CONTENT_ML];
      const dialogData = new WarningDialogData(DataService.LICENSE_SUBSCRIPTION_RENEWAL_HEADER, content, '', 'Remind Me Later', 'Renew Now', false);
      this.warningService.showWarning(dialogData, false, 560);
    } else {
      await this.urlService.openEstoreCatalogueWithCategory(EstoreProductCategoryEnum.GWH_RENEW_SUBSCRIPTION);
    }
  }
  openCameraDialog(): void {
    this.screenManager.closeAllScreens();
    const hasShownDialog = sessionStorage.getItem('hasShownCameraAccessDialog');
    if (!hasShownDialog) {
      const dialogRef = this.dialog.open(CameraAccessDialogComponent);

      dialogRef.afterClosed().subscribe(result => {
        if (result === true) {
          sessionStorage.setItem('hasShownCameraAccessDialog', 'true');
          this.openCamera();  // Open the camera if "Yes" is selected
        } else {
          console.log('Camera access denied');
        }
      });
    } else {
      this.openCamera(); // If dialog has been shown already, proceed directly to camera initiation
    }

  }
  openCamera = (): void => {
    // Reset latitude, longitude and heading.
    this.latitude = 0;
    this.longitude = 0;
    this.heading = null;

    //To Reset geoWatchId
    if (this.geoWatchId !== undefined) {
      navigator.geolocation.clearWatch(this.geoWatchId);
      this.geoWatchId = undefined;
    }

    if (navigator.geolocation) {
      //watchPosition is better suited for mobile and tablet devices to get continuous location updates.
      this.geoWatchId = navigator.geolocation.watchPosition(
          (position) => {
            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;

            // Update latitude and longitude
            if (latitude !== this.latitude || longitude !== this.longitude) {
              this.latitude = latitude;
              this.longitude = longitude;
              console.log('Updated Latitude:', this.latitude);
              console.log('Updated Longitude:', this.longitude);

              // Recalculate heading or other operations
              if (this.browserDetectorService.getBrowserName().toLowerCase().includes('edge') || !window.DeviceOrientationEvent) {
                this.getStreetViewHeading(this.latitude, this.longitude);
              } else {
                this.requestDeviceOrientationPermission();
              }
              // Open the camera
              this.openCameraAndFetchData();
            }
          },
          (error) => {
            console.error('Error getting location:', error);
          }, {enableHighAccuracy: true,timeout: 5000, maximumAge: 0}
      );
    } else {
      console.error('Geolocation is not supported by your browser');
    }
  }

  closeCamera(): void {
    if (this.videoElement && this.mediaStream) {
      // Stop all tracks of the media stream
      this.mediaStream.getTracks().forEach(track => track.stop());

      // Remove the video element from the DOM
      document.body.removeChild(this.videoElement);
      this.videoElement = undefined;
      this.mediaStream = undefined;
    }
    // Remove watchId to reset the geolocation watch
    if (this.geoWatchId !== undefined) {
      navigator.geolocation.clearWatch(this.geoWatchId);
      this.geoWatchId = undefined;
    }
  }

  openCameraAndFetchData: () => Promise<void>;
  private initAccessCamera() {
    this.openCameraAndFetchData = async () => {
      if (this.isProcessing) {
        return;  // Prevent geolocation or heading changes during data fetch
      }
      const devices = await navigator.mediaDevices.enumerateDevices();
      let videoDevices = devices.filter(device => device.kind === 'videoinput');
      let rearCameraDevice = videoDevices.find(device => device.label.toLowerCase().includes('back'));
      const constraints = {
        video: rearCameraDevice ? { deviceId: rearCameraDevice.deviceId } : { facingMode: 'environment' }
      };
      // Access the camera
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      const videoElement = document.createElement('video');
      videoElement.srcObject = stream;
      videoElement.autoplay = true;
      videoElement.controls = false;
      videoElement.style.position = 'absolute';
      videoElement.style.top = '0';
      videoElement.style.left = '0';
      videoElement.style.width = '100vw';
      videoElement.style.height = '100vh';
      videoElement.style.zIndex = '9999';

      document.body.appendChild(videoElement);
      this.isProcessing = true;

      const loaderElement = document.createElement('div');
      loaderElement.id = 'camera-loader';
      loaderElement.innerHTML = `
    <div class="spinner">
      <img id="pref-img-spinner" alt="Loading..." src="assets/img/icon-refresh.gif"/>
    </div>
  `;
      loaderElement.style.position = 'absolute';
      loaderElement.style.top = '0';
      loaderElement.style.left = '0';
      loaderElement.style.width = '100vw';
      loaderElement.style.height = '100vh';
      loaderElement.style.zIndex = '10000'; // Ensure it's above the camera view
      loaderElement.style.display = 'flex';
      loaderElement.style.alignItems = 'center';
      loaderElement.style.justifyContent = 'center';
      loaderElement.style.backgroundColor = 'rgba(255, 255, 255, 0.5)';


      // Save the stream and video element references so they can be closed later
      this.videoElement = videoElement;
      this.mediaStream = stream;
      this.locationService.getPosition();
      // async openCameraAndFetchData(lat: number, lon: number) {
      try {
        setTimeout(async () => {
          // Fetch the address from the Street View API
          let headingValue = null;
          let calculatedPov = new PointOfView();
          if(this.heading == null || this.heading == undefined) {
            this.getStreetViewHeading(this.latitude, this.longitude);
          }
          headingValue = this.heading;
          if(headingValue != null) {
            calculatedPov.heading = this.heading;
            calculatedPov.latitude = this.latitude;
            calculatedPov.longitude = this.longitude;
            this.addressQueryResult = await lastValueFrom(
                this.streetViewService.getAddressByStreetViewPOV(calculatedPov),
                {defaultValue: {}}
            );
            this.address = this.addressQueryResult?.address?.message;
            this.pin = this.addressQueryResult?.address?.pin;
          }
          const pin = this.pin;

          if (pin === null || pin === undefined || headingValue === null) {
            this.openSnackBarError('No data available, please try again.');
            this.isProcessing = false;
            this.closeCamera();
            return;
          }
          const condoBlock = pin.substring(0, 5); // Get the first 5 digits from the PIN
          this.isLoading = true;
          document.body.appendChild(loaderElement);

          // To check if the pin is condo or freehold
          const condoSummary = new CondoSummary(condoBlock, this.address );
          const condoLevel =  await lastValueFrom(this.omnibarSearchService.getCondoLevelsCounterByBlock(condoSummary))
          this.isLoading = false;
          this.isProcessing = false;
          document.body.removeChild(loaderElement);
          //To get lro
          let mapCenter: google.maps.LatLng = this.mainMapService.getMapCenter();
          let currentLro: string | null = null;
          if (mapCenter) {
            let mapCenterLroId: string | undefined = this.lroPolygonsService.getLroIdBySpatialPoint(mapCenter);
            if (mapCenterLroId !== undefined) {
              currentLro = this.lroPolygonsService.getCurrentLro();
              this.loggerService.logDebug(`center of map is within lro ${mapCenterLroId}, current lro is ${currentLro}`);
            }
          }
          let resultsFound: boolean = false;
          this.screenManager.showScreen(ScreenNameEnum.OMNIBAR_SEARCH_RESULTS);
          if (Array.isArray(condoLevel) && condoLevel.length !== 0) {
            this.closeCamera();
            const url = `omnibar/properties?searchText=${condoBlock}&lro=${currentLro}&pageSize=20&page=0`;
            this.condoSearchResults = await lastValueFrom(this.omnibarSearchService.searchPropertiesByAutoSuggest(AutoSuggestSearchMode.GET_RECENTLY_SEARCHED_SUGGESTED_RESULT, url, currentLro ?? '' ), {defaultValue: {}});
            resultsFound = this.isOmnibarSearchResultsFound(this.condoSearchResults);
          }
          else {
            this.closeCamera();
            const url = `omnibar/properties?searchText=${pin}&lro=${currentLro}&pageSize=20&page=0`;
            this.freeholdSearchResults = await lastValueFrom(this.omnibarSearchService.searchPropertiesByAutoSuggest(AutoSuggestSearchMode.GET_RECENTLY_SEARCHED_SUGGESTED_RESULT, url, currentLro ?? '' ), {defaultValue: {}});
            resultsFound = this.isOmnibarSearchResultsFound(this.freeholdSearchResults);
          }
          if (resultsFound) {
            this.searchResultsFound();
          }
        }, 200);

      } catch (error) {
        console.error('Error fetching data:', error);
        this.isLoading = false;
      }
    }
  }
  accessCamera: () => Promise<void>;
  getStreetViewHeading(latitude: number, longitude: number) {
    const streetViewService = new google.maps.StreetViewService();
    const location = {lat: latitude, lng: longitude};
    try {
      streetViewService.getPanorama({location, radius: 50}, (data, status) => {
        if (status === google.maps.StreetViewStatus.OK && data && data.links && data.links.length > 0) {
          // Extract the heading from the first link in the returned data
          const heading = data.links[0].heading;
          this.heading = heading;
          console.log('Heading from Google Maps:', heading);
        } else {
          console.error('Street View data is not available or status is not OK:', status);
          this.heading = null;  // Optionally, set heading to undefined when not available
        }
      });
    } catch (e) {
      this.loggerService.logError("Error in getting Heading", e)
    }
  }
  // Below code do not support Desktop browser testing
  initDeviceOrientation() {
    if (window.DeviceOrientationEvent) {
      window.addEventListener('deviceorientation', (event: DeviceOrientationEvent) => {
        if (event.alpha !== null) {
          // alpha provides the compass direction in degrees (0 to 360)
          this.heading = event.alpha;
          console.log('Heading from Device Orientation:', this.heading);
        }
      });
    } else {
      console.warn('Device Orientation is not supported on this device.');
    }
  }

  //To support in safari browser.
  private requestDeviceOrientationPermission() {
    if (typeof (DeviceOrientationEvent as any).requestPermission === 'function') {
      (DeviceOrientationEvent as any).requestPermission()
          .then((permissionState: string) => {
            if (permissionState === 'granted') {
              this.initDeviceOrientation();
            } else {
              console.warn('Device orientation permission denied');
            }
          })
          .catch(console.error);
    } else {
      this.initDeviceOrientation();
    }
  }
}

import { inject, Injectable } from "@angular/core";
import { EstoreProductDetailTypeEnum } from "src/app/core/enum/estore-product-detail-type-enum";
import { UserService } from "./user.service";
import { SSOOpenIDConnectJson } from "../../core/model/sso/sso-oidc-json";
import { BehaviorSubject, catchError, concat, concatMap, delay, lastValueFrom, map, Observable, of, retry, skip, tap, throwError } from "rxjs";
import { SSOService } from "./sso.service";
import { HttpClient } from "@angular/common/http";
import { LandingPageSettings } from "../../core/model/store-front/landing-page/landing-page-settings";
import { EStoreProductPage } from "../../core/model/product/e-store/e-store-product-page";
import { baseUrl } from "./system";
import { EStoreSearchUrl } from "../../core/model/product/e-store/e-store-search-url";
import { EStorePlToken } from "../../core/model/product/e-store/e-store-pl-token";
import * as _ from 'lodash';
import { ProductCarousel } from "../../core/model/estore/product-carousel";
import { EStoreWindowInfo } from "../../core/model/product/e-store/e-store-window-info";
import { v4 as uuidv4 } from 'uuid';
import { EStoreParam } from "../../core/model/product/e-store/e-store-param";
import { LoggerService } from "./log/logger.service";
import { EstoreProductTypeEnum } from "../../core/enum/estore-product-type.enum";
import { EstoreDisplayModeEnum } from "../../core/enum/estore-display-mode.enum";
import { EstoreProductCategoryEnum } from "../../core/enum/estore-product-category-enum";
import { RouteMappingUtility } from "../utility/route-mapping-utility";
import { Router } from "@angular/router";
import { EStoreProduct } from "../../core/model/product/e-store/e-store-product";
import { EstoreBrowserTabAttribute } from "../../core/model/estore/estore-browser-tab-attribute";
import { ProductCategoryItem } from "../../core/model/store-front/product-category/product-category-item";
import { CartCounter } from "../../core/model/estore/cart-counter";
import { ApplicationFeatureFlagService } from "./feature/app-feature-flag.service";
import { FeatureFlagEnum } from "src/app/core/enum/feature-flag.enum";

@Injectable({
  providedIn: 'root'
})
export class EstoreService {

  constructor() {
  }

  private userService = inject(UserService);
  private ssoService = inject(SSOService);
  private router = inject(Router);
  private loggerService = inject(LoggerService);
  private https = inject(HttpClient);
  private featureFlagService = inject(ApplicationFeatureFlagService)

  private estoreUrl: string;

  private cartCountUrl = window.location.origin + '/gema-rest/rest/estore/cart/counter';
  api$ = this.https.get(this.cartCountUrl);
  load$ = new BehaviorSubject('');
  polledApi$: Observable<number>;

  restart$ = of('')
    .pipe(
      delay(5000), // wait for 5sec
      tap(_ => this.load$.next('')), // calls load$
      skip(1),
    );

  private _estoreUrl = new BehaviorSubject<string | null>(null);
  private estoreUrl$ = this._estoreUrl.asObservable();

  private _catalogueProductListAnchor = new BehaviorSubject<boolean>(false);
  private catalogueProductListAnchor$ = this._catalogueProductListAnchor.asObservable();

  private _propertyInsightsReportRunner = new BehaviorSubject<number>(-1);
  private propertyInsightsReportRunner$ = this._propertyInsightsReportRunner.asObservable();

  private _residentialRelocationReportTimeFramePrompter = new BehaviorSubject<number>(-1);
  private residentialRelocationReportTimeFramePrompter$ = this._residentialRelocationReportTimeFramePrompter.asObservable();

  getEstoreUrlObservable = (): Observable<string | null> => {
    return this.estoreUrl$;
  }

  setEstoreUrl = (url: string) => {
    this._estoreUrl.next(url);
  }

  getCatalogueProductListAnchorObservable = (): Observable<boolean> => {
    return this.catalogueProductListAnchor$;
  }

  scrollToCatalogueProductListAnchor = (scroll: boolean) => {
    this._catalogueProductListAnchor.next(scroll);
  }

  getPropertyInsightsReportRunnerObservable = (): Observable<number> => {
    return this.propertyInsightsReportRunner$;
  }

  startPropertyInsightsReportRunner = (productId: number) => {
    this._propertyInsightsReportRunner.next(productId);
  }

  stopPropertyInsightsReportRunner = () => {
    this._propertyInsightsReportRunner.next(-1);
  }

  getResidentialRelocationReportTimeFramePrompterObservable = () => {
    return this.residentialRelocationReportTimeFramePrompter$;
  }

  triggerResidentialRelocationReportTimeFramePrompt = (productId: number) => {
    this._residentialRelocationReportTimeFramePrompter.next(productId);
  }

  getCartCount() {
    this.loggerService.logDebug('cart polling started');
    this.polledApi$ = this.load$.pipe(
      concatMap(_ => concat(this.api$, this.restart$)), // restart only when api returns a response
      map((response: any) => {
        return response.counter;
      }),
      retry(3) // retry 3 times in case of error
    );
  }

  getCartCountUrl() {
    return this.cartCountUrl;
  }

  //TODO implement this function from the gema2g getStoreInfo() function in storeservice.js
  getProductDetailUrl = (estoreProductDetailType: any, token: any, pin?: any, arn?: any, docNo?: any, lro?: any, tokenString?: any, landRegistryStatus?: any, productId?: any, uuid?: any, reportReference?: any, pybToken?: any): string => {

    switch (estoreProductDetailType) {
      case EstoreProductDetailTypeEnum.SHOPPING_CART:
        this.estoreUrl = "estore/ProductDetailFrame.jsp?defaultTitle=&nextUrl=/gwhweb/viewCart.do";
        break;
    }

    return this.estoreUrl;
  }


  private getStoreInfo(storeParam: EStoreParam): EStoreWindowInfo {
    const userAccessControls = this.userService.getUserAccessControl();
    const eStoreWindowInfo = new EStoreWindowInfo();
    const storeWindowSizeCatalog = 'width=1400,height=800';
    storeParam.tokenStr = escape(storeParam.tokenStr);

    var storeUrl = "";
    var mpacRequest = false;
    var pybRequest = false;
    var condoRequest = false;
    var transactional = true;
    let residentialRelocationRequest = false;
    let planImageRequest = false;

    if (storeParam.arn && Array.isArray(storeParam.arn)) {
      // this.loggerService.logDebug("ARN IS AN ARRAY");
      storeParam.arn = storeParam.arn[0].arn;
    }

    switch (storeParam.storeProductType) {
      case EstoreProductTypeEnum.NON_TRANSACTIONAL_MPAC_REPORT:
        storeUrl = "/getMpacRptNonTxnlBundle.do?productId=" + storeParam.prodId + "&assessmentRollNumber=" + storeParam.arn;
        transactional = false;
        mpacRequest = true;
        break;

      case EstoreProductTypeEnum.SHOPPING_CART:
        storeUrl = "/estore/ProductDetailFrame.jsp?defaultTitle=&nextUrl=/gwhweb/viewCart.do";
        break;

      case EstoreProductTypeEnum.LICENSE_SUBSCRIPTION_RENEWAL:
        storeUrl = `/estore/ProductDetailFrame.jsp?defaultTitle=&nextUrl=/gwhweb/purchaseLicenseRenewal.do%3FproductId%3D160%26lpId%3D${storeParam.licensePackageId}`;
        break;

      case EstoreProductTypeEnum.COMP_LICENSE_SUBSCRIPTION_RENEWAL:
        storeUrl = "/estore/ProductDetailFrame.jsp?defaultTitle=&nextUrl=/gwhweb/purchaseCompanyLicenseRenewal.do%3FproductId%3D164%26baseLicensePriceProductId%3D160%26defaultLicenseCount%3D" + userAccessControls.companyLicenseRenewalLicenseCount;
        break;

      case EstoreProductTypeEnum.GEOWAREHOUSE_REPORT_PACKAGE:
        storeUrl = "/estore/ProductDetailFrame.jsp?defaultTitle=&nextUrl=/gwhweb//purchaseReportCounter.do%3FproductId%3D950%26transactional%3Dtrue";
        break;

      case EstoreProductTypeEnum.PARCEL_REGISTER:
        var pinStatus = "ACTIVE";
        if (storeParam.landRegistryStatus == null || (storeParam.landRegistryStatus.toUpperCase() != "ACTIVE" && storeParam.landRegistryStatus.toUpperCase() != "A")) {
          pinStatus = "INACTIVE";
        }

        transactional = true;
        if (pinStatus == "ACTIVE" && userAccessControls.parcelRegisterActive) {
          transactional = userAccessControls.parcelRegisterActiveTxnl;
        } else if (pinStatus == "INACTIVE" && userAccessControls.parcelRegisterInActive) {
          transactional = userAccessControls.parcelRegisterInActiveTxnl;
        }

        var includeDeletedInstruments = false;

        var firstRequest = "true";

        storeUrl = "/estore/ProductDetailFrame.jsp?defaultTitle=&nextUrl=/gwhweb/parcelRegister.do%3Fpin%3D" + storeParam.pin + "%26transactional%3D" + transactional + "%26includeDeletedInstruments%3D" + includeDeletedInstruments + "%26pinStatus%3D" + pinStatus + "%26firstRequest%3D" + firstRequest;
        break;

      case EstoreProductTypeEnum.PARCEL_REGISTER_TITLE_CHECK:
        var pinStatus = "ACTIVE";
        if (storeParam.landRegistryStatus == null || (storeParam.landRegistryStatus.toUpperCase() != "ACTIVE" && storeParam.landRegistryStatus.toUpperCase() != "A")) {
          pinStatus = "INACTIVE";
        }

        transactional = false;
        if (userAccessControls.parcelRegisterActive && userAccessControls.parcelRegisterActiveTxnl == true &&
          userAccessControls.instrumentImage == true && userAccessControls.instrumentImageTxnl == true &&
          userAccessControls.parcelRegisterWithTitleCheck == true) {
          transactional = userAccessControls.parcelRegisterActiveTxnl;
        }

        var includeDeletedInstruments = false;

        var firstRequest = "true";

        const includeTitleCheck = String(!!userAccessControls.parcelRegisterWithTitleCheck);

        storeUrl = "/estore/ProductDetailFrame.jsp?defaultTitle=&nextUrl=/gwhweb/parcelRegister.do%3Fpin%3D" + storeParam.pin +
          "%26transactional%3D" + transactional + "%26includeDeletedInstruments%3D" + includeDeletedInstruments +
          "%26pinStatus%3D" + pinStatus + "%26firstRequest%3D" + firstRequest + "%26includeTitleCheck%3D" + includeTitleCheck;
        break;

      case EstoreProductTypeEnum.HOME_VERIFIED_REPORT_SUBSCRIPTION:
        storeUrl = "/estore/ProductDetailFrame.jsp?defaultTitle=&nextUrl=/gwhweb/homeVerifiedReportSubscription.do%3FproductId%3D641";
        break;

      case EstoreProductTypeEnum.HOME_VERIFIED_REPORT:
        storeUrl = "/estore/ProductDetailFrame.jsp?defaultTitle=&nextUrl=/gwhweb/homeVerifiedReportInput.do%3Fpin%3D" + storeParam.pin;
        break;

      case EstoreProductTypeEnum.ASSESSMENT_REPORTS:
        storeUrl = "/storeCatalogue.do?pin=" + storeParam.pin + "&arn=" + storeParam.arn + "&arnResponse=one&fromMPAC=true#MPAC_REPORT";
        eStoreWindowInfo.size = storeWindowSizeCatalog;
        break;

      /* PLAN/INSTRUMENT IMAGE */
      case EstoreProductTypeEnum.PLAN_IMAGE:
        planImageRequest = true;
        storeUrl = "/estore/ViewProductFrame.jsp?defaultTitle=&nextUrl=/gwhweb/documentImage.do%3Faction%3DdisplayDocumentDetails%26docNumber%3D" + storeParam.docNo + "%26lro%3D" + storeParam.lro;
        break;

      case EstoreProductTypeEnum.INSTRUMENT_IMAGE:
        storeUrl = "/estore/ProductDetailFrame.jsp?defaultTitle=&nextUrl=/gwhweb/documentImage.do%3Faction%3DpurchaseDocumentPlist%26token%3D" + storeParam.tokenStr + "%26product%3DplanList";
        break;

      case EstoreProductTypeEnum.INSTRUMENT_IMAGE_DOCUMENT:

        storeUrl = "/estore/ViewProductFrame.jsp?defaultTitle=&nextUrl=/gwhweb/documentImage.do%3Faction%3DdisplayDocumentDetails%26token%3D" + storeParam.tokenStr + "%26docNumber%3D" + storeParam.docNo + "%26lro%3D" + storeParam.lro;
        break;

      case EstoreProductTypeEnum.PROPERTY_SURVEY_IMAGE_JDB:
        storeUrl = "/estore/ProductDetailFrame.jsp?defaultTitle=&nextUrl=/gwhweb/documentImage.do%3Faction%3DgettingSurveyDocument%26token%3D" + storeParam.tokenStr + "%26product%3DsomeProduct";
        break;

      case EstoreProductTypeEnum.PROPERTY_SURVEY_IMAGE_RPE:
        storeUrl = "/estore/ProductDetailFrame.jsp?defaultTitle=&nextUrl=/gwhweb/documentImage.do%3Faction%3DgettingSurveyDocument%26token%3D" + storeParam.tokenStr + "%26product%3DsomeProduct";
        break;

      case EstoreProductTypeEnum.PROPERTY_SURVEY_IMAGE_LSR:
        storeUrl = "/estore/ProductDetailFrame.jsp?defaultTitle=&nextUrl=/gwhweb/documentImage.do%3Faction%3DgettingSurveyDocument%26token%3D" + storeParam.tokenStr + "%26product%3DsomeProduct";
        break;

      case EstoreProductTypeEnum.PROPERTY_SURVEY_IMAGE_SRI:
        // FIXME: this is popup email message (refer: gwutil.js/sendSurveyRequest
        // PlanListByPin.jsp -- not sure this jsp.. need to verify
        storeUrl = "/estore/ProductDetailFrame.jsp?defaultTitle=&nextUrl=/gwhweb/documentImage.do%3Faction%3DgettingSurveyDocument%26token%3D" + storeParam.tokenStr + "%26product%3DsomeProduct";
        break;

      /* Street scape will be implemented in future */
      case EstoreProductTypeEnum.STREETSCAPE_IMAGERY_LICENSE:
        break;

      /* Street scape and Pivot view will be implemented in future */
      case EstoreProductTypeEnum.STREETSCAPEIMAGERY_AND_PIVOTVW_LICENSE:
        break;

      /* MPAC reports */
      case EstoreProductTypeEnum.MPAC_RESIDENTIAL_DETAILED_REPORT_LEVEL_1:
        if (userAccessControls.residentialDtldRptL1Txnl) {
          storeUrl = "/estore/ProductDetailFrame.jsp?defaultTitle=&nextUrl=/gwhweb/purchaseAssessmentReport.do%3FproductId%3D567%26assessmentRollNumber%3D" + storeParam.arn;
        } else {
          storeUrl = "/estore/ProductDetailFrame.jsp?defaultTitle=&nextUrl=/gwhweb/viewAssessmentReport.do%3FproductId%3D567%26assessmentRollNumber%3D" + storeParam.arn;
        }

        mpacRequest = true;
        break;

      case EstoreProductTypeEnum.MPAC_RESIDENTIAL_DETAILED_REPORT_LEVEL_2:
        if (userAccessControls.residentialDtldRptL2Txnl) {
          storeUrl = "/estore/ProductDetailFrame.jsp?defaultTitle=&nextUrl=/gwhweb/purchaseAssessmentReport.do%3FproductId%3D557%26assessmentRollNumber%3D" + storeParam.arn;
        } else {
          storeUrl = "/estore/ProductDetailFrame.jsp?defaultTitle=&nextUrl=/gwhweb/viewAssessmentReport.do%3FproductId%3D557%26assessmentRollNumber%3D" + storeParam.arn;
        }

        mpacRequest = true;
        break;

      case EstoreProductTypeEnum.MPAC_AVM_BASIC_RESIDENTIAL_REPORT:
        if (userAccessControls.AVMBasicResidentialRptTxnl) {
          storeUrl = "/estore/ProductDetailFrame.jsp?defaultTitle=&nextUrl=/gwhweb/purchaseAssessmentReport.do%3FproductId%3D558%26assessmentRollNumber%3D" + storeParam.arn;
        } else {
          storeUrl = "/estore/ProductDetailFrame.jsp?defaultTitle=&nextUrl=/gwhweb/viewAssessmentReport.do%3FproductId%3D558%26assessmentRollNumber%3D" + storeParam.arn;
        }
        mpacRequest = true;
        break;

      case EstoreProductTypeEnum.MPAC_AVM_ENHANCED_RESIDENTIAL_REPORT:
        if (userAccessControls.AVMEnhancedResidentialRptTxnl) {
          storeUrl = "/estore/ProductDetailFrame.jsp?defaultTitle=&nextUrl=/gwhweb/purchaseAssessmentReport.do%3FproductId%3D559%26assessmentRollNumber%3D" + storeParam.arn;
        } else {
          storeUrl = "/estore/ProductDetailFrame.jsp?defaultTitle=&nextUrl=/gwhweb/viewAssessmentReport.do%3FproductId%3D559%26assessmentRollNumber%3D" + storeParam.arn;
        }
        mpacRequest = true;
        break;

      case EstoreProductTypeEnum.MPAC_INDUSTRIAL_DETAILED_REPORT:
        if (userAccessControls.indstrlDetailRptTxnl) {
          storeUrl = "/estore/ProductDetailFrame.jsp?defaultTitle=&nextUrl=/gwhweb/purchaseAssessmentReport.do%3FproductId%3D560%26assessmentRollNumber%3D" + storeParam.arn;
        } else {
          storeUrl = "/estore/ProductDetailFrame.jsp?defaultTitle=&nextUrl=/gwhweb/viewAssessmentReport.do%3FproductId%3D560%26assessmentRollNumber%3D" + storeParam.arn;
        }
        mpacRequest = true;
        break;

      case EstoreProductTypeEnum.MPAC_COMMERCIAL_DETAILED_REPORT:
        if (userAccessControls.commercialDetailRptTxnl) {
          storeUrl = "/estore/ProductDetailFrame.jsp?defaultTitle=&nextUrl=/gwhweb/purchaseAssessmentReport.do%3FproductId%3D561%26assessmentRollNumber%3D" + storeParam.arn;
        } else {
          storeUrl = "/estore/ProductDetailFrame.jsp?defaultTitle=&nextUrl=/gwhweb/viewAssessmentReport.do%3FproductId%3D561%26assessmentRollNumber%3D" + storeParam.arn;
        }
        mpacRequest = true;
        break;

      case EstoreProductTypeEnum.MPAC_ICI_COMMERCIAL_INDUSTRIAL_DETAILED_REPORT:
        if (userAccessControls.ICICommercialAndInstrlDetailRptTxnl) {
          storeUrl = "/estore/ProductDetailFrame.jsp?defaultTitle=&nextUrl=/gwhweb/purchaseAssessmentReport.do%3FproductId%3D568%26assessmentRollNumber%3D" + storeParam.arn;
        } else {
          storeUrl = "/estore/ProductDetailFrame.jsp?defaultTitle=&nextUrl=/gwhweb/viewAssessmentReport.do%3FproductId%3D568%26assessmentRollNumber%3D" + storeParam.arn;
        }
        mpacRequest = true;
        break;

      case EstoreProductTypeEnum.MPAC_REB_REPORT:
        if (userAccessControls.REBRptTxnl) {
          storeUrl = "/estore/ProductDetailFrame.jsp?defaultTitle=&nextUrl=/gwhweb/purchaseAssessmentReport.do%3FproductId%3D562%26assessmentRollNumber%3D" + storeParam.arn;
        } else {
          storeUrl = "/estore/ProductDetailFrame.jsp?defaultTitle=&nextUrl=/gwhweb/viewAssessmentReport.do%3FproductId%3D562%26assessmentRollNumber%3D" + storeParam.arn;
        }
        mpacRequest = true;
        break;

      case EstoreProductTypeEnum.MPAC_CORPORATE_NAME_ADDRESS_REPORT:
        if (userAccessControls.corpNameAndAddrRptTxnl) {
          storeUrl = "/estore/ProductDetailFrame.jsp?defaultTitle=&nextUrl=/gwhweb/purchaseAssessmentReport.do%3FproductId%3D563%26assessmentRollNumber%3D" + storeParam.arn;
        } else {
          storeUrl = "/estore/ProductDetailFrame.jsp?defaultTitle=&nextUrl=/gwhweb/viewAssessmentReport.do%3FproductId%3D563%26assessmentRollNumber%3D" + storeParam.arn;
        }
        mpacRequest = true;
        break;

      case EstoreProductTypeEnum.MPAC_AVM_COMPARABLE_REPORT:
        if (userAccessControls.AVMCmprbleRptTxnl) {
          storeUrl = "/estore/ProductDetailFrame.jsp?defaultTitle=&nextUrl=/gwhweb/purchaseAssessmentReport.do%3FproductId%3D564%26assessmentRollNumber%3D" + storeParam.arn;
        } else {
          storeUrl = "/estore/ProductDetailFrame.jsp?defaultTitle=&nextUrl=/gwhweb/viewAssessmentReport.do%3FproductId%3D564%26assessmentRollNumber%3D" + storeParam.arn;
        }
        mpacRequest = true;
        break;

      case EstoreProductTypeEnum.MPAC_RESIDENTIAL_FLOOR_AREA_REPORT:
        if (userAccessControls.residentialFloorAreaReportTxnl) {
          storeUrl = "/estore/ProductDetailFrame.jsp?defaultTitle=&nextUrl=/gwhweb/purchaseAssessmentReport.do%3FproductId%3D565%26assessmentRollNumber%3D" + storeParam.arn;
        } else {
          storeUrl = "/estore/ProductDetailFrame.jsp?defaultTitle=&nextUrl=/gwhweb/viewAssessmentReport.do%3FproductId%3D565%26assessmentRollNumber%3D" + storeParam.arn;
        }
        mpacRequest = true;
        break;

      case EstoreProductTypeEnum.MPAC_FOUR_YEAR_PHASED_IN_REPORT:
        if (userAccessControls.geowarehouse4YrPhaseInRptTxnl) {
          storeUrl = "/estore/ProductDetailFrame.jsp?defaultTitle=&nextUrl=/gwhweb/purchaseAssessmentReport.do%3FproductId%3D566%26assessmentRollNumber%3D" + storeParam.arn;
        } else {
          storeUrl = "/estore/ProductDetailFrame.jsp?defaultTitle=&nextUrl=/gwhweb/viewAssessmentReport.do%3FproductId%3D566%26assessmentRollNumber%3D" + storeParam.arn;
        }
        mpacRequest = true;
        break;

      case EstoreProductTypeEnum.MPAC_REB_RESIDENTIAL_DETAILED_REPORT:
        if (userAccessControls.REBResiDetailedRptTxnl) {
          storeUrl = "/estore/ProductDetailFrame.jsp?defaultTitle=&nextUrl=/gwhweb/purchaseAssessmentReport.do%3FproductId%3D569%26assessmentRollNumber%3D" + storeParam.arn;
        } else {
          storeUrl = "/estore/ProductDetailFrame.jsp?defaultTitle=&nextUrl=/gwhweb/viewAssessmentReport.do%3FproductId%3D569%26assessmentRollNumber%3D" + storeParam.arn;
        }
        mpacRequest = true;
        break;

      case EstoreProductTypeEnum.MPAC_REB_RESIDENTIAL_DETAIL_REPORT_RANGE:
        if (userAccessControls.REBResiDetailRptRangeTxnl) {
          storeUrl = "/estore/ProductDetailFrame.jsp?defaultTitle=&nextUrl=/gwhweb/purchaseAssessmentReport.do%3FproductId%3D583%26assessmentRollNumber%3D" + storeParam.arn;
        } else {
          storeUrl = "/estore/ProductDetailFrame.jsp?defaultTitle=&nextUrl=/gwhweb/viewAssessmentReport.do%3FproductId%3D583%26assessmentRollNumber%3D" + storeParam.arn;
        }
        mpacRequest = true;
        break;

      case EstoreProductTypeEnum.MPAC_AVM_MARKET_SALES_REPORT:
        if (userAccessControls.avmMarketSalesRptTxnl) {
          storeUrl = "/estore/ProductDetailFrame.jsp?defaultTitle=&nextUrl=/gwhweb/purchaseAssessmentReport.do%3FproductId%3D570%26assessmentRollNumber%3D" + storeParam.arn;
        } else {
          storeUrl = "/estore/ProductDetailFrame.jsp?defaultTitle=&nextUrl=/gwhweb/viewAssessmentReport.do%3FproductId%3D570%26assessmentRollNumber%3D" + storeParam.arn;
        }
        mpacRequest = true;
        break;

      case EstoreProductTypeEnum.MPAC_DISCOUNTED_ICI_COMMERCIAL_INDUSTRIAL_DETAILED_REPORT:
        if (userAccessControls.discountedICICommercialAndInstrlDetailRptTxnl) {
          storeUrl = "/estore/ProductDetailFrame.jsp?defaultTitle=&nextUrl=/gwhweb/purchaseAssessmentReport.do%3FproductId%3D573%26assessmentRollNumber%3D" + storeParam.arn;
        } else {
          storeUrl = "/estore/ProductDetailFrame.jsp?defaultTitle=&nextUrl=/gwhweb/viewAssessmentReport.do%3FproductId%3D573%26assessmentRollNumber%3D" + storeParam.arn;
        }
        mpacRequest = true;
        break;

      case EstoreProductTypeEnum.MPAC_AVM_RESIDENTIAL_COMPARABLE_REPORT:
        if (userAccessControls.AVMResiCmprbleRptTxnl) {
          storeUrl = "/estore/ProductDetailFrame.jsp?defaultTitle=&nextUrl=/gwhweb/purchaseAssessmentReport.do%3FproductId%3D574%26assessmentRollNumber%3D" + storeParam.arn;
        } else {
          storeUrl = "/estore/ProductDetailFrame.jsp?defaultTitle=&nextUrl=/gwhweb/viewAssessmentReport.do%3FproductId%3D574%26assessmentRollNumber%3D" + storeParam.arn;
        }
        mpacRequest = true;
        break;

      case EstoreProductTypeEnum.MPAC_NEW_RESIDENTIAL_FLOOR_AREA_REPORT:
        if (userAccessControls.NewResidentialFloorAreaReportTxnl) {
          storeUrl = "/estore/ProductDetailFrame.jsp?defaultTitle=&nextUrl=/gwhweb/purchaseAssessmentReport.do%3FproductId%3D575%26assessmentRollNumber%3D" + storeParam.arn;
        } else {
          storeUrl = "/estore/ProductDetailFrame.jsp?defaultTitle=&nextUrl=/gwhweb/viewAssessmentReport.do%3FproductId%3D575%26assessmentRollNumber%3D" + storeParam.arn;
        }
        mpacRequest = true;
        break;

      case EstoreProductTypeEnum.MPAC_RESIDENTIAL_FLOOR_AREA_RANGE_REPORT:
        if (userAccessControls.ResidentialFloorAreaRangeReportTxnl) {
          storeUrl = "/estore/ProductDetailFrame.jsp?defaultTitle=&nextUrl=/gwhweb/purchaseAssessmentReport.do%3FproductId%3D582%26assessmentRollNumber%3D" + storeParam.arn;
        } else {
          storeUrl = "/estore/ProductDetailFrame.jsp?defaultTitle=&nextUrl=/gwhweb/viewAssessmentReport.do%3FproductId%3D582%26assessmentRollNumber%3D" + storeParam.arn;
        }
        mpacRequest = true;
        break;

      case EstoreProductTypeEnum.MPAC_RESIDENTIAL_DETAILED_REPORT_LEVEL_2_RANGE:
        if (userAccessControls.ResidentialDetailedReportLevel2RangeTxnl) {
          storeUrl = "/estore/ProductDetailFrame.jsp?defaultTitle=&nextUrl=/gwhweb/purchaseAssessmentReport.do%3FproductId%3D584%26assessmentRollNumber%3D" + storeParam.arn;
        } else {
          storeUrl = "/estore/ProductDetailFrame.jsp?defaultTitle=&nextUrl=/gwhweb/viewAssessmentReport.do%3FproductId%3D584%26assessmentRollNumber%3D" + storeParam.arn;
        }
        mpacRequest = true;
        break;

      case EstoreProductTypeEnum.MPAC_AVM_RESIDENTIAL_COMPARABLE_REPORT_BUNDLE_10:
        if (userAccessControls.AVMResiCmprbleBndle250Fr10RptTxnl) {
          storeUrl = "/estore/ProductDetailFrame.jsp?defaultTitle=&nextUrl=/gwhweb/purchaseMpacBundle.do%3FproductId%3D576%26transactional%3D" + userAccessControls.AVMResiCmprbleBndle250Fr10RptTxnl;
        } else {
          storeUrl = "/getMpacNonTxnlBundle.do?productId=576&transactional=" + userAccessControls.AVMResiCmprbleBndle250Fr10RptTxnl;
          transactional = false;
        }
        mpacRequest = true;
        break;

      case EstoreProductTypeEnum.MPAC_AVM_MARKET_SALES_REPORT_BUNDLE:
        if (userAccessControls.AVMMarketSalesBndle350Fr10RptTxnl) {
          storeUrl = "/estore/ProductDetailFrame.jsp?defaultTitle=&nextUrl=/gwhweb/purchaseMpacBundle.do%3FproductId%3D577%26transactional%3D" + userAccessControls.AVMMarketSalesBndle350Fr10RptTxnl;
        } else {
          storeUrl = "/getMpacNonTxnlBundle.do?productId=577&transactional=" + userAccessControls.AVMMarketSalesBndle350Fr10RptTxnl;
          transactional = false;
        }
        mpacRequest = true;
        break;

      case EstoreProductTypeEnum.MPAC_REB_RESIDENTIAL_DETAIL_REPORT_BUNDLE_1:
        if (userAccessControls.REBResiBndle160Fr25RptTxnl) {
          storeUrl = "/estore/ProductDetailFrame.jsp?defaultTitle=&nextUrl=/gwhweb/purchaseMpacBundle.do%3FproductId%3D578%26transactional%3D" + userAccessControls.REBResiBndle160Fr25RptTxnl;
        } else {
          storeUrl = "/getMpacNonTxnlBundle.do?productId=578&transactional=" + userAccessControls.REBResiBndle160Fr25RptTxnl;
          transactional = false;
        }
        mpacRequest = true;
        break;

      case EstoreProductTypeEnum.MPAC_REB_RESIDENTIAL_DETAIL_REPORT_BUNDLE_2:
        if (userAccessControls.REBResiBndle280Fr50RptTxnl) {
          storeUrl = "/estore/ProductDetailFrame.jsp?defaultTitle=&nextUrl=/gwhweb/purchaseMpacBundle.do%3FproductId%3D579%26transactional%3D" + userAccessControls.REBResiBndle280Fr50RptTxnl;
        } else {
          storeUrl = "/getMpacNonTxnlBundle.do?productId=579&transactional=" + userAccessControls.REBResiBndle280Fr50RptTxnl;
          transactional = false;
        }
        mpacRequest = true;
        break;

      case EstoreProductTypeEnum.MPAC_RESIDENTIAL_FLOOR_AREA_REPORT_BUNDLE_1:
        if (userAccessControls.ResiFlorAreaBndle100Fr25RptTxnl) {
          storeUrl = "/estore/ProductDetailFrame.jsp?defaultTitle=&nextUrl=/gwhweb/purchaseMpacBundle.do%3FproductId%3D580%26transactional%3D" + userAccessControls.ResiFlorAreaBndle100Fr25RptTxnl;
        } else {
          storeUrl = "/getMpacNonTxnlBundle.do?productId=580&transactional=" + userAccessControls.ResiFlorAreaBndle100Fr25RptTxnl;
          transactional = false;
        }
        mpacRequest = true;
        break;

      case EstoreProductTypeEnum.MPAC_RESIDENTIAL_FLOOR_AREA_REPORT_BUNDLE_2:
        if (userAccessControls.ResiFlorAreaBndle175Fr50RptTxnl) {
          storeUrl = "/estore/ProductDetailFrame.jsp?defaultTitle=&nextUrl=/gwhweb/purchaseMpacBundle.do%3FproductId%3D581%26transactional%3D" + userAccessControls.ResiFlorAreaBndle175Fr50RptTxnl;
        } else {
          storeUrl = "/getMpacNonTxnlBundle.do?productId=581&transactional=" + userAccessControls.ResiFlorAreaBndle175Fr50RptTxnl;
          transactional = false;
        }
        mpacRequest = true;
        break;

      // TODO -- update ws call

      case EstoreProductTypeEnum.HOODQ_ADDRESS_REPORT:
        if (userAccessControls.HoodQAddressReportTxnl) {
          storeUrl = "/estore/ProductDetailFrame.jsp?defaultTitle=&nextUrl=/gwhweb/purchaseHoodQReport.do%3FproductId%3D585%26assessmentRollNumber%3D" + storeParam.arn + "%26pin%3D" + storeParam.pin;
        } else {
          storeUrl = "/estore/ProductDetailFrame.jsp?defaultTitle=&nextUrl=/gwhweb/viewHoodReport.do%3FproductId%3D585%26assessmentRollNumber%3D" + storeParam.arn + "%26pin%3D" + storeParam.pin;
        }
        mpacRequest = true;
        break;

      case EstoreProductTypeEnum.HOODQ_DETAILED_REPORT:
        if (userAccessControls.HoodQDetailedReportTxnl) {
          storeUrl = "/estore/ProductDetailFrame.jsp?defaultTitle=&nextUrl=/gwhweb/purchaseHoodQReport.do%3FproductId%3D586%26assessmentRollNumber%3D" + storeParam.arn + "%26pin%3D" + storeParam.pin;
        } else {
          storeUrl = "/estore/ProductDetailFrame.jsp?defaultTitle=&nextUrl=/gwhweb/viewHoodReport.do%3FproductId%3D586%26assessmentRollNumber%3D" + storeParam.arn + "%26pin%3D" + storeParam.pin;
        }
        mpacRequest = true;
        break;

      case EstoreProductTypeEnum.HOODQ_SCHOOL_REPORT:
        if (userAccessControls.HoodQSchoolReportTxnl) {
          storeUrl = "/estore/ProductDetailFrame.jsp?defaultTitle=&nextUrl=/gwhweb/purchaseHoodQReport.do%3FproductId%3D587%26assessmentRollNumber%3D" + storeParam.arn + "%26pin%3D" + storeParam.pin;
        } else {
          storeUrl = "/estore/ProductDetailFrame.jsp?defaultTitle=&nextUrl=/gwhweb/viewHoodReport.do%3FproductId%3D587%26assessmentRollNumber%3D" + storeParam.arn + "%26pin%3D" + storeParam.pin;
        }
        mpacRequest = true;
        break;

      case EstoreProductTypeEnum.MPAC_AVM_RANGE_REPORT:
        if (userAccessControls.avmRangeReportTxnl) {
          storeUrl = "/estore/ProductDetailFrame.jsp?defaultTitle=&nextUrl=/gwhweb/purchaseTeranetPropertyInsightsReport.do%3FproductId%3D170%26reportReference%3D" + storeParam.reportReference + "%26pin%3D" + storeParam.pin;
        } else {
          storeUrl = "/estore/ProductDetailFrame.jsp?defaultTitle=&nextUrl=/gwhweb/viewTeranetPropertyInsightsReport.do%3FproductId%3D170%26reportReference%3D" + storeParam.reportReference + "%26pin%3D" + storeParam.pin;
        }
        break;

      case EstoreProductTypeEnum.AVM_RANGE_REPORT:
        if (userAccessControls.avmRangeReportTxnl) {
          storeUrl = "/estore/ProductDetailFrame.jsp?defaultTitle=&nextUrl=/gwhweb/purchaseTeranetPropertyInsightsReport.do%3FproductId%3D170%26reportReference%3D" + storeParam.reportReference + "%26pin%3D" + storeParam.pin;
        } else {
          storeUrl = "/estore/ProductDetailFrame.jsp?defaultTitle=&nextUrl=/gwhweb/viewTeranetPropertyInsightsReport.do%3FproductId%3D170%26reportReference%3D" + storeParam.reportReference + "%26pin%3D" + storeParam.pin;
        }
        break;

      case EstoreProductTypeEnum.MPAC_PREMIUM_AVM_COMPARABLE_REPORT:
        if (userAccessControls.premiumAvmComparableReportTxnl) {
          storeUrl = "/estore/ProductDetailFrame.jsp?defaultTitle=&nextUrl=/gwhweb/purchaseTeranetPropertyInsightsReport.do%3FproductId%3D171%26reportReference%3D" + storeParam.reportReference + "%26pin%3D" + storeParam.pin;
        } else {
          storeUrl = "/estore/ProductDetailFrame.jsp?defaultTitle=&nextUrl=/gwhweb/viewTeranetPropertyInsightsReport.do%3FproductId%3D171%26reportReference%3D" + storeParam.reportReference + "%26pin%3D" + storeParam.pin;
        }
        break;

      case EstoreProductTypeEnum.PREMIUM_AVM_COMPARABLE_REPORT:
        if (userAccessControls.premiumAvmComparableReportTxnl) {
          storeUrl = "/estore/ProductDetailFrame.jsp?defaultTitle=&nextUrl=/gwhweb/purchaseTeranetPropertyInsightsReport.do%3FproductId%3D171%26reportReference%3D" + storeParam.reportReference + "%26pin%3D" + storeParam.pin;
        } else {
          storeUrl = "/estore/ProductDetailFrame.jsp?defaultTitle=&nextUrl=/gwhweb/viewTeranetPropertyInsightsReport.do%3FproductId%3D171%26reportReference%3D" + storeParam.reportReference + "%26pin%3D" + storeParam.pin;
        }
        break;

      case EstoreProductTypeEnum.PYB_BUYER_REPORT:
        if (userAccessControls.pybBuyersReportTxnl) {
          storeUrl = "/estore/ProductDetailFrame.jsp?defaultTitle=&nextUrl=/gwhweb/purchasePYBReport.do%3FproductId%3D1100%26assessmentRollNumber%3D" + storeParam.arn + "%26pin%3D" + storeParam.pin + "%26deeplinkFlow%3DestoreProduct" + "%26deeplink_searchval%3D" + storeParam.pin;
        }
        pybRequest = true;
        break;

      case EstoreProductTypeEnum.PYB_EASEMENT_REPORT:
        if (userAccessControls.pybEasmentReportTxnl) {
          storeUrl = "/estore/ProductDetailFrame.jsp?defaultTitle=&nextUrl=/gwhweb/purchasePYBReport.do%3FproductId%3D1101%26assessmentRollNumber%3D" + storeParam.arn + "%26pin%3D" + storeParam.pin + "%26deeplinkFlow%3DestoreProduct" + "%26deeplink_searchval%3D" + storeParam.pin;
        }
        pybRequest = true;
        break;

      case EstoreProductTypeEnum.PYB_SELLER_REPORT:
        if (userAccessControls.pybSellersReportTxnl) {
          storeUrl = "/estore/ProductDetailFrame.jsp?defaultTitle=&nextUrl=/gwhweb/purchasePYBReport.do%3FproductId%3D1102%26assessmentRollNumber%3D" + storeParam.arn + "%26pin%3D" + storeParam.pin + "%26deeplinkFlow%3DestoreProduct" + "%26deeplink_searchval%3D" + storeParam.pin;
        }
        pybRequest = true;
        break;

      case EstoreProductTypeEnum.PYB_SURVEY:
        storeUrl = "/estore/ProductDetailFrame.jsp?defaultTitle=&nextUrl=/gwhweb/documentImage.do%3Faction%3DgettingSurveyDocument%26token%3D" + storeParam.pybToken + "%26product%3DsomeProduct" + "%26deeplinkFlow%3DestoreProduct" + "%26deeplink_searchval%3D" + storeParam.pin;
        pybRequest = true;
        break;

      case EstoreProductTypeEnum.COMPARABLE_SEARCH_REPORT_TOPUP_50:
        if (userAccessControls.reportTopUp50ComparableSearchTxnl) {
          storeUrl = "/estore/ProductDetailFrame.jsp?nextUrl=/gwhweb/purchaseTopUpReport.do%3FproductId%3D1260";
        }
        mpacRequest = true;
        break;

      case EstoreProductTypeEnum.COMPARABLE_SEARCH_REPORT_TOPUP_100:
        if (userAccessControls.reportTopUp100ComparableSearchTxnl) {
          storeUrl = "/estore/ProductDetailFrame.jsp?nextUrl=/gwhweb/purchaseTopUpReport.do%3FproductId%3D1261";
        }
        mpacRequest = true;
        break;

      case EstoreProductTypeEnum.COMPARABLE_SEARCH_REPORT_TOPUP_150:
        if (userAccessControls.reportTopUp150ComparableSearchTxnl) {
          storeUrl = "/estore/ProductDetailFrame.jsp?nextUrl=/gwhweb/purchaseTopUpReport.do%3FproductId%3D1262";
        }
        mpacRequest = true;
        break;

      case EstoreProductTypeEnum.COMPARABLE_SEARCH_REPORT_TOPUP_200:
        if (userAccessControls.reportTopUp200ComparableSearchTxnl) {
          storeUrl = "/estore/ProductDetailFrame.jsp?nextUrl=/gwhweb/purchaseTopUpReport.do%3FproductId%3D1263";
        }
        mpacRequest = true;
        break;

      case EstoreProductTypeEnum.COMPARABLE_SEARCH_REPORT_TOPUP_250:
        if (userAccessControls.reportTopUp250ComparableSearchTxnl) {
          storeUrl = "/estore/ProductDetailFrame.jsp?nextUrl=/gwhweb/purchaseTopUpReport.do%3FproductId%3D1264";
        }
        mpacRequest = true;
        break;

      case EstoreProductTypeEnum.REPORT_TOPUP_100_STANDARD:
        if (userAccessControls.reportTopUp100StandardPropertyTxnl) {
          storeUrl = "/estore/ProductDetailFrame.jsp?nextUrl=/gwhweb/purchaseTopUpReport.do%3FproductId%3D1250";
        }
        mpacRequest = true;
        break;

      case EstoreProductTypeEnum.REPORT_TOPUP_250_STANDARD:
        if (userAccessControls.reportTopUp250StandardPropertyTxnl) {
          storeUrl = "/estore/ProductDetailFrame.jsp?nextUrl=/gwhweb/purchaseTopUpReport.do%3FproductId%3D1251";
        }
        mpacRequest = true;
        break;

      case EstoreProductTypeEnum.REPORT_TOPUP_500_STANDARD:
        if (userAccessControls.reportTopUp500StandardPropertyTxnl) {
          storeUrl = "/estore/ProductDetailFrame.jsp?nextUrl=/gwhweb/purchaseTopUpReport.do%3FproductId%3D1252";
        }
        mpacRequest = true;
        break;

      case EstoreProductTypeEnum.REPORT_TOPUP_100_STANDARD_MPAC:
        if (userAccessControls.reportTopUp100MPACStandardPropertyTxnl) {
          storeUrl = "/estore/ProductDetailFrame.jsp?nextUrl=/gwhweb/purchaseTopUpReport.do%3FproductId%3D1265";
        }
        mpacRequest = true;
        break;

      case EstoreProductTypeEnum.REPORT_TOPUP_250_STANDARD_MPAC:
        if (userAccessControls.reportTopUp250MPACStandardPropertyTxnl) {
          storeUrl = "/estore/ProductDetailFrame.jsp?nextUrl=/gwhweb/purchaseTopUpReport.do%3FproductId%3D1266";
        }
        mpacRequest = true;
        break;

      case EstoreProductTypeEnum.REPORT_TOPUP_500_STANDARD_MPAC:
        if (userAccessControls.reportTopUp500MPACStandardPropertyTxnl) {
          storeUrl = "/estore/ProductDetailFrame.jsp?nextUrl=/gwhweb/purchaseTopUpReport.do%3FproductId%3D1267";
        }
        mpacRequest = true;
        break;

      case EstoreProductTypeEnum.COMPARABLE_SEARCH_REPORT_TOPUP_50_MPAC:
        if (userAccessControls.comparableTopUp50MPACStandardPropertyTxnl) {
          storeUrl = "/estore/ProductDetailFrame.jsp?nextUrl=/gwhweb/purchaseTopUpReport.do%3FproductId%3D1268";
        }
        mpacRequest = true;
        break;

      case EstoreProductTypeEnum.COMPARABLE_SEARCH_REPORT_TOPUP_100_MPAC:
        if (userAccessControls.comparableTopUp100MPACStandardPropertyTxnl) {
          storeUrl = "/estore/ProductDetailFrame.jsp?nextUrl=/gwhweb/purchaseTopUpReport.do%3FproductId%3D1269";
        }
        mpacRequest = true;
        break;

      case EstoreProductTypeEnum.COMPARABLE_SEARCH_REPORT_TOPUP_150_MPAC:
        if (userAccessControls.comparableTopUp150MPACStandardPropertyTxnl) {
          storeUrl = "/estore/ProductDetailFrame.jsp?nextUrl=/gwhweb/purchaseTopUpReport.do%3FproductId%3D1270";
        }
        mpacRequest = true;
        break;

      case EstoreProductTypeEnum.COMPARABLE_SEARCH_REPORT_TOPUP_200_MPAC:
        if (userAccessControls.comparableTopUp200MPACStandardPropertyTxnl) {
          storeUrl = "/estore/ProductDetailFrame.jsp?nextUrl=/gwhweb/purchaseTopUpReport.do%3FproductId%3D1271";
        }
        mpacRequest = true;
        break;

      case EstoreProductTypeEnum.COMPARABLE_SEARCH_REPORT_TOPUP_250_MPAC:
        if (userAccessControls.comparableTopUp250MPACStandardPropertyTxnl) {
          storeUrl = "/estore/ProductDetailFrame.jsp?nextUrl=/gwhweb/purchaseTopUpReport.do%3FproductId%3D1272";
        }
        mpacRequest = true;
        break;

      case EstoreProductTypeEnum.REPORT_TOPUP_PACKAGE:
        if (userAccessControls.topUpPackageReportTxnl) {
          storeUrl = "/estore/ProductDetailFrame.jsp?defaultTitle=&nextUrl=/gwhweb/purchaseTopUpReport.do%3FproductId%3D1108";
        } else {
          //storeUrl = "/estore/ProductDetailFrame.jsp?defaultTitle=&nextUrl=/gwhweb/viewHoodReport.do%3FproductId%3D585%26assessmentRollNumber%3D" + arn + "%26pin%3D" + pin;
        }
        mpacRequest = true;
        break;

      /* GWHgeoReportTopUp Products START */

      case EstoreProductTypeEnum.REPORT_TOPUP_1000_PROPERTY:
        if (userAccessControls.reportTopUp1000PropertyTxnl) {
          storeUrl = "/estore/ProductDetailFrame.jsp?nextUrl=/gwhweb/purchaseTopUpReport.do%3FproductId%3D1111";
        }
        mpacRequest = true;
        break;

      case EstoreProductTypeEnum.REPORT_TOP_2500_PROPERTY:
        if (userAccessControls.reportTopUp2500PropertyTxnl) {
          storeUrl = "/estore/ProductDetailFrame.jsp?nextUrl=/gwhweb/purchaseTopUpReport.do%3FproductId%3D1112";
        }
        mpacRequest = true;
        break;

      case EstoreProductTypeEnum.REPORT_TOP_5000_PROPERTY:
        if (userAccessControls.reportTopUp5000PropertyTxnl) {
          storeUrl = "/estore/ProductDetailFrame.jsp?nextUrl=/gwhweb/purchaseTopUpReport.do%3FproductId%3D1113";
        }
        mpacRequest = true;
        break;

      case EstoreProductTypeEnum.REPORT_TOPUP_1000_PROPERTY_MPAC:
        if (userAccessControls.mpacReportTopUp1000PropertyTxnl) {
          storeUrl = "/estore/ProductDetailFrame.jsp?nextUrl=/gwhweb/purchaseTopUpReport.do%3FproductId%3D1114";
        }
        mpacRequest = true;
        break;

      case EstoreProductTypeEnum.REPORT_TOP_2500_PROPERTY_MPAC:
        if (userAccessControls.mpacReportTopUp2500PropertyTxnl) {
          storeUrl = "/estore/ProductDetailFrame.jsp?nextUrl=/gwhweb/purchaseTopUpReport.do%3FproductId%3D1115";
        }
        mpacRequest = true;
        break;

      case EstoreProductTypeEnum.REPORT_TOP_5000_PROPERTY_MPAC:
        if (userAccessControls.mpacReportTopUp5000PropertyTxnl) {
          storeUrl = "/estore/ProductDetailFrame.jsp?nextUrl=/gwhweb/purchaseTopUpReport.do%3FproductId%3D1116";
        }
        mpacRequest = true;
        break;

      case EstoreProductTypeEnum.REPORT_TOPUP_200_PROPERTY_MPAC:
        if (userAccessControls.mpacReportTopUp200PropertyTxnl) {
          storeUrl = "/estore/ProductDetailFrame.jsp?nextUrl=/gwhweb/purchaseTopUpReport.do%3FproductId%3D1126";
        }
        mpacRequest = true;
        break;

      case EstoreProductTypeEnum.REPORT_TOPUP_500_PROPERTY_MPAC:
        if (userAccessControls.mpacReportTopUp500PropertyTxnl) {
          storeUrl = "/estore/ProductDetailFrame.jsp?nextUrl=/gwhweb/purchaseTopUpReport.do%3FproductId%3D1127";
        }
        mpacRequest = true;
        break;

      case EstoreProductTypeEnum.REPORT_TOPUP_200_PROPERTY:
        if (userAccessControls.reportTopUp200PropertyTxnl) {
          storeUrl = "/estore/ProductDetailFrame.jsp?nextUrl=/gwhweb/purchaseTopUpReport.do%3FproductId%3D1128";
        }
        mpacRequest = true;
        break;

      case EstoreProductTypeEnum.REPORT_TOPUP_500_PROPERTY:
        if (userAccessControls.reportTopUp500PropertyTxnl) {
          storeUrl = "/estore/ProductDetailFrame.jsp?nextUrl=/gwhweb/purchaseTopUpReport.do%3FproductId%3D1129";
        }
        mpacRequest = true;
        break;

      /* GWHgeoReportTopUp Products END */

      case EstoreProductTypeEnum.CONDO_SUITE_SQUARE_FOOTAGE_REPORT:
        if (userAccessControls.CondoSuiteSqrFootageTxnl) {
          storeUrl = "/estore/ProductDetailFrame.jsp?defaultTitle=&nextUrl=/gwhweb/purchaseCondoReport.do%3FproductId%3D1123%26arn%3D" + storeParam.arn + "%26pin%3D" + storeParam.pin;
        } else {
          storeUrl = "/estore/ProductDetailFrame.jsp?defaultTitle=&nextUrl=/gwhweb/viewCondoReport.do%3FproductId%3D1123%26arn%3D" + storeParam.arn + "%26pin%3D" + storeParam.pin;
        }
        condoRequest = true;
        break;

      case EstoreProductTypeEnum.CONDO_SUITE_SQUARE_FOOTAGE_BUNDLE:
        if (userAccessControls.CondoSqrFootageBundleTxnl) {
          storeUrl = "/estore/ProductDetailFrame.jsp?defaultTitle=&nextUrl=/gwhweb/purchaseCondoBundleReport.do%3FproductId%3D1125%26transactional%3D" + userAccessControls.CondoSqrFootageBundleTxnl;
        } else {
          storeUrl = "/estore/ProductDetailFrame.jsp?defaultTitle=&nextUrl=/gwhweb/viewCondoBundleReport.do%3FproductId%3D1125%26transactional%3D" + userAccessControls.CondoSqrFootageBundleTxnl;
        }
        condoRequest = true;
        break;

      case EstoreProductTypeEnum.NON_TRANSACTIONAL_CONDO_REPORT:
        storeUrl = "/estore/ProductDetailFrame.jsp?defaultTitle=&nextUrl=/gwhweb/getCondoRptNonTxnlBundle.do%3FproductId%3D" + storeParam.prodId + "%26arn%3D" + storeParam.arn + "%26pin%3D" + storeParam.pin;
        transactional = false;
        condoRequest = true;
        break;

      case EstoreProductTypeEnum.REPORT_TOPUP_5000_MPS_COMMERCIAL:
        if (userAccessControls.reportTopUp5000MPSCommercialTxnl) {
          storeUrl = "/estore/ProductDetailFrame.jsp?nextUrl=/gwhweb/purchaseTopUpReport.do%3FproductId%3D1134";
        }
        mpacRequest = true;
        break;

      case EstoreProductTypeEnum.REPORT_TOPUP_2500_MPS_COMMERCIAL:
        if (userAccessControls.reportTopUp2500MPSCommercialTxnl) {
          storeUrl = "/estore/ProductDetailFrame.jsp?nextUrl=/gwhweb/purchaseTopUpReport.do%3FproductId%3D1135";
        }
        mpacRequest = true;
        break;

      case EstoreProductTypeEnum.REPORT_TOPUP_1000_MPS_COMMERCIAL:
        if (userAccessControls.reportTopUp1000MPSCommercialTxnl) {
          storeUrl = "/estore/ProductDetailFrame.jsp?nextUrl=/gwhweb/purchaseTopUpReport.do%3FproductId%3D1136";
        }
        mpacRequest = true;
        break;

      case EstoreProductTypeEnum.REPORT_TOPUP_5000_MPS_RESIDENTIAL:
        if (userAccessControls.reportTopUp5000MPSResidentialTxnl) {
          storeUrl = "/estore/ProductDetailFrame.jsp?nextUrl=/gwhweb/purchaseTopUpReport.do%3FproductId%3D1137";
        }
        mpacRequest = true;
        break;

      case EstoreProductTypeEnum.REPORT_TOPUP_2500_MPS_RESIDENTIAL:
        if (userAccessControls.reportTopUp2500MPSResidentialTxnl) {
          storeUrl = "/estore/ProductDetailFrame.jsp?nextUrl=/gwhweb/purchaseTopUpReport.do%3FproductId%3D1138";
        }
        mpacRequest = true;
        break;
      case EstoreProductTypeEnum.REPORT_TOPUP_1000_MPS_RESIDENTIAL:
        if (userAccessControls.reportTopUp1000MPSResidentialTxnl) {
          storeUrl = "/estore/ProductDetailFrame.jsp?nextUrl=/gwhweb/purchaseTopUpReport.do%3FproductId%3D1139";
        }
        mpacRequest = true;
        break;

      case EstoreProductTypeEnum.RESIDENTIAL_RELOCATION_REPORT_LRO:
        residentialRelocationRequest = true;
        const searchParam = storeParam.additionalPropertyParam?.lroName ?? storeParam.lro;
        if (userAccessControls.residentialRelocationReportLroTxnl) {
          storeUrl = "/estore/ProductDetailFrame.jsp?defaultTitle=&nextUrl=/gwhweb/purchaseBuyerMobilityReport.do%3FproductId%3D1119%26searchParam%3D" + searchParam + "%26fromDate%3D" + storeParam.additionalPropertyParam?.fromDate + "%26toDate%3D" + storeParam.additionalPropertyParam?.toDate + "%26quarter%3D" + storeParam.additionalPropertyParam?.quarter;
        } else {
          storeUrl = "/estore/ProductDetailFrame.jsp?defaultTitle=&nextUrl=/gwhweb/viewBuyerMobilityReport.do%3FproductId%3D1119%26searchParam%3D" + searchParam + "%26fromDate%3D" + storeParam.additionalPropertyParam?.fromDate + "%26toDate%3D" + storeParam.additionalPropertyParam?.toDate + "%26quarter%3D" + storeParam.additionalPropertyParam?.quarter;
          transactional = false;
        }
        break;

      case EstoreProductTypeEnum.RESIDENTIAL_RELOCATION_REPORT_MUNICIPALITY:
        residentialRelocationRequest = true;
        if (userAccessControls.residentialRelocationReportMunicipalityTxnl) {
          storeUrl = "/estore/ProductDetailFrame.jsp?defaultTitle=&nextUrl=/gwhweb/purchaseBuyerMobilityReport.do%3FproductId%3D1120%26searchParam%3D" + storeParam.additionalPropertyParam?.municipality + "%26fromDate%3D" + storeParam.additionalPropertyParam?.fromDate + "%26toDate%3D" + storeParam.additionalPropertyParam?.toDate + "%26quarter%3D" + storeParam.additionalPropertyParam?.quarter;
        } else {
          transactional = false;
          storeUrl = "/estore/ProductDetailFrame.jsp?defaultTitle=&nextUrl=/gwhweb/viewBuyerMobilityReport.do%3FproductId%3D1120%26searchParam%3D" + storeParam.additionalPropertyParam?.municipality + "%26fromDate%3D" + storeParam.additionalPropertyParam?.fromDate + "%26toDate%3D" + storeParam.additionalPropertyParam?.toDate + "%26quarter%3D" + storeParam.additionalPropertyParam?.quarter;
        }
        break;

      case EstoreProductTypeEnum.RESIDENTIAL_RELOCATION_REPORT_FSA:
        residentialRelocationRequest = true;
        if (userAccessControls.residentialRelocationReportFsaTxnl) {
          storeUrl = "/estore/ProductDetailFrame.jsp?defaultTitle=&nextUrl=/gwhweb/purchaseBuyerMobilityReport.do%3FproductId%3D1121%26searchParam%3D" + storeParam.additionalPropertyParam?.first3CharPostalCode + "%26fromDate%3D" + storeParam.additionalPropertyParam?.fromDate + "%26toDate%3D" + storeParam.additionalPropertyParam?.toDate;
        } else {
          transactional = false;
          storeUrl = "/estore/ProductDetailFrame.jsp?defaultTitle=&nextUrl=/gwhweb/viewBuyerMobilityReport.do%3FproductId%3D1121%26searchParam%3D" + storeParam.additionalPropertyParam?.first3CharPostalCode + "%26fromDate%3D" + storeParam.additionalPropertyParam?.fromDate + "%26toDate%3D" + storeParam.additionalPropertyParam?.toDate;
        }
        break;

      default:// opens nothing
        storeUrl = "";
        eStoreWindowInfo.size = storeWindowSizeCatalog;
        break;
    }

    if ((storeUrl.includes("viewCart.do") || residentialRelocationRequest) && (!!storeParam.uuid)) {
      storeUrl += ("%26rrf%3D" + storeParam.uuid);
    } else if (mpacRequest || pybRequest) {
      storeUrl += ("&rrf=" + storeParam.uuid);
    }

    storeUrl += ("&collabSrcUrl=" + window.location.href);

    if (this.featureFlagService.isFeatureEnabled(FeatureFlagEnum.MARKETING_AD_CLICK)) {
      storeUrl += ("&adSrcId=" + ""); //todo take the value from the ad click url or from the observable
      storeUrl += ("&adProductId=" + ""); //todo take the value from the ad click url or from the observable
    }

    eStoreWindowInfo.storeUrl = storeParam.token + "&resource=" + encodeURIComponent(storeUrl);

    return eStoreWindowInfo;
  }


  storeWindow: any;

  private openEstoreWindow(estoreDisplayMode: EstoreDisplayModeEnum, url?: any, title?: any, size?: any, uuid?: any, browserTabSetting?: EstoreBrowserTabAttribute) {

    let defaultWindowTitle = 'GeoWarehouse Store';
    if (title) {
      defaultWindowTitle = title;
    }

    let storeWindowSizeDefault = 'width=700,height=700';
    if (size) {
      storeWindowSizeDefault = size;
    }

    if (url) {
      switch (estoreDisplayMode) {
        case EstoreDisplayModeEnum.POPUP_WINDOW:
          if (this.storeWindow != null && !this.storeWindow.closed) {
            this.storeWindow.close();
          }
          this.storeWindow = window.open(url, title, storeWindowSizeDefault);
          return this.storeWindow;

        case EstoreDisplayModeEnum.BROWSER_TAB:
          //title is set by the estore page
          if (browserTabSetting) {
            if (browserTabSetting.newTab) {
              if (browserTabSetting.newTabFocus) {
                window.open(url, '_blank')?.focus();
              } else {
                window.open(url, '_blank');
              }
            } else {
              window.open(url, '_self');
            }
          } else {
            //default
            window.open(url, '_self');
          }
          return null;
      }
    }
  }

  async redirectToGWHInvoicePage(transactionId: string | undefined) {

    const ssoOIDCJson: SSOOpenIDConnectJson = await lastValueFrom(this.ssoService.getOpenIDConnectJson(), { defaultValue: new SSOOpenIDConnectJson() });

    if (ssoOIDCJson?.accessToken) {
      const url = ssoOIDCJson.accessTokenLoginUrl + "&resource=/transactionDetail.do?transactionId=" + transactionId;
      this.openEstoreWindow(EstoreDisplayModeEnum.BROWSER_TAB, url, 'Purchase Receipt', null, null, new EstoreBrowserTabAttribute(false, false));
    }
  }

  async renewSubscription() {
    await this.openShoppingCartWithStoreProductType('GWHLicenseSubscriptionRenewal', EstoreDisplayModeEnum.POPUP_WINDOW);
  }

  async openShoppingCart() {
    await this.openShoppingCartWithStoreProductType(EstoreProductTypeEnum.SHOPPING_CART, EstoreDisplayModeEnum.BROWSER_TAB);
  }

  async openShoppingCartWithStoreProductType(storeProductType: string, estoreDisplayMode: EstoreDisplayModeEnum) {
    const ssoOIDCJson: SSOOpenIDConnectJson = await lastValueFrom(this.ssoService.getOpenIDConnectJson(), { defaultValue: new SSOOpenIDConnectJson() });

    if (ssoOIDCJson?.accessToken) {
      const storeParam: EStoreParam = new EStoreParam(storeProductType);
      storeParam.token = ssoOIDCJson.accessTokenLoginUrl;
      var storeInfo = this.getStoreInfo(storeParam);
      var storeWindow = this.openEstoreWindow(estoreDisplayMode, storeInfo.storeUrl, 'SSO Store', storeInfo.size);
    }
  }

  async openShoppingCartWithStoreProductTypeForSSO(storeParam: EStoreParam, estoreDisplayMode: EstoreDisplayModeEnum) {
    const ssoOIDCJson: SSOOpenIDConnectJson = await lastValueFrom(this.ssoService.getOpenIDConnectJson(), { defaultValue: new SSOOpenIDConnectJson() });

    if (ssoOIDCJson?.accessToken && storeParam) {
      storeParam.token = ssoOIDCJson.accessTokenLoginUrl;
      var storeInfo = this.getStoreInfo(storeParam);
      var storeWindow = this.openEstoreWindow(estoreDisplayMode, storeInfo.storeUrl, 'SSO Store', storeInfo.size);
    }
  }

  //async openReport(storeProductType: string, pin: string | undefined, arn : string, lro: string | null, product:EStoreProduct, reportReference?: any, additionalPropertyParam? : EStoreParamAdditional, landRegistryStatus?: string, tokenStr?: string) {
  async openReport(eStoreParam: EStoreParam, browserTabSetting?: EstoreBrowserTabAttribute) {
    this.loggerService.logDebug(`openReport(storeProductType=${eStoreParam.storeProductType},pin=${eStoreParam.pin}, product=${eStoreParam.prodId} )`)

    const ssoOIDCJson: SSOOpenIDConnectJson = await lastValueFrom(this.ssoService.getOpenIDConnectJson(), { defaultValue: new SSOOpenIDConnectJson() });

    if (ssoOIDCJson?.accessToken) {
      eStoreParam.uuid = uuidv4();
      eStoreParam.token = ssoOIDCJson.accessTokenLoginUrl;
      var storeInfo = this.getStoreInfo(eStoreParam);
      var storeWindow = this.openEstoreWindow(EstoreDisplayModeEnum.BROWSER_TAB, storeInfo.storeUrl, 'SSO Store', storeInfo.size, eStoreParam.uuid, browserTabSetting);

      this.setEstoreUrl(storeInfo.storeUrl);
    }
  }

  getEstoreLandingPageSettings = (): Observable<LandingPageSettings> => {
    return this.https.get('../../../../assets/json/estore/landing-page.json').pipe(
      map(resp => {
        return <LandingPageSettings>resp;
      }),
      catchError((err) => {
        this.loggerService.logError(err);
        return throwError(err);
      })
    );
  }

  getEstoreProductCategories = (): Observable<ProductCategoryItem[]> => {
    return this.https.get<ProductCategoryItem[]>('../../../../assets/json/estore/product-categories.json')
      .pipe(
        map(resp => {
          return resp.map(item => new ProductCategoryItem(item));
        }),
        catchError((err) => {
          this.loggerService.logError(err);
          return throwError(err);
        })
      );
  }

  getEStoreProducts(pin:String): Observable<EStoreProductPage> {
    const url = `${baseUrl}/user/productpage?pin=${pin}`;

    return this.https.get(url).pipe(
      map((resp) => {
        return new EStoreProductPage(<EStoreProductPage>resp);
      }),
      catchError((err) => {
        this.loggerService.logError(err);
        return throwError(err);
      })
    );
  }

  getSearchURL(): Observable<EStoreSearchUrl> {
    const url = `${baseUrl}/externalURl`;

    return this.https.get(url).pipe(
      map((resp) => {
        return <EStoreSearchUrl>resp;
      }),
      catchError((err) => {
        this.loggerService.logError(err);
        return throwError(err);
      })
    );
  }

  getPlToken(): Observable<EStorePlToken> {
    const url = `${baseUrl}/plToken`;

    return this.https.get(url).pipe(
      map((resp) => {
        return <EStorePlToken>resp;
      }),
      catchError((err) => {
        this.loggerService.logError(err);
        return throwError(err);
      })
    );
  }

  getConduitUrl(pin: string): Observable<any> {
    const url = `${baseUrl}/conduit/link?pin=` + pin;

    return this.https.get(url).pipe(
      map((response) => {
        return <any> response;
      }),
      catchError((err) => {
        this.loggerService.logError(err);
        return throwError(err);
      })
    );
  }

  async openPropertyLine(arn: string | undefined) {
    let windowName = (arn != null) ? 'PropertyLineARN' : 'PropertyLineCommon';
    if (arn == 'N/A') {
      arn = undefined;
    }
    const plToken = await lastValueFrom(this.getPlToken());
    if (plToken) {
      var navURL = this.getPropertyLineNavURL(plToken.plURL, plToken.plToken, arn);
      window.open(navURL, windowName);
    }
  }

  getPropertyLineNavURL(plURL: string, plToken: string, arn: string | undefined) {
    return arn ?
      `${plURL}&searchType=roll&searchText=${arn}&token=${plToken}` :
      `${plURL}&token=${plToken}`;
  }

  getCarouselProductsByBeID = (beId: number): Observable<ProductCarousel> => {
    const url = `${baseUrl}/user/carouselProduct?beid=` + beId;
    return this.https.get<any>(url, {
      headers: {
        'Content-Type': 'application/json'
      }
    }).pipe(
      map(response => {
        if (!_.isEmpty(response)) {
          return new ProductCarousel(<ProductCarousel>response);
        } else {
          return new ProductCarousel();
        }
      }),
      catchError((err) => {
        this.loggerService.logError(`error getting carousel products for beid ${beId}`);
        return throwError(err);
      })
    );
  }

  getCarouselProductsByPin = (pin: string): Observable<ProductCarousel> => {
    const url = `${baseUrl}/user/carouselProduct?pin=` + pin;
    return this.https.get<any>(url, {
      headers: {
        'Content-Type': 'application/json'
      }
    }).pipe(
      map(response => {
        if (!_.isEmpty(response)) {
          return new ProductCarousel(<ProductCarousel>response);
        } else {
          return new ProductCarousel();
        }
      }),
      catchError((err) => {
        this.loggerService.logError(`error getting carousel products for pin ${pin}`);
        return throwError(err);
      })
    );
  }

  getEstoreCartCount = (): Observable<CartCounter> => {
    const url = `${baseUrl}/estore/cart/counter`;

    return this.https.get(url, {
      headers: {
        'Content-Type': 'application/json'
      }
    }).pipe(
      map(response => {
        if (!_.isEmpty(response)) {
          return new CartCounter(<CartCounter> response);
        } else {
          return new CartCounter();
        }
      }),
      catchError((err) => {
        this.loggerService.logError(`error retrieving estore cart count`, err);
        return throwError(err);
      })
    );
  }

  goToCatalogueCategory = (category: EstoreProductCategoryEnum) => {
    this.router.navigate(['/catalogue', RouteMappingUtility.eStoreCategoryToRoute(category)]);
  }

  // getCarouselProducts = (beId: number): Observable<ProductCarousel> => {
  //   const url = `${baseUrl}/user/carouselProduct?beid=` + beId;
  //   return this.https.get<ProductCarousel>(url, {
  //     headers: {
  //         'Content-Type': 'application/json'
  //     }
  //   }).pipe(
  //     map(response => {
  //         if (!_.isEmpty(response)) {
  //             return <ProductCarousel>response;
  //         } else {
  //             return new ProductCarousel();
  //         }
  //     }),
  //     catchError((err) => {
  //         this.loggerService.logError(`error getting carousel products for beid ${beId}`);
  //         return throwError(err);
  //     })
  //   );
  // }
  isProductNotAccessible(product: EStoreProduct): boolean {
    const userAccessControls = this.userService.getUserAccessControl();
    const prodId: number = +product.prodId;

    if (prodId) {
      return (
           (prodId == 1100 && !userAccessControls.pybBuyersReportTxnl)
        || (prodId == 1101 && !userAccessControls.pybEasmentReportTxnl)
        || (prodId == 1102 && !userAccessControls.pybSellersReportTxnl)
        || (prodId == 1111 && !userAccessControls.reportTopUp1000PropertyTxnl)
        || (prodId == 1112 && !userAccessControls.reportTopUp2500PropertyTxnl)
        || (prodId == 1113 && !userAccessControls.reportTopUp5000PropertyTxnl)
        || (prodId == 1114 && !userAccessControls.mpacReportTopUp1000PropertyTxnl)
        || (prodId == 1115 && !userAccessControls.mpacReportTopUp2500PropertyTxnl)
        || (prodId == 1116 && !userAccessControls.mpacReportTopUp2500PropertyTxnl)
        || (prodId == 1126 && !userAccessControls.mpacReportTopUp200PropertyTxnl)
        || (prodId == 1127 && !userAccessControls.mpacReportTopUp500PropertyTxnl)
        || (prodId == 1128 && !userAccessControls.reportTopUp200PropertyTxnl)
        || (prodId == 1129 && !userAccessControls.reportTopUp500PropertyTxnl)
        || (prodId == 1134 && !userAccessControls.reportTopUp5000MPSCommercialTxnl)
        || (prodId == 1135 && !userAccessControls.reportTopUp2500MPSCommercialTxnl)
        || (prodId == 1136 && !userAccessControls.reportTopUp1000MPSCommercialTxnl)
        || (prodId == 1137 && !userAccessControls.reportTopUp5000MPSResidentialTxnl)
        || (prodId == 1138 && !userAccessControls.reportTopUp2500MPSResidentialTxnl)
        || (prodId == 1139 && !userAccessControls.reportTopUp1000MPSResidentialTxnl)
        || (prodId == 1250 && !userAccessControls.reportTopUp100StandardPropertyTxnl)
        || (prodId == 1251 && !userAccessControls.reportTopUp250StandardPropertyTxnl)
        || (prodId == 1252 && !userAccessControls.reportTopUp500StandardPropertyTxnl)
        || (prodId == 1265 && !userAccessControls.reportTopUp100MPACStandardPropertyTxnl)
        || (prodId == 1266 && !userAccessControls.reportTopUp250MPACStandardPropertyTxnl)
        || (prodId == 1267 && !userAccessControls.reportTopUp500MPACStandardPropertyTxnl)
        || (prodId == 1268 && !userAccessControls.comparableTopUp50MPACStandardPropertyTxnl)
        || (prodId == 1269 && !userAccessControls.comparableTopUp100MPACStandardPropertyTxnl)
        || (prodId == 1270 && !userAccessControls.comparableTopUp150MPACStandardPropertyTxnl)
        || (prodId == 1271 && !userAccessControls.comparableTopUp200MPACStandardPropertyTxnl)
        || (prodId == 1272 && !userAccessControls.comparableTopUp250MPACStandardPropertyTxnl)
      );
    }
    return false;
  }
  getTitleCheckIndicators(pin:String): Observable<string[]> {
    const url = `${baseUrl}/property/titleCheckIndicators?pin=${pin}`;

    return this.https.get<string[]>(url).pipe(
      map((resp) => resp as string[]),
      catchError((err) => {
        this.loggerService.logError(err);
        return throwError(() => err);
      })
    );
  }

}

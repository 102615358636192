import {Component, OnInit} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {BaseUnsubscribe} from "../base-unsubscribe/base-unsubscribe";
@Component({
  selector: 'app-camera-access-dialog',
  templateUrl: './camera-access-dialog.component.html',
  styleUrls: ['./camera-access-dialog.component.scss']
})
export class CameraAccessDialogComponent extends BaseUnsubscribe{
  constructor(public dialogRef: MatDialogRef<CameraAccessDialogComponent>) {
    super();
  }
  onNoClick(): void {
    this.dialogRef.close(false);  // Close the dialog and return false
  }
  onYesClick(): void {
    this.dialogRef.close(true);  // Close the dialog and return true
  }
}

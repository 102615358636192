import { inject, Injectable } from "@angular/core";
import { UserService } from "../../shared/service/user.service";
import { BehaviorSubject, catchError, lastValueFrom, map, Observable, throwError } from "rxjs";
import { baseUrl } from "../../shared/service/system";
import * as _ from "lodash";
import { HttpClient } from "@angular/common/http";
import { PropertyReportService } from "../../shared/service/property-report.service";
import { LoggerService } from "../../shared/service/log/logger.service";
import { ScreenManager } from "../../shared/service/screen-manager.service";
import { GoogleAnalyticsService } from "../../shared/service/google-analytics.service";
import { User } from "../../core/model/user/user";
import { LoginResponse } from "../../core/model/user/login-response";
import { UserPreference } from "../../core/model/user/preference/user-preference";
import { DataService } from "./data.service";
import { FirstBaseSolutionService } from "./first-base-solution.service";
import { ZendeskService } from "./zendesk.service";
import { UrlService } from "./url.service";
import { ActivatedRoute, Router } from "@angular/router";
import { MainMapService } from "../../home/home/main-map/main-map.service";
import { MeasurementUnitService } from "./measurement-unit.service";
import { environment } from "../../../environments/environment";
import { LocalStorageKey } from "../constant/constants";
import { ComparablesSearchService } from "./search/comparables-search.service";
import { AuthService } from "@auth0/auth0-angular";
import { GA_Event, GA_Label } from "../constant/google-analytics-constants";

// if classic login needs to be enabled also add this to index.html
// <script src="https://cdn.auth0.com/js/lock/11.15/lock.min.js"></script>
// if not, just remove
declare var Auth0Lock: any;

@Injectable({providedIn: 'root'})
export class AuthenticationService {

  constructor() {
    this.initClassicOkta();
  }

  // @ts-ignore
  private lock: Auth0Lock;
  private userService = inject(UserService);
  private auth0Service = inject(AuthService);
  private https = inject(HttpClient);
  private fbsService = inject(FirstBaseSolutionService);
  private zendeskService = inject(ZendeskService);
  private urlService = inject(UrlService);
  private router = inject(Router);
  private activatedRoute = inject(ActivatedRoute);
  private mainMapService = inject(MainMapService);
  private measurementUnitService = inject(MeasurementUnitService);
  private comparablesSearchService = inject(ComparablesSearchService);

  private _oktaLogin: boolean = false;

  public get oktaLogin(): boolean {
    return this._oktaLogin;
  }

  public set oktaLogin(newValue: boolean) {
    this._oktaLogin = newValue;
    localStorage.setItem(LocalStorageKey.isOktaLogin, String(newValue));
  }

  private _isSSOLogin: boolean = false;
  get isSSOLogin(): boolean {
    return this._isSSOLogin;
  }

  propertyReportService = inject(PropertyReportService);
  loggerService = inject(LoggerService);

  screenManager = inject(ScreenManager);
  gaService = inject(GoogleAnalyticsService);

  isSSOUser(): boolean {
    return this.userService.isSSOUser();
  }

  get user(): User {
    return this.userService.user;
  }

  private _termsAndConditionsAccepted = new BehaviorSubject<boolean>(false);
  termsAndConditionsAccepted$ = this._termsAndConditionsAccepted.asObservable();

  private _userProfileInitialized = new BehaviorSubject<boolean>(false);
  userProfileInitialized$ = this._userProfileInitialized.asObservable();

  public setTermsAndConditionsAccepted = (accepted: boolean) => {
    this._termsAndConditionsAccepted.next(accepted);
  }

  public setUserProfileInitialized = (initialized: boolean) => {
    this._userProfileInitialized.next(initialized);
  }

  public isTermsAndConditionsAccepted = (): boolean => {
    return this._termsAndConditionsAccepted.getValue();
  }

  public isUserProfileInitialized = (): boolean => {
    return this._userProfileInitialized.getValue();
  }

  public get isLoggedIn(): boolean {
    this.loggerService.logInfo("checking for loggedin user", this.userService.user);
    return !!this.userService.user?.businessEntityId;
  }

  public login(userName: string, password: string): Observable<LoginResponse> {
    this.gaService.sendEvent(GA_Event.EVENT, GA_Event.NAVIGATE, GA_Label.LOGIN, userName);
    this.userService.clearUser();
    const url = baseUrl + '/authenticate';
    return this.https.post(url, null, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': 'Basic ' + btoa(userName + ":" + password)
      }
    }).pipe(
      map(resp => {
        return <LoginResponse>resp;
      }),
      catchError((err) => {
        this.loggerService.logError(err);
        return throwError(err);
      })
    );
  }

  public logoutWithoutRedirect(): Observable<boolean> {
    this.zendeskService.hideMessagingWidget();
    return this.logoutFromGemaRest().pipe(
      map(
        (result) => {
          if (this.oktaLogin) {
            this.logoutFromOkta();
          }
          return result;
        }
      ));
  }

  private logoutFromGemaRest(): Observable<boolean> {
    const url = baseUrl + '/logout';

    this.gaService.sendEvent(GA_Event.EVENT, GA_Event.NAVIGATE, GA_Label.LOGOUT, String(this.user?.businessEntityId));
    this.userService.clearUser();
    this.propertyReportService.resetSubscribers();
    this.screenManager.closeAllScreens();

    return this.https.post(url, null).pipe(
      map(resp => {
        return (_.isEmpty(resp)); // this returns {}
      }),
      catchError((err) => {
        this.loggerService.logError(err);
        return throwError(err);
      })
    );
  }

  public async logoutFromApp() {
    try {
      this.gaService.sendEvent(GA_Event.EVENT, GA_Event.NAVIGATE, GA_Label.LOGOUT, String(this.user?.businessEntityId));
      sessionStorage.removeItem('hasShownCameraAccessDialog');

      const logoutSuccess = await lastValueFrom(this.logoutWithoutRedirect(), {defaultValue: false});
      if (logoutSuccess) {
        this.loggerService.logInfo(`logged out user ${this.user?.businessEntityId} successfully`);
      } else {
        this.loggerService.logWarning(`there was an error on logging out user ${this.user?.businessEntityId}`);
      }
    } catch (e) {
      this.loggerService.logError(e);
    } finally {
      this.urlService.goToMarketingSitePage();
    }
  }

  public acceptLegalAgreement(): Observable<boolean> {
    const url = baseUrl + '/user/legalAgreement';
    return this.https.post(url, true)
      .pipe(
        map(resp => {
          return !!(resp);
        }),
        catchError((err) => {
          this.loggerService.logError(err);
          return throwError(err);
        })
      );
  }

  public async continueLoggingIn(isOkta: boolean, isSSOLogin: boolean) {
    this.oktaLogin = isOkta;
    this._isSSOLogin = isSSOLogin;

    localStorage.setItem(LocalStorageKey.LAST_LOGGED_IN, new Date().getTime().toString());

    if (isOkta) {
      try {
        const updated = await lastValueFrom(this.userService.updateLastLogin());
      } catch (e) {
        this.loggerService.logError("Error updating last login", e);
      }

    }

    const user: User = await lastValueFrom(this.userService.getUser((isSSOLogin) ? true : false), {defaultValue: new User()});
    let loggedInBeId = user.businessEntityId;

    try {
      const userPreference: UserPreference = await lastValueFrom(this.userService.getPreferences());
      this.mainMapService.updateMainMapType(userPreference);
      this.measurementUnitService.setUnitOfMeasure(userPreference);

    } catch (e) {
      this.loggerService.logError((isSSOLogin) ? 'error retrieving user preferences' : DataService.SSO_DEEPLINK_USER_PREFERENCES_NOT_LOADED, e);
    }


    this.zendeskService.loginUserToMessagingWidget();
    this.fbsService.setMinimumFBSZoomLevel();
    //this.timerService.startCheckingForNewVersion();
    this.comparablesSearchService.initCounters();

    //set the map tile security cookie
    //this.cookieService.set("tileSecToken", decodeURIComponent(user.tileAuthKey + ";domain=.geowarehouse.ca; path=/"));
    document.cookie = "tileSecToken=" + user.tileAuthKey + ";domain=.geowarehouse.ca; path=/";

    let requireLegalAgreement: boolean = user.requireLegalAgreement;
    this.loggerService.logDebug(`company ${user.companyBeid} requires terms and conditions acceptance? ${requireLegalAgreement}`);

    if ((user.isLegalAgreementAccepted || !requireLegalAgreement) && user.isProfileAccepted) {
      if (!isSSOLogin) {
        await this.router.navigate(['/home']).then(() => {
        });
      } else {
        if (user.hasProductsConfigured() && !this.userService.getUserAccessControl().ssoDeepLinkAccess) {
          throw new Error(`user ${loggedInBeId} does not have sso access permissions.`);
        } else {
          this.loggerService.logDebug(`dispatching request for sso user ${loggedInBeId}`);
          this.userService.setSSORequestRoute(this.activatedRoute);
        }
      }
    } else {
      if (!user.isLegalAgreementAccepted && requireLegalAgreement) {
        //user needs to accept the legal agreement
        this.loggerService.logDebug(`redirecting user ${loggedInBeId} to accept terms and conditions`);
        this.urlService.goToAcceptTermsAndConditionsPage();

      } else {
        //user needs to fill in the user profile
        this.loggerService.logDebug(`redirecting user ${loggedInBeId} to complete user profile`);
        this.urlService.goToInitializeUserProfilePage();
      }
    }

  }

  private initClassicOkta() {
    if (this.isOktaClassicEnabled) {
      this.lock = new Auth0Lock(environment.auth0.CLIENT_ID, environment.auth0.DOMAIN, {
        autoclose: false,
        avatar: null,
        auth: {
          container: 'loginFrame',
          redirectUrl: environment.auth0.LOGIN_REDIRECT_URI,
          responseType: environment.auth0.RESPONSE_TYPE,
          params: {
            scope: environment.auth0.SCOPE
          }
        },
        theme: {
          logo: 'https://collaboration.geowarehouse.ca/gema-web/img/geowarehouse-logo1.png',
          primaryColor: '#4c7a15'
        },
        languageDictionary: {
          title: "",
          emailInputPlaceholder: "yours@teranet.ca",
          lastLoginInstructions: "Welcome back! It looks like you are logging in again, please click the email address below to log in.",
          error: {
            login: {
              "lock.invalid_username_password": "Invalid username or password."
            }
          },
          success: {
            forgotPassword: "Check your inbox for the reset your password email."
          },
          forgotPasswordAction: "Reset Password"
        }
      });

      this.lock.on('authenticated', (authResult: any) => {
        this.loggerService.logDebug(authResult);
      });
    }
  }

  private get isOktaClassicEnabled(): boolean {
    return !!environment.isOktaClassicEnabled;
  }

  public async loginWithOkta() {
    if (this.isOktaClassicEnabled) {
      await this.classicOktaLogin();
    } else {
      await this.universalOktaLogin();
    }
    this.oktaLogin = true;
  }

  private async classicOktaLogin() {

    this.lock?.show();
  }

  private async universalOktaLogin() {
    try {
      this.auth0Service.loginWithRedirect()
        .subscribe({
          next: (value) => {
            this.loggerService.logWarning('loginWithRedirect returned: ' + value);
          },
          error: (err) => {
            this.loggerService.logError('Error during universal Okta login: ' + err);
          }
        });
      //this.auth0Service.loginWithPopup();
    } catch (error) {
      this.loggerService.logError('Error during universal Okta login: ' + error);
    }
  }

  clearOktaLocalStorageData(){
    localStorage.removeItem(LocalStorageKey.auth0Token);
    localStorage.removeItem(LocalStorageKey.auth0ExpiresAt);
    localStorage.removeItem(LocalStorageKey.auth0UserId);
    localStorage.removeItem(LocalStorageKey.auth0LoginAttempt);
  }

  public logoutFromOkta() {
    this.clearOktaLocalStorageData();
    if (this.isOktaClassicEnabled) {
      this.classicOktaLogout();
    } else {
      this.universalOktaLogout();
    }
  }

  private classicOktaLogout() {

  }

  private universalOktaLogout() {
    this.auth0Service.logout({logoutParams: {returnTo: environment.auth0.LOGOUT_URL}});
  }

  public registerOktaSessionParams(token: any) {
    if (token) {
      this.loggerService.logDebug("New Auth0 token:" + token.access_token);
      this.loggerService.logDebug("auth0ExpiresIn = " + token.expires_in);
      localStorage.setItem(LocalStorageKey.auth0Token, token.access_token);
      const expiresAt = new Date().getTime() + (token.expires_in * 1000);// token.expires_in is in seconds
      this.loggerService.logDebug('new token expiresIn = ' + expiresAt);
      localStorage.setItem(LocalStorageKey.auth0ExpiresAt, String(expiresAt));
      this.auth0Service.user$.subscribe((usr) => {
        if (usr?.sub) {
          localStorage.setItem(LocalStorageKey.auth0UserId, usr?.sub);
        }
      });
    } else {
      this.loggerService.logError("Tried to register an invalid Auth0 token");
    }
  }

  public async refreshAuth0Token() {
    const token = await lastValueFrom(this.auth0Service.getAccessTokenSilently({
      detailedResponse: true
    }));
    this.registerOktaSessionParams(token);
  }

  tryToLoadAuth0FromLocalStorage() {
    this.oktaLogin = localStorage.getItem(LocalStorageKey.isOktaLogin) === String(true);
  }
}

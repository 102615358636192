import {Component, inject, Input, OnInit} from '@angular/core';
import { BaseSearchResults } from "../base-search-results";
import { CondoLevel } from "../../../../core/model/search-result/condo-level";
import { lastValueFrom } from "rxjs";
import { OmnibarSearchService } from "../../../../shared/service/search/omnibar-search.service";
import { SearchResultOrder } from "../search-results-container/search-result-order";
import * as _ from "lodash";

@Component({
  selector: 'gema3g-condo-search-results',
  templateUrl: './condo-search-results.component.html',
  styleUrls: ['./condo-search-results.component.scss']
})
export class CondoSearchResultsComponent extends BaseSearchResults implements OnInit {

  constructor() {
    super();
  }

  private omnibarSearchService : OmnibarSearchService = inject(OmnibarSearchService);

  @Input('condoLevels') condoLevels: CondoLevel[] = [];
  @Input('condoAddress') condoAddress: string;
  @Input() isMobileFullScreen: boolean = false;

  sortingOptions = SearchResultOrder.LevelPinDropdownValues;
  condoLevelUIId = 'condo-level-header-';
  defaultSortingOption: SearchResultOrder = this.sortingOptions[0].value;
  showBlankSection: boolean = false;

  ngOnInit(): void {

  }

  get totalUnits(): number {
    if (this.condoLevels) {
      return this.condoLevels.reduce((accumulator, currentValue) => accumulator + currentValue.count, 0);
    }
    return 0;
  }

  applySorting($event: SearchResultOrder){
    if ($event.firstPredicate == 'pin'){
      this.condoLevels.forEach(condoLevel => {
        condoLevel.units = _.orderBy(condoLevel.units, $event.sorting, $event.isReverse ? 'desc' : 'asc');
      });
    } else {
      this.condoLevels = _.orderBy(this.condoLevels, $event.sorting, $event.isReverse ? 'desc' : 'asc');
    }
  }

  async expandCondoLevel(condoLevel: CondoLevel) {
    this.condoLevels.forEach(value => value.expanded = false);
    condoLevel.expanded = true;
    condoLevel.loading = true;
    // please note that caching should be done at HttpCacheInterceptor level
    condoLevel.units = [...await lastValueFrom(this.omnibarSearchService.getCondosByBlockAndLevel(condoLevel.block, condoLevel.levelNumber))];
    // 2g changes the default sort order to pin : descending
    condoLevel.units = _.orderBy(condoLevel.units, 'pin' , 'desc');
    condoLevel.loading = false;
  }

  collapseCondoLevel(condoLevel: CondoLevel) {
    condoLevel.expanded = false;
  }

  jumpToLevel($event: any) {
    let selectedCondoLevel = $event.value;
    if (selectedCondoLevel) {
      this.condoLevels.forEach(condoLevel => {
          if (condoLevel.levelNumber == selectedCondoLevel){
            setTimeout( () => {
              this.expandCondoLevel(condoLevel);
              this.scrollToLevel(condoLevel);
              }, 100);
          } else if (condoLevel.expanded) {
            this.collapseCondoLevel(condoLevel);
          }
        }
      );
    }
  }

  scrollToLevel(condoLevel: CondoLevel) {
    let elementId: string = `${this.condoLevelUIId}${condoLevel.levelNumber}`;
    let element:HTMLElement = document.getElementById(elementId) as HTMLElement;
    if (element) {
      element.scrollIntoView({behavior: 'smooth'});
    }
  }

  onSortingClick($event: any) {
    if (this.condoLevels?.length < 2) {
      this.showBlankSection = !this.condoLevels.some(value => value.expanded)
    }
  }
}

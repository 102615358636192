import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { SearchResultOrder } from "../search-results-container/search-result-order";
import * as _ from "lodash";
import { SearchResult } from "../../../../core/model/search-result/search-result";
import { MainMapService } from "../../main-map/main-map.service";
import { UserAccessControl } from "../../../../core/model/user/user-access-control";
import { EstoreService } from "../../../../shared/service/estore.service";
import { EStoreParam } from "../../../../core/model/product/e-store/e-store-param";
import { UserService } from "../../../../shared/service/user.service";
import { BasePropertyEventEmitter } from "../base-property-event-emitter";
import { LoggerService } from '../../../../shared/service/log/logger.service';
import { EstoreBrowserTabAttribute } from '../../../../core/model/estore/estore-browser-tab-attribute';

@Component({
  selector: 'gema3g-instrument-search-results',
  templateUrl: './instrument-search-results.component.html',
  styleUrls: ['./instrument-search-results.component.scss']
})
export class InstrumentSearchResultsComponent extends BasePropertyEventEmitter implements OnInit {
  constructor() {
    super();
  }

  private loggerService: LoggerService = inject(LoggerService);
  private eStoreService: EstoreService = inject(EstoreService);
  private userService: UserService = inject(UserService);

  ngOnInit(): void {
    this.userAccessControls = this.userService.getUserAccessControl();
  }

  @Input('searchResult') searchResult: SearchResult;
  @Input() isMobileFullScreen: boolean = false;
  @Output() onDivScroll = new EventEmitter<Event>();
  sortingOptions = SearchResultOrder.DropdownValues;
  defaultSortingOption: SearchResultOrder = this.sortingOptions[0].value;
  userAccessControls: UserAccessControl;

  applySorting($event: SearchResultOrder) {
    if ($event.sorting && $event.sorting.includes('name')){
      // since there is no name field on the items of the list using initial index (same as 2g)
      this.searchResult.searchResult = _.orderBy(this.searchResult.searchResult, 'initialIndex', $event.isReverse ? 'desc' : 'asc');
    } else {
      this.searchResult.searchResult = _.orderBy(this.searchResult.searchResult, $event.sorting, $event.isReverse ? 'desc' : 'asc');
    }
  }

  async openDetails() {
    const eStoreParam = new EStoreParam('PlanImage', '', '', this.searchResult.document?.lroNumber);
    eStoreParam.docNo = this.searchResult.document?.name;
    this.loggerService.logDebug(`view instrument details for ${eStoreParam.docNo}`);
    await this.eStoreService.openReport(eStoreParam, new EstoreBrowserTabAttribute(false, false));
  }

  onScroll($event: Event) {
    this.onDivScroll.emit($event);
  }

  get instrumentSearchResultsClass(): string {
    if (this.isMobileFullScreen) {
      return this.searchResult?.document?.name ? 'max-h-[calc(100dvh-242px)]' : 'max-h-[calc(100dvh-142px)]';
    }
    
    return this.searchResult?.document?.name ? 'max-h-[25vh]' : 'max-h-[40vh]';
  }
}

import {BaseModel} from "../base-model";
import {Pii} from "../property/pii";
import {OwnerToPropertyMap} from "./owner-to-property-map";
import {InstrumentDocument} from "../property/instrument-document";
import _ from "lodash";


export class SearchResult extends BaseModel {
  constructor(searchResult?: SearchResult) {
    super(searchResult);
    if (searchResult) {
      if (searchResult.ownerToPropertyMap) {
        // Check if results are from autosuggest
        // @ts-ignore
        if (searchResult.activityType == 'NAME_SEARCH' && Object.keys(searchResult.ownerToPropertyMap).length == 1 && searchResult.ownerToPropertyMap[searchResult.searchText] instanceof Array && searchResult.ownerToPropertyMap[searchResult.searchText].length > 1) {
          // @ts-ignore
          searchResult.ownerToPropertyMap[searchResult.searchText].forEach((value) => {

            let ownerToPropertyMap = new OwnerToPropertyMap(Object.keys(searchResult.ownerToPropertyMap)[0], [value]);
            this.ownerToPropertyMap.push(ownerToPropertyMap);
          });
        } else {
          Object.keys(searchResult.ownerToPropertyMap).forEach((value) => {

            // @ts-ignore
            let ownerToPropertyMap = new OwnerToPropertyMap(value, searchResult.ownerToPropertyMap[value]);
            this.ownerToPropertyMap.push(ownerToPropertyMap);
          });
        }
      }

      if (Array.isArray(searchResult.searchResult)) {
        this.searchResult = searchResult.searchResult.map((pii, index) => {
          return new Pii(pii, index);
        });
      } else if (Array.isArray(searchResult.spatialPropertyResult)) {
        this.searchResult = searchResult.spatialPropertyResult.map((pii, index) => {
          return new Pii(pii, index);
        });
      }

      if (searchResult.document) {
        this.document = new InstrumentDocument(searchResult.document);
      }
    }
  }

  activityType: string;
  document?: InstrumentDocument;
  itemsTotal: number;
  nextItemAddress: number;
  nextItemOwner: number;
  nextPage: number;
  ownerToPropertyMap: OwnerToPropertyMap[] = [];
  page: number;
  pageSize: number;
  pagesTotal: number; //ToDo
  searchResult: Pii[];
  searchText: string;
  spatial: any[]; //ToDo
  spatialPropertyResult: Pii[];

  //UI only
  initialized: boolean = false;

  copyReturnedResults(searchResult: SearchResult) {
    this.nextItemOwner = searchResult.nextItemOwner;
    this.nextItemAddress = searchResult.nextItemAddress;
    this.searchResult = _.unionBy(this.searchResult, searchResult.searchResult, 'pin');
  }
}
